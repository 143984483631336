/* Create some type interfaces for common data structures */

/**
 * @typedef {Object} RawNode
 * @property {string} node_type
 * @property {string} node_name
 * @property {number} node_id
 */

/**
 * @typedef {Object} RawGraphEdge
 * @property {RawNode} start
 * @property {RawNode} end
 * @property {string} type
 */

/**
 * @typedef {RawGraphEdge[]} RawGraph
 */

/**
 * @typedef {Object} ProcessedNode
 * @property {string} type
 * @property {string} name
 * @property {number} id
 */

const NodeType = Object.freeze({
  Parcel_Address: 'Parcel_Address',
  A_Parcel_Owner: 'A_Parcel_Owner',
  A_Parcel: 'A_Parcel',
  RB_Eviction: 'RB_Eviction',
  RB_Evictor: 'RB_Evictor'
})

const RelType = Object.freeze({
  EVICTED_BY: 'EVICTED_BY',
  EVICTION_AT: 'EVICTION_AT',
  UNIT_AT: 'UNIT_AT',
  BE_LINKED_TO: 'BE_LINKED_TO'
})

/**
 * 
 * @param {number} node_id 
 * @param {string} node_name 
 * @param {string} node_type 
 * @returns {RawNode}
 */
function createRawNode(node_id, node_name, node_type) {
  return { node_id: node_id, node_name: node_name, node_type: node_type }
}

/**
* 
* @param {RawNode} start
* @param {RawNode} end
* @param {string} type
* @returns {RawGraphEdge}
*/
function createRawEdge(start, end, type) {
  return { start: start, end: end, type: type }

}

/**
 * @param {RawNode} node 
 * @returns {ProcessedNode} 
 */
const createNode = node => {
  let nodeName = ''
  switch (node.node_type) {
    case NodeType.A_Parcel_Owner:
      nodeName = node.node_name.concat(' (Property Owner)')
      break
    default:
      nodeName = node.node_name
  }
  return { id: node.node_id, name: nodeName, type: node.node_type }
}

/**
 * 
 * @param {RawGraph} graph 
 */
const get_node_degrees = function (graph) {
  /** @type {Object.<number, number>} */
  let degrees = {}
  graph.forEach(rel => {
    if (rel.type !== RelType.EVICTED_BY && rel.type !== RelType.EVICTION_AT) {
      for (const id of [rel.start.node_id, rel.end.node_id]) {
        if (!(id in degrees)) {
          degrees[id] = 1
        } else {
          degrees[id] += 1
        }
      }
    }
  })
  return degrees
}

/**
 * @param {RawGraph} graph 
 */
const generate_addr_map = function (graph) {
  /** @type {Object.<number, ProcessedNode>} */
  let addr_map = {} // unit_id: {addr_main node}
  const addr_unit_rels = graph.filter(n => n.type === RelType.UNIT_AT)
  addr_unit_rels.forEach(rel => {
    addr_map[rel.start.node_id] = createNode(rel.end)
  })
  return addr_map
}

/*
generate_evictor_maps produces mappings from parcels <-> evictors as follows:

evictor_map = {
  evictor_id: {
    num_certain: 5,
    num_possible: 10,
    definite_parcels: [parcel_a, parcel_b]
  }
}

parcel_map: {
  parcel_id: {
    num_certain: 4,
    num_possible: 8
    definite_evictors: [evictor_a, evictor_b]
  }
}
*/

/**
 * @param {RawGraph} graph 
 */
const generate_evictor_maps = function (graph) {
  const eviction_rels = graph.filter(n => n.start.node_type === NodeType.RB_Eviction)
  /* {RB_Eviction id: {
      "evictors": [(e:evictor_node, certain:bool), ...]
      "parcels": [(p:parcel_node, certain:bool), ...]}
    }
  */
  let eviction_map = {}
  eviction_rels.forEach(rel => {
    const evict_id = rel.start.node_id
    if (!(evict_id in eviction_map)) {
      eviction_map[evict_id] = { evictors: [], parcels: [] }
    }
    const end_node = createNode(rel.end)
    if (rel.type === RelType.EVICTED_BY) {
      // if r.rel.type ==
      eviction_map[evict_id]['evictors'].push(end_node)
    } else if (rel.type === RelType.EVICTION_AT) {
      eviction_map[evict_id]['parcels'].push(end_node)
    }
  })

  let evictor_map = {}
  let parcel_map = {}
  for (const evict_id in eviction_map) {
    // if more than one evictor or parcel is assoc. with an eviction,
    // this is a "multiple match" scenario, so we are not confident
    // about the evictor / parcel
    const evictor_certain = eviction_map[evict_id].evictors.length === 1
    const parcel_certain = eviction_map[evict_id].parcels.length === 1
    eviction_map[evict_id].evictors.forEach(evictor => {
      if (!(evictor.id in evictor_map)) {
        evictor_map[evictor.id] = {
          num_certain: 0,
          num_possible: 0,
          parcels: [],
        }
      }
      let e = evictor_map[evictor.id]
      e.num_certain += evictor_certain ? 1 : 0
      e.num_possible += evictor_certain ? 0 : 1
      // ONLY connect evictor and parcel if both matches are certain
      if (evictor_certain && parcel_certain) {
        const parcel = eviction_map[evict_id].parcels[0]
        if (!e.parcels.map(x => x.id).includes(parcel.id)) {
          e.parcels.push(parcel)
        }
      }
    })
    eviction_map[evict_id].parcels.forEach(parcel => {
      if (!(parcel.id in parcel_map)) {
        parcel_map[parcel.id] = {
          num_certain: 0,
          num_possible: 0,
          evictors: [],
        }
      }
      let p = parcel_map[parcel.id]
      p.num_certain += parcel_certain ? 1 : 0
      p.num_possible += parcel_certain ? 0 : 1
      // ONLY connect evictor and parcel if both matches are certain
      if (evictor_certain && parcel_certain) {
        const evictor = eviction_map[evict_id].evictors[0]
        if (!p.evictors.map(x => x.id).includes(evictor.id)) p.evictors.push(evictor)
      }
    })
  }
  return {
    evictor_map: evictor_map,
    parcel_map: parcel_map,
  }
}

const LINK_LABEL = {
  PARC_OWNER_ADDR_AT: 'owner address at',
  PARC_OWNED_BY: 'property owned by',
  OFFICER_FOR: 'officer for',
  ENTITY_OWNED_BY: 'owned by',
  LISTED_AT: 'listed at',
  PARCEL_ADDR_FOR: 'parcel address for',
}

const _create_link = function (source_id, target_id, label) {
  return {
    source: source_id,
    target: target_id,
    label: label,
  }
}

const unique_nodes = function (nodes) {
  let unique = []
  nodes.forEach(n => {
    if (!unique.map(x => x.id).includes(n.id)) unique.push(n)
  })
  return unique
}

const unique_links = function (links) {
  let unique = []
  links.forEach(l => {
    if (l.label === undefined) return
    let can_add = true
    unique.forEach(u => {
      if (u.source === l.source && u.target === l.target) {
        can_add = false
      }
    })
    if (can_add) unique.push(l)
  })
  return unique
}

/** get_next_edges(): given a node, returns all of nodes with one
 * edge between them and this node
 * as well as all of their associated links
 * exclude_set: set of node_ids already added to master list
 * 
 * @param {ProcessedNode} node 
 * @param {RawGraph} graph 
 * @param {Object.<number, ProcessedNode>} addr_map 
 * @param {*} evictor_map TODO
 * @param {*} parcel_map TODO
 * @param {*} exclude_set TODO
 */

const get_next_edges = function (node, graph, addr_map, evictor_map, parcel_map, exclude_set) {
  let new_nodes = []
  let new_links = []
  // if current node is RB_Evictor, automatically add parcels
  // TODO: add property counting evictions
  if (node.type === NodeType.RB_Evictor) {
    if (node.id in evictor_map) {
      const e = evictor_map[node.id]
      e.parcels.forEach(p => {
        if (!exclude_set.includes(p.id)) new_nodes.push(p)
        // should this be under if statement?
        new_links.push(_create_link(node.id, p.id, 'evictor at'))
      })
    }
  }
  // if current node is A_Parcel, automatically add evictors
  // TODO: add property counting evictions
  else if (node.type === NodeType.A_Parcel) {
    if (node.id in parcel_map) {
      const p = parcel_map[node.id]
      p.evictors.forEach(e => {
        if (!exclude_set.includes(e.id)) new_nodes.push(e)
        new_links.push(_create_link(e.id, node.id, 'evictor at'))
      })
    }
  }

  graph.forEach(rel => {
    // if rel is an EVICTED_BY or EVICTION_AT, continue (parcels/evictors already added)
    if (rel.type === RelType.EVICTED_BY || rel.type === RelType.EVICTION_AT) return

    // if neither endpoint matches current node, skip
    if (rel.start.node_id !== node.id && rel.end.node_id !== node.id) return

    const linked_neo4j_node = rel.start.node_id === node.id ? rel.end : rel.start
    const source_rel = rel.start.node_id === node.id ? true : false // if true, current node is source of link

    let linked_node = createNode(linked_neo4j_node)
    // if we can replace Address_Unit linked node with Address_Main, do so
    if (linked_neo4j_node.node_id in addr_map) {
      linked_node = addr_map[linked_neo4j_node.node_id]
    }

    // if current node is BE_LINKED_TO another node, contract rel
    // and add one-degree connections of other node
    if (rel.type === RelType.BE_LINKED_TO && !exclude_set.includes(linked_node.id)) {
      const linked_res = get_next_edges(
        linked_node,
        graph,
        addr_map,
        evictor_map,
        parcel_map,
        exclude_set.concat([linked_node.id])
      )
      new_nodes.push(...linked_res.nodes)
      // modify links to link back up to current node
      linked_res.links.forEach(l => {
        if (l.source === linked_node.id) l.source = node.id
        else l.target = node.id
        new_links.push(l)
      })
      return
    }

    // otherwise, add this linked node to set of one degree connections
    if (!exclude_set.includes(linked_node.id)) new_nodes.push(linked_node)
    if (source_rel) {
      new_links.push(_create_link(node.id, linked_node.id, LINK_LABEL[rel.type]))
    } else {
      new_links.push(_create_link(linked_node.id, node.id, LINK_LABEL[rel.type]))
    }
  })
  return { nodes: unique_nodes(new_nodes), links: unique_links(new_links) }
}

/**
 * 
 * @param {RawGraph} graph 
 * @returns {RawGraph}
 */
const collapse_addresses = (graph) => {

  if (!graph) return []

  /* construct set of parcels with addresses */
  const parcels = {}
  graph.forEach(link => {
    if (link.start.node_type === NodeType.Parcel_Address) {
      parcels[link.end.node_id] = {
        node_id: link.end.node_id,
        node_type: NodeType.A_Parcel, // the linked node will always be a parcel
        node_name: link.start.node_name // replace the node name with the parcel address
      }
    }

    if (link.end.node_type === NodeType.Parcel_Address) {
      parcels[link.start.node_id] = {
        node_id: link.start.node_id,
        node_type: NodeType.A_Parcel, // the linked node will always be a parcel
        node_name: link.end.node_name // replace the node name with the parcel address
      }
    }
  })

  /**
   * 
   * @param {number} node_id 
   */
  function createUnknownAddressNode(node_id) {
    return createRawNode(node_id, 'Parcel (Unknown Address)', NodeType.A_Parcel)
  }

  return graph.filter(link => { // filter out all edges that contain a parcel address
    return link.start.node_type !== NodeType.Parcel_Address && link.end.node_type !== NodeType.Parcel_Address
  }).map(link => { // wherever a parcel was listed, replace it with the new parcel node with the address
    if (link.start.node_type === NodeType.A_Parcel) {
      const node_id = link.start.node_id
      // replace the start node with the new parcel node with the address, if present
      const start_node = (node_id in parcels) ? parcels[node_id] : createUnknownAddressNode(node_id)

      return createRawEdge(start_node, link.end, link.type)
    } else if (link.end.node_type === NodeType.A_Parcel) {
      const node_id = link.end.node_id
      // replace the end node with the new parcel node with the address, if present
      const end_node = (node_id in parcels) ? parcels[node_id] : createUnknownAddressNode(node_id)

      return createRawEdge(link.start, end_node, link.type)
    }

    return link
  })
}

/**
 * 
 * @param {RawGraph} db_graph 
 * @param {number} node_limit 
 * @param {number} degree_limit 
 */
export const get_view_network = function (db_graph, node_limit = 80, degree_limit = 6) {
  const graph = collapse_addresses(db_graph)

  // If there's no graph to render, short-circuit and return an obj with empty
  // lists for 'nodes' and 'links'.
  if (!Array.isArray(graph) || graph.length === 0) {
    return {
      nodes: [],
      links: [],
    }
  }

  const node_degrees = get_node_degrees(graph)
  const addr_map = generate_addr_map(graph)
  const emap = generate_evictor_maps(graph)
  const evictor_map = emap.evictor_map
  const parcel_map = emap.parcel_map

  let confirmed_nodes = [createNode(graph[0].start)] // arbitrarily use the first node to start with
  let confirmed_links = []

  let path_length = 1

  let last_added_nodes = [...confirmed_nodes] // the set of nodes added on the last run of the while loop
  let cached_nodes = [...confirmed_nodes] // a copy of the total amount of saved nodes

  // If the user selected 'All', set the limit as the number of total nodes
  node_limit = node_limit === 'All' ? Object.keys(node_degrees).length : node_limit

  while (confirmed_nodes.length < node_limit && path_length <= degree_limit) {
    let nodes_to_add = []
    let links_to_add = []
    /* eslint no-loop-func: 'off' */
    last_added_nodes.forEach(n => {
      const { nodes, links } = get_next_edges(n, graph, addr_map, evictor_map, parcel_map, [])
      nodes.forEach(new_n => {
        if (!nodes_to_add.map(x => x.id).includes(new_n.id)) nodes_to_add.push(new_n)
      })
      links_to_add.push(...links)
    })
    last_added_nodes = nodes_to_add

    cached_nodes.push(...nodes_to_add)
    cached_nodes = unique_nodes(cached_nodes)

    // update confirmed_nodes iff the result will be under node_limit
    //    if it crosses the limit, then confirmed_nodes will "remember"
    //    a cached value from the previous run and not add the limit-breaking nodes
    // This also provides a safety check in case the graph processor thinks the graph is
    //    "fragmented" and will never reach the total number of nodes. In this scenario
    //    it will cause a stack overflow, so we want to break if there were no nodes added
    //    from one run of the while loop
    if (cached_nodes.length >= node_limit) {
      confirmed_nodes = [...cached_nodes].slice(0, node_limit)
      const node_ids = confirmed_nodes.map(node => node.id)

      confirmed_links = unique_links(links_to_add)
        .filter(link => node_ids.includes(link.source) && node_ids.includes(link.target))
      break
    }
    if (cached_nodes.length === confirmed_nodes.length) break
    confirmed_nodes = [...cached_nodes]
    confirmed_links = unique_links(links_to_add)
    path_length += 1
  }

  confirmed_nodes.forEach(n => {
    let displayed_degree = 0
    confirmed_links.forEach(l => {
      if (l.source === n.id || l.target === n.id) displayed_degree += 1
    })
    if (displayed_degree < node_degrees[n.id]) {
      n['not_displayed'] = node_degrees[n.id] - displayed_degree
    }
  })

  return {
    nodes: confirmed_nodes,
    links: confirmed_links,
  }
}
