import  { memo, useEffect } from 'react'
import { useAppSelector } from 'src/state/hooks'
import { useAppDispatch } from 'src/state/hooks'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import { Loading } from '../components/NotAvailable'
import Link from '@mui/material/Link'
import { useTheme } from '@mui/material/styles'
import useStyles from '../theme/SearchResultsStyles'
import {
  resetSearch,
  setOffset,
  selectSearchResults,
  selectSearchTerm,
  selectSearchOffset,
  selectSearchPending
} from '../state/slices/searchStateSlice'
import { Row, Col } from '../components/Grid'
import { Link as RouterLink } from 'react-router-dom'
import BusinessIcon from '@mui/icons-material/Business'
import HomeIcon from '@mui/icons-material/Home'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import DataTable from  '../components/DataTable/DataTable'
import { config } from '../config/config'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

/*
  SearchResultsPage: presents results for a given search term
*/

/* Get a list of search categories from the config file */
const categories  = config.categories

const RenderConditionalAvatar = category => {
  
  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Avatar variant="circular" className={classes.avatar}>
      {
        { '2': <BusinessIcon color="secondary" />,
          '1': <RoomOutlinedIcon color="secondary" />,
          '0': <HomeIcon color="secondary" />
        }[categories[category]]
      }
    </Avatar>
  )
}

const ResultCard = ({ label, category, categoryReadable, url }) => {

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Card elevation={1}>
      <CardHeader
        avatar={RenderConditionalAvatar(category)}
        title={
          <Link
            to={url}
            component={RouterLink}>
            <Typography className={classes.title}>
              {label}
            </Typography>
          </Link>}
        subheader={
          <Typography className={classes.subHeader}>
            {categoryReadable}
          </Typography>
        }
      />
    </Card>
  )
}

const SearchResults = () => {

  const dispatch = useAppDispatch()

  /* Reset search state when this component is unmounted */
  useEffect(() => {
    return () => {
      dispatch(resetSearch())
    }
  }, [dispatch])

  /* Set up API data fields and selectors */
  const select = config.api.response.search.selectors
  const field = config.api.response.search.fields

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Set hooks to use Redux global state */
  const search = useAppSelector(selectSearchTerm)
  const records = useAppSelector(selectSearchResults)
  const pending = useAppSelector(selectSearchPending)

  const results = Object.assign([], records) /* adds missing Array __proto__ */
  const offset = useAppSelector(selectSearchOffset)

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'SearchPage', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  const limit = 50
  const resultsPerPage = 10
  function onPageChange(newPageNumber){
    /* newPageNumber starts from 0 after new data is fetched */
    const shouldFetchNewResults = (newPageNumber + 1) * resultsPerPage >= limit
    shouldFetchNewResults && dispatch(setOffset(offset + limit - resultsPerPage))
  }

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  /* Wait for data to become available */
  if (!search) { return null }

  return (
    <div className={classes.page}>
      <Row wrap='nowrap' className={classes.content}>
        <Col xs={12}>
          <Typography variant='h1' style={{'marginRight': '20px'}} >
            {t('{{state}} results for {{search}}', {
              state: pending ? 'Loading ' : 'Showing ',
              search: search
            })}
          </Typography>
          {pending && <Loading />}
          {!pending &&
          <Typography variant='body1' style={{textAlign: 'left', marginBottom: '1rem'}}>
            {t("Don't see what you're looking for? Try searching for similar names or addresses. Instead of 32 Main Street, 34 Main Street might have data for the property you're looking for.")}
          </Typography>}
          {results.length > 0 ?
            <Box className={classes.cardContainer}>
              <DataTable 
                cellStyles={{padding: 0}}
                className={classes.searchTable}
                csvFileName={t('Search results for {{search}}', {search: search})}
                name='results'
                rows={results}
                columns={[{
                  field: field.records,
                  searchSelector: row => select.label(row) + select.categoryLabel(row),
                  renderCell: e => 
                    <ResultCard 
                      key={select.detailsUrl(e.row)}
                      label={select.label(e.row)}
                      url={select.detailsUrl(e.row)}
                      categoryReadable={select.categoryLabel(e.row)}
                      category={select.category(e.row)}
                    />
                }]}
                onPageChange={onPageChange}
                rowsPerPageOptions={[]}
                labelDisplayedRows={({from, to}) => {
                  return `${from + offset} to ${to + offset} results.`
                }}
              >
              </DataTable>
            </Box> : !pending && 
            <Typography className={classes.missingData}>
              No results found
            </Typography>
          }
        </Col>
      </Row>
    </div>
  ) 
}

export default memo(SearchResults)
