import { updateEdgeLabels } from './links'

export function dragStarted(event, d, simulation, theme) {
  d.dragging = true
  event.sourceEvent.stopPropagation()
  if (!event.active) simulation.alpha(0.3).restart()
  d.fx = d.x
  d.fy = d.y
  updateEdgeLabels([], theme)
}

export function dragging(event, d) {
  d.fx = event.x
  d.fy = event.y
}

export function dragEnded(event, d, simulation) {
  d.dragging = false
  if (!event.active) simulation.alpha(0.3).restart()
  d.fx = event.x
  d.fy = event.y
}
