import { memo, useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Footer from './Footer'
import Header from './Header'
import Home from '../pages/Home'
import About from '../pages/AboutPage'
import Glossary from '../pages/Glossary'
import Disclaimer from '../pages/Disclaimer'
import Methodology from '../pages/Methodology'
import TakeAction from '../pages/TakeAction'
import TenantResources from '../pages/TenantResources'
import EvictionsPage from '../pages/EvictionPage'
import OwnerPage from '../pages/OwnerPage'
import ParcelPage from '../pages/ParcelPage'
import NetworkPage from '../pages/NetworkPage'
import SearchResultsPage from '../pages/SearchResultsPage'
import { NoPage } from './NotAvailable'
import { Login } from '../pages/Login'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { useAppDispatch } from 'src/state/hooks'
import { setLoggedIn } from '../state/slices/loginSlice'
import PreforeclosuresPage from '../pages/PreforeclosuresPage'
import NeighborhoodListPage from '../pages/NeighborhoodListPage'
import FeedbackIFrame from './FeedbackIFrame'
import { light, dark } from '../theme/Theme'

/** App is the main component of this application */
const App = () => {
  /* Report to the console if not in production */
  const isProduction = typeof process.env.NODE_ENV === 'string' &&
    process.env.NODE_ENV === 'production'
  if (!isProduction) console.info('UI is not running in production mode')

  /* Set up Material-UI theme */
  const [themeMode, setThemeMode] = useState(localStorage.getItem('themeMode') ?? 'light')

  useEffect(() => {
    localStorage.setItem('themeMode', themeMode)
  }, [themeMode])

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  if (!isProduction) {
    if (prefersDarkMode) {
      console.info('User prefers dark mode')
    }
    console.info('Theme mode is set to: ' + themeMode)
  }

  let theme
  if (!isProduction) {
    theme = themeMode === 'light' ? light : dark
  }
  else {
    theme = light
  }

  const dispatch = useAppDispatch()
  /* On first load, check if user is logged in */
  useEffect(() => {
    fetch('/api/login', { credentials: 'include' })
      .then(res => res.json())
      .then(({ isLoggedIn }) => {
        dispatch(setLoggedIn({ isLoggedIn }))
      })
  }, [dispatch])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Box className="page-container" display="flex" flexDirection="column" flex="1">
              <Header themeMode={themeMode} setThemeMode={setThemeMode} />
              <Box display="flex" flexDirection="column" flex="1">
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/about' element={<About />} />
                  <Route path='/glossary' element={<Glossary />} />
                  <Route path='/disclaimer' element={<Disclaimer />} />
                  <Route path='/methodology' element={<Methodology />} />
                  <Route path='/take-action' element={<TakeAction />} />
                  <Route path='/tenant-resources' element={<TenantResources />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/neighborhood/:search' element={<EvictionsPage />} />
                  <Route path='/preforeclosures/:neighborhood' element={<PreforeclosuresPage />} />
                  <Route path='/owner/:search' element={<OwnerPage />} />
                  <Route path='/parcel/:id' element={<ParcelPage />} />
                  <Route path='/graph/:id' element={<NetworkPage />} />
                  <Route path='/searchresults/:searchParam' element={<SearchResultsPage />} />
                  <Route path='/searchresults' element={<SearchResultsPage />} />
                  <Route path='/neighborhood-list/:geography' element={<NeighborhoodListPage />} />
                  <Route
                    path='/feedback'
                    element={<FeedbackIFrame hideFeedbackFormInFooter={true} />}
                  />
                  {/* Default to NoPage if the URL does not match any routes above */}
                  <Route path='*' element={<NoPage />} />
                </Routes>
              </Box>
              <Footer />
            </Box>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>

    </ThemeProvider>
  )
}

export default memo(App)
