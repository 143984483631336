import  { memo } from 'react'
import { ReactComponent as SearchIconGunmetal } from '../images/search-icon-homepage.svg'
import { ReactComponent as CorporationIcon } from '../images/corporation-icon-homepage.svg'
import { ReactComponent as NeighborhoodIcon } from '../images/neighborhood-icon-homepage.svg'
import { ReactComponent as NetworkIcon } from '../images/network-icon-homepage.svg'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import DataPage, { selectRecord } from '../components/DataPage/DataPage'
import { Row, Col } from '../components/Grid'
import InterpolateTranslation from '../components/InterpolateTranslation'
import SearchBar from '../components/DataSearchBar/SearchBar'
import useStyles from '../theme/HomeStyles'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { useAppSelector } from 'src/state/hooks'
import { config } from '../config/config'

/*
  The landing page of the application
*/

/* Set up API data selectors */
const select = config.api.response.homePage.selectors

/**
 * @param {object} props
 * @param {string} props.text
 * @param {string[]} props.links
 * @param {ElementType} props.icon
 * @returns 
 */
const DisplayItem = ({ text, links, icon }) => {
  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /**
   * @param {number} index 
   * @returns {JSX<PropsWithChildren<{}>>}
   */
  const linkGenerator =
    index =>
      ({ children }) =>
        (
          <>
            <Link href={links[index]}>{children}</Link>
            <br />
          </>
        )

  /** @type {JSX<PropsWithChildren<{}>>[]} */
  const components = [
    ({ children }) => (
      <Row justifyContent='center'>
        <Typography className={classes.heading}>{children}</Typography>
      </Row>
    ),
    linkGenerator(0),
    linkGenerator(1),
    linkGenerator(2),
  ]

  return (
    <Col sm={12} md={6} lg={3} className={classes.colStyle}>
      <Row justifyContent='center'>
        <Box component={icon} className={classes.iconStyle} />
      </Row>
      <InterpolateTranslation components={components}>{text}</InterpolateTranslation>
    </Col>
  )
}

const Home = () => {
  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'HomePage' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  const exampleParcel = useAppSelector(selectRecord('example-parcel'))
  const exampleOwner = useAppSelector(selectRecord('example-owner'))
  const exampleNetwork = useAppSelector(selectRecord('example-network'))

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) {
    return null
  }

  const displayItems = [
    {
      text: t('<1>Search for an address</1> like <2>{{address}}</2>', {
        address: select.parcelAddress(exampleParcel),
      }),
      icon: SearchIconGunmetal,
      links: [`/parcel/${select.parcelId(exampleParcel)}`],
    },
    {
      text: t('<1>Search for a property owner</1> like <2>{{owner_name}}</2>', {
        owner_name: select.ownerNameTitleCase(exampleOwner),
      }),
      links: [`/owner/${select.ownerName(exampleOwner)}`],
      icon: CorporationIcon,
    },
    {
      text: t(`
      <1>Search by neighborhood</1>
      like <2>Merritt</2>
      or <3>View All Neighborhoods in Oakland</3>
      or <4>View All Neighborhoods in San Francisco</4>
      `),
      links: [
        '/neighborhood/MERRITT%2C%20OAKLAND',
        '/neighborhood-list/oak',
        '/neighborhood-list/sf',
      ],
      icon: NeighborhoodIcon,
    },
    {
      text: t('<1>Explore corporate networks</1> like <2>this one</2>'),
      links: [`/graph/${select.networkId(exampleNetwork)}`],
      icon: NetworkIcon,
    },
  ]

  return (
    <>
      <DataPage
        fetchArray={[
          { slice: 'example-parcel', url: '/api/example/parcel' },
          { slice: 'example-network', url: '/api/example/network' },
          { slice: 'example-owner', url: '/api/example/owner' },
        ]}
        isDebugEnabled={false}>
        <div className={classes.introBackground}>
          <div className={classes.intro}>
            <Typography variant='h1' className={classes.title}>
              EVICTORBOOK
            </Typography>
            <SearchBar location='home' />
            <Row
              justifyContent='space-around'
              textAlign='center'
              whiteSpace='nowrap'
              id='homepage-primary-links'
              className={classes.primaryLinks}>
              <Link className={classes.link} href='/about'>
                {t('About Evictorbook')}
                <KeyboardArrowRightIcon fontSize='large' className={classes.linkIcon} />
              </Link>
              <Link className={classes.link} href='/methodology'>
                {t('Our Methodology')}
                <KeyboardArrowRightIcon fontSize='large' className={classes.linkIcon} />
              </Link>
              <Link className={classes.link} href='/tenant-resources'>
                {t('Tenant Resources')}
                <KeyboardArrowRightIcon fontSize='large' className={classes.linkIcon} />
              </Link>
              <a
                className={classes.link}
                href='https://www.flipcause.com/secure/cause_pdetails/MTQ5OTE3'>
                {t('Donate to Evictorbook')}
                <KeyboardArrowRightIcon fontSize='large' className={classes.linkIcon} />
              </a>
            </Row>
          </div>
        </div>
        <div className={classes.infoStyle}>
          <Row justifyContent='space-around' textAlign='center'>
            {displayItems.map((item, index) => (
              <DisplayItem {...item} key={index} />
            ))}
          </Row>
        </div>
      </DataPage>
    </>
  )
}

export default memo(Home)
