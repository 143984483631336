import { memo } from 'react'
import { useLocation } from 'react-router-dom'
import SearchBar from './DataSearchBar/SearchBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useStyles from '../theme/HeaderStyles'
import HomeIcon from '@mui/icons-material/Home'
import LogoutIcon from '@mui/icons-material/ExitToApp'
import { useTheme } from '@mui/material/styles'
import { Col } from './Grid'
import { DropdownMenu } from '../components/Menu'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import { useAppSelector } from 'src/state/hooks'

/**
 * @param {object} props
 * @param {string} props.themeMode
 * @param {SetState<string>} props.setThemeMode
 * @returns 
 */
const Header = ({ themeMode, setThemeMode }) => {
  /* Get a React Router location object */
  const location = useLocation()

  const isProduction = typeof process.env.NODE_ENV === 'string' &&
    process.env.NODE_ENV === 'production'

  const toggleThemeMode = () => {
    setThemeMode(previous => (previous === 'light' ? 'dark' : 'light'))
  }

  const menuItems = [
    <FormGroup>
      <FormControlLabel control={
        <Switch
          checked={themeMode === 'dark'}
          onChange={toggleThemeMode}
          inputProps={{ 'aria-label': 'Dark Mode' }} />
      } label="Dark Mode" />
    </FormGroup>
  ]

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  const isSmallDisplay = useMediaQuery('(max-width: 600px)')
  const { isLoggedIn } = useAppSelector(state => state.login) ?? {}

  const LoginIndicator = () =>
    isLoggedIn && (
      <Col className={classes.login}>
        {!isSmallDisplay && (
          <Typography variant='body1' className={classes.titleText}>
            LOGGED IN
          </Typography>
        )}
        <Link href='/login' state={{ isLogout: true }} className={classes.title}>
          <LogoutIcon
            htmlColor={theme.palette.primary.dark}
            style={{ fontSize: '2rem' }}
          />
        </Link>
      </Col>
    )

  /* If on the home page, show a minimal header with
   * logout link */
  if (location.pathname === '/') {
    return (
      <Toolbar
        style={{ justifyContent: 'flex-end', border: 'none' }}
        className={classes.root}>
        <LoginIndicator />
      </Toolbar>
    )
  }

  return (
    <Toolbar className={classes.root}>
      <Link style={{ textDecoration: 'none' }} href='/'>
        {isSmallDisplay ? (
          <HomeIcon
            htmlColor={theme.palette.primary.dark}
            style={{ fontSize: '40' }}
          />
        ) : (
          <Typography variant='h3' className={classes.titleText}>
            EVICTORBOOK
          </Typography>
        )}
      </Link>
      <div className={isSmallDisplay ? classes.searchBarMobile : classes.searchBarDiv}>
        <SearchBar location='/results' />
      </div>
      <LoginIndicator />
      { !isProduction &&
          <DropdownMenu items={menuItems} />
      }
    </Toolbar>
  )
}

export default memo(Header)
