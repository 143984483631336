import { memo } from 'react'
import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import { Row, Col } from '../../Grid'
import { formatMoney, reformatDate, titleCase } from '../../../utils/stringUtils'
import { renderParcelLink } from '../../../utils/tableUtils'
import { useTheme } from '@mui/material/styles'
import { isNull } from '../../Data/DataUtils'
import useStyles from '../../../theme/OverlayStyles'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { config } from '../../../config/config'

/** InfoBox: render an information box map overlay
 * @param {object} props
 * @param {any[]} [props.items]
 * @param {string} [props.directions]
 * @param {any} [props.selectedMarker]
 * @param {string[]} [props.displayFields]
 * @param {boolean} [props.displayAllFields]
 * @returns {ReactNode}
 */
const InfoBox = props => {
  
  const select = config.api.response.infoBox.selectors

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  const marker = props.selectedMarker
  const directions = props.directions
  const { displayAllFields = true, displayFields = null } = props

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'DataMap', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )
  if (isNull(marker)) {
    return (
      <Card className={classes.infoBox} title='Eviction Details'>
        <Typography variant='textInvertedColors1'>{directions}</Typography>
      </Card>
    )
  }

  const corp_details = select.businessDetails(marker)

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) return null

  /* formatters are stored in separate fields so that Array.filter() can throw out undefined/null values */
  /** @type {{name: string, value?: string | number | undefined, 
   * formatter?: (val: string | number) => string}[]} */
  const content = [
    /* fields for property info */
    {name: t('Address'), value: select.evictionAddress(marker)},
    {name: t('APN (Block/Lot)'), value: select.parcelId(marker)},
    {name: t('Year Built'), value: select.yearBuilt(marker)},
    {name: t('Zipcode'), value: select.zipCode(marker)},
    {name: t('Property Class Code'), value: select.propertyClassCode(marker)},
    {name: t('Type'), value: select.parcelType(marker)},
    {name: t('Date Filed'), value: select.dateFiled(marker)},
    {name: t('Stories'), value: select.numStories(marker)},
    {name: t('Rooms'), value: select.numRooms(marker)},
    {name: t('Units'), value: select.numUnits(marker)},
    {name: t('Beds'), value: select.numBeds(marker)},
    {name: t('Baths'), value: select.numBaths(marker)},
    {name: t('Partial Baths'), value: select.numPartialBaths(marker)},
    {name: t('Construction Type'), value: select.constructionType(marker)},
    {name: t('Foundation Type'), value: select.foundationType(marker)},
    {name: t('Owner is Trust?'), value: select.ownerIsTrust(marker)},
    {name: t('Owner is Business?'), value: select.ownerIsBusiness(marker)},
    {name: t('Owner Type'), value: select.ownerType(marker), formatter: titleCase},
    {name: t('Owner Occupied?'), value: select.ownerOccupied(marker)},
    {name: t('Recent Tax Delinquent Year'), value: select.taxDelinquentYear(marker)},
    {name: t('Last Sales Price'), value: select.currentSalesPrice(marker), formatter: formatMoney},
    /* fields for owner info */ 
    {name: t('Office Address'), value: select.officeAddress(marker)},
    {name: t('Date Registered'), value: select.dateCreated(marker), formatter: reformatDate},
    {name: t('Corporation Number'), value: select.corporationNumber(corp_details)},
    {name: t('Corporation Type'), value: select.corporationType(corp_details)},
    {name: t('Status'), value: select.status(corp_details)},
    {name: t('Tax Base'), value: select.corporationTaxBase(corp_details)},
    {name: t('Jurisdiction'), value: select.jurisdiction(corp_details)},
    {name: t('LLC Type'), value: select.llcTypeOfBusiness(corp_details)},
    {name: t('LLC Management Code'), value: select.llcManagementCode(corp_details)},
  ].filter(d => d.value) /* only keep the elements where the value exists */ 

  return (
    <Card className={classes.infoBox} title='Title'>
      { select.parcelId(marker)
        ? <Row direction='row' justifyContent='space-between'>
          <Typography variant='textInvertedColors2'>
            {renderParcelLink(select.parcelId(marker), select.evictionAddress(marker), classes.invertedColorScheme)}
          </Typography>
        </Row>
        : <Row direction='row' justifyContent='space-between'>
          <Typography variant='textInvertedColors2'>{select.evictionAddress(marker)}</Typography>
        </Row>
      }
      { /*the content array above is iterated through here */
        content.map((d,i) => 
          (displayAllFields || displayFields.includes(d.name)) &&
            <Row direction='row' justifyContent='space-between' key={i}>
              <Col style={{marginRight: 20}}><Typography variant='textInvertedColors1' style={{fontWeight: 700}}>{d.name}</Typography></Col>
              <Col style={{marginLeft: 20}}><Typography variant='textInvertedColors1'>
                {typeof d.formatter === 'function' ? d.formatter(d.value) : d.value}
              </Typography></Col>
            </Row>
        )
      }
    </Card>
  )
}

export default memo(InfoBox)
