import  { memo } from 'react'
import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import { Row } from '../../Grid'
import { useTheme } from '@mui/material/styles'
import useStyles from '../../../theme/OverlayStyles'

/** 
 * Legend: render a legend map overlay
 * @param {object} props
 * @param {{icon: ReactNode, label: string}[]} props.items: an array with sets of icons and labels
 * @param {string} props.title: the header text
*/
const Legend = ({ items, title }) => {

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Card className={classes.legend}>
      <div style={{ marginBottom: 10 }}>
        <Typography
          variant='textInvertedColors2'>
          {title}
        </Typography>
      </div>
      {
        items.map(({ label, icon }, index) => 
          <Row
            direction='row'
            alignItems='center'
            style={{marginBottom: 10}}
            key={label + index}>
            <span>{icon}&nbsp;&nbsp;</span>
            <Typography variant='textInvertedColors1'>
              {label}
            </Typography>
          </Row>
        )
      }
    </Card>
  )
}

export default memo(Legend)