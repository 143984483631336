import  { memo } from 'react'
import graph from '../images/methodology-graph.png'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { Row, Col } from '../components/Grid'
import PageTitle from '../components/PageTitle'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItem from '@mui/material/ListItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import useStyles from '../theme/UtilStyles'

import DataPage, { selectRecord } from '../components/DataPage/DataPage'
import { useAppSelector } from 'src/state/hooks'
import { config } from '../config/config'

const Methodology = () => {
  const classes = useStyles()

  /* Set up API data selectors */
  const select = config.api.response.methodologyPage.selectors
  const value = config.api.response.methodologyPage.values

  /* Set hooks to use Redux global state */
  const evictionSourceData = useAppSelector(selectRecord('about-evictions'))
  const assessorSourceData = useAppSelector(selectRecord('about-assessor'))
  const businessSourceData = useAppSelector(selectRecord('about-business'))
  const buildingPermitSourceData = useAppSelector(selectRecord('about-building-permits'))
  const buildingComplaintSourceData = useAppSelector(selectRecord('about-building-complaints'))
  const rapPetitionSourceData = useAppSelector(selectRecord('about-rap-petitions'))

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'MethodologyPage', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  const dataSources = [
    {
      source: select.sourceName(assessorSourceData?.[value.sanFrancisco]),
      description: t('A list of parcels, addresses, and current parcel owners in San Francisco'),
      retrieved: select.sourceDate(assessorSourceData?.[value.sanFrancisco])
    },
    {
      source: select.sourceName(assessorSourceData?.[value.oakland]),
      description: t('A list of parcels, addresses, and current parcel owners in Oakland'),
      retrieved: select.sourceDate(assessorSourceData?.[value.oakland])
    },
    {
      source: select.sourceName(businessSourceData?.['ca']),
      description: t('A list of all registered LLC and LP’s and corporations in California, along with their officers, owners, and mailing addresses'),
      retrieved: select.sourceDate(businessSourceData?.['ca'])
    },
    {
      source: select.sourceName(evictionSourceData?.[value.oakland]),
      description: t('Ellis Act eviction data, buyout data, and all eviction notices in Oakland'),
      retrieved: select.sourceDate(evictionSourceData?.[value.oakland])
    },
    {
      source: select.sourceName(evictionSourceData?.[value.sanFrancisco]),
      description: t('Ellis Act eviction data, buyout data, and all eviction notices in San Francisco'),
      retrieved: select.sourceDate(evictionSourceData?.[value.sanFrancisco])
    },
    {
      source: select.sourceName(buildingPermitSourceData?.[value.oakland]),
      description: t('Building permits in Oakland'),
      retrieved: select.sourceDate(buildingPermitSourceData?.[value.oakland])
    },
    {
      source: select.sourceName(buildingComplaintSourceData?.[value.oakland]),
      description: t('Building complaints in Oakland'),
      retrieved: select.sourceDate(buildingComplaintSourceData?.[value.oakland])
    },
    {
      source: select.sourceName(rapPetitionSourceData?.[value.oakland]),
      description: t('Rent Adjustment Program (RAP) petitions filed by the landlord or tenant in Oakland'),
      retrieved: select.sourceDate(rapPetitionSourceData?.[value.oakland])
    },
  ]

  const quirks = [
    {
      title: t('Our search feature may not list a property you are interested in.'),
      info: [
        '', //left intentionally blank for list styling
        t('Sometimes the data we use for property information lists a range of addresses for a single property. E.g. if you are looking for 102 Main St and the property in our database is recorded as “100-104 Main St, it may not show up when you search. If you don’t find your property, try searching adjacent properties.'),
        t('Sometimes properties located on corner lots will have units that are listed on several different cross streets. Because the property is only recorded in our database using one of the cross streets, it may be difficult to find on the Evictorbook.'),
        t('Our database contains all properties that are either currently residential properties or ones that have had an eviction recorded at them in the past.')
      ]
    },
    {
      title: t('Our search feature may not list a business you are interested in.'),
      info: [t('Our database contains businesses that are highly linked to the properties that they own. Often, to avoid creating connections between unrelated businesses, we have to remove businesses from the corporate networks that are not tightly connected to the network. For example, any company that is a property owner, or an evictor, or listed at the same address as the mailing address for a property, will all be in the database. However, businesses more distantly related might not be in the database. We recommend searching a property to find businesses in the network that are on the Evictorbook.')]
    },
    {
      title: t('Looking up the name of a person associated with an eviction and not their business entity.'),
      info: [t('For now, you can only look up their business entity. For instance, you might know that Yat-Pang Au is the CEO and person associated with Veritas (San Francisco’s biggest landlord), but currently you have to search for Veritas or one of Vertitas’s LLCs and not Yat-Pang Au himself.')]
    },
    {
      title: t('The owner percentages on the property pages may not add up to 100%.'),
      info: [t('Our source data currently only lists one owner for each property, even when it is likely that multiple owners exist.')]
    },
    {
      title: t('Some identical evictions are listed on multiple different properties, and some have either no or multiple evictors.'),
      info: [t('Sometimes there are multiple possible properties that correspond to the address listed on the eviction, and sometimes we find multiple evictors for an eviction record, or none at all. Overall we are able to find evictors for more than 90% of the eviction records.')]
    },
    {
      title: t('A business you are looking for may have different information than you were expecting.'),
      info: [t('Sometimes businesses have multiple records attached to them, if they are filed, cancelled, and then later refiled. We use the information from either the active record, or if there are none, the most recently filed inactive record.')]
    },
    {
      title: t('Some evictors and property owners appear to be businesses, but are not linked to a business page, or addresses that are listed separately but look like the same. '),
      info: [t('All of the records we use are quite messy, often with spelling errors from when the records were manually entered. We attempt to clean up these errors, but sometimes the errors are so strange that we cannot connect the records together.')]
    },
    {
      title: t('The evictor assigned to an eviction may be different than you expected.'),
      info: [t('We determine the evictor for each eviction by searching the property owner at the time of the eviction from sales transfer records. Often this results in the correct owner, but because there are sometimes multiple transactions (e.g. between different trusts) that don’t represent transfers between different people, the evictor might look a bit strange.')]
    },
    {
      title: t('A property may be part of multiple possible ownership networks.'),
      info: [t('Our method to connect properties into ownership networks relies on owner mailing address lookups. Sometimes office buildings will have offices with different real estate companies. While we are able to distinguish between these companies, when we look up properties using a mailing address at those buildings, because of recording differences between our source data, we have to assume that the properties could be part of any of those companies.')]
    },
    {
      title: t('The related parcels list may be smaller or longer than you expected.'),
      info: [t('We are constantly learning more about these corporate ownership networks and improving our connection methods. We attempted to tune our method to be able to reproduce many large, widely known, corporate owner networks. However, because of the way that shell companies, investors, management companies, and even insurance companies, operate, unexpected connections sometimes appear. If you see something unexpected or simply wrong, please let us know!')]
    },
  ]

  const connections = [
    {
      title: t('Connecting Properties and Business Entities'),
      info: [
        t('Properties are connected to business entities in one of two ways:'),
        t('The property owner (as obtained from either the County Assessor officer, for current ownership, or sales transfer records, for past ownership) shares the same name as a business entity that is registered with the state, or'),
        t('The property owner’s mailing address is listed at the same or similar address unit as a business entity is.')
      ]
    },
    {
      title: t('Connecting Properties, Evictions and Evictors'),
      info: [
        t('We connect each eviction to the propertie(s) on which they occurred by matching on the address provided in the eviction record. For evictions that cannot be merged using the address exactly (e.g. for corner lots that use different street names, or for currently non-existent addresses), we grab the longitude and latitude of the address and match it to the closest existing parcel.'),
        t('Since the eviction records do not list the evictor, we then match the eviction to the property owner at the time of eviction from sales transfer records. We consider that property owner the likely evictor.'),
        t('Because we sometimes find multiple address matches or multiple sales matches, we may be unable to connect each eviction to a single property and single evictor with 100% confidence. In these cases, we notify the user that these are not confident links.')
      ]
    },
    {
      title: t('Determining Connections between Business Entities'),
      info: [
        t('When businesses file with the Secretary of State, they list both addresses, “officers” (partners for LP’s or CEOs for corporations), and owners. These “officers” and owners can either be individuals or other business entities. Thus, we create connections between businesses when businesses share the same officer or owner, or businesses list one another as an officer or owner.'),
        t('These connections allow us to create “property owner networks.” Each property owner network is a group of interconnected properties, property owners, corporate entities, corporate owners, and evictors. Property owners and evictors could be business entities themselves. Each network is formed by entities that are connected by relationships as defined in the image above.')
      ]
    }
  ]

  return (
    <Row justifyContent='center'>
      <Col xs={10} sm={8} md={6}>
        <DataPage fetchArray={[
          { slice: 'about-evictions', url: '/api/about/eviction' },
          { slice: 'about-assessor', url: '/api/about/assessor' },
          { slice: 'about-business', url: '/api/about/business' },
          { slice: 'about-building-permits', url: '/api/about/buildingPermit' },
          { slice: 'about-building-complaints', url: '/api/about/buildingComplaint' },
          { slice: 'about-rap-petitions', url: '/api/about/rapPetition' },
        ]} isDebugEnabled={false}>
          <PageTitle text={t('Methodology')} />
          <Typography variant='h2'>{t('Data Sources')}</Typography>
          <Typography variant='body1'>
            {t('If you believe any information provided is inaccurate, please contact us through the feedback form and specifically explain the basis of your request for correction or deletion.')}
          </Typography>
          <TableContainer component={Paper} style={{ marginTop: 20 }}>
            <Table aria-label='data sources' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Source</TableCell>
                  <TableCell align='left'>Description</TableCell>
                  <TableCell align='left'>Last Retrieved</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSources.map(({ source, description, retrieved }, i) => (
                  <TableRow key={i}>
                    <TableCell align='left'>{source}</TableCell>
                    <TableCell align='left'>{description}</TableCell>
                    <TableCell align='left'>{retrieved}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant='h2'>{t('Connections')}</Typography>
          <Typography variant='body1'>
            {t('We connect the data from the sources above into a graph network that reveals the connections between properties, property owners, evictors, and business entities.')}
          </Typography>
          &nbsp;
          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            <img src={graph} alt={t('Graph Diagram')} />
          </div>

          {connections.map(({ title, info }) =>
            <Accordion key={title}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant='body1' className={classes.leftAlignText}>
                  {title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                {info.map((point, index) =>
                  <ListItem button key={index}>
                    <Typography className={classes.leftAlignText} variant='body1' key={'connections' + index}>
                      {point}
                    </Typography>
                  </ListItem>
                )}
              </AccordionDetails>
            </Accordion>
          )}
          <Typography variant='h2'>{t('Common Issues & Quirks')}</Typography>
          <Typography variant='body1' style={{ marginBottom: 20 }}>
            {t('While we do our best to ensure that the data and connections shown on this site are as accurate as possible, and we will continue to improve our methods, there are limitations that you should be aware of. We encourage you, where possible, to verify findings on this site using other data sources as well.')}
          </Typography>

          {quirks.map(({ title, info }) =>
            <Accordion key={title}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Typography className={classes.leftAlignText} variant='body1'>
                  {title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                {info.map((point, index) =>
                  <ListItem button key={index}>
                    <Typography variant='body1' key={'quirks' + index} className={classes.leftAlignText}>
                      {point}
                    </Typography>
                  </ListItem>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </DataPage>
      </Col>
    </Row>
  )
}

export default memo(Methodology)