import { forwardRef, memo } from 'react'
import { Loading, Error, NoPage, NoData } from '../NotAvailable'
import Data, { isNull } from '../Data/Data'
import { Children } from '../Data/DataUtils'
export {
  selectRecords,
  selectRecord,
  isDataAvailable,
  selectGeography,
  isNull,
  isEmpty,
  urlParam,
} from '../Data/DataUtils'

/**
 * DataPage: a minimalist wrapper of the Data component suitable for conditionally rendering all
 * content on a page Note: this component should not compose map or table components (that need
 * extra time to load) as it defers loading of content and slows overall page load (which is pretty
 * slow on mobile)
 *
 * @param {object} props
 * @param {any[]} props.fetchArray: An object specifying data that
 *   needs to be fetched (optional)
 * @param {ReactNode | ReactNode[]} props.children
 * @param {boolean} props.isDebugEnabled
 * @param {Ref<HTMLElement>} ref
 */
const DataPage = ({ fetchArray, children, ...rest }, ref) => {
  /* Wait for data to become available */
  if (isNull(fetchArray)) return null

  return (
    <Data fetchArray={fetchArray} {...rest}>
      <Data.Pending component={Loading} />
      <Data.Rejected component={Error} />
      <Data.NoData component={NoData} />
      <Data.Timeout component={NoPage} secondsToWait={45} />
      <Data.Fulfilled>
        <Children childrenToRender={children} ref={ref} />
      </Data.Fulfilled>
    </Data>
  )
}

export default memo(forwardRef(DataPage))
