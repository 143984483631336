import * as d3 from 'd3'

export function renderLinks(graph) {
  const filteredLinks = graph.links.filter(l => !l.shouldHide)
  const link = d3
    .select('g.links')
    .selectAll('line')
    .data(filteredLinks)
    .join('line')
    .attr('class', 'link')

  return link
}

// create paths for labels along links
// referenced by the xlink:href property of the
// edge labels
export function renderEdgePaths(graph) {
  d3.select('g.edgepaths')
    .selectAll('.edgepath')
    .data(graph.links)
    .join('path')
    .attr('class', 'edgepath')
    .attr('fill-opacity', 0)
    .attr('stroke-opacity', 0)
    .attr('id', d => `edgepath${d.index}`)
    .style('pointer-events', 'none')
}

export function updateEdgeLabels(links, theme) {
  d3.select('.edgelabels')
    .selectAll('.edgelabel')
    .data(links, d => d.index)
    .join('text')
    .attr('class', 'edgelabel')
    .attr('font-size', 14)
    .attr('fill', theme.palette.primary.main)
    .append('textPath')
    .attr('xlink:href', d => `#edgepath${d.index}`)
    .attr('startOffset', '50%')
    .text(d => d.label || '')
  updateLabelPositions()
}

export function updateLabelPositions() {
  d3.selectAll('.edgelabel').attr('transform', function (d) {
    if (d.target.x < d.source.x) {
      const bbox = this.getBBox()

      let rx = bbox.x + bbox.width / 2
      let ry = bbox.y + bbox.height / 2
      return 'rotate(180 ' + rx + ' ' + ry + ')'
    } else {
      return 'rotate(0)'
    }
  })

  d3.selectAll('.edgepath').attr(
    'd',
    d => `M ${d.source.x} ${d.source.y} L ${d.target.x} ${d.target.y}`
  )
}
