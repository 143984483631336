import { createRoot } from 'react-dom/client'
import App from './components/App'
import { Provider } from 'react-redux'
import Store from './state/store.js'
import { StyledEngineProvider } from '@mui/material/styles'
import 'leaflet/dist/leaflet.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={Store}>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  </Provider>
)