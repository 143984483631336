import { useState, memo } from 'react'
import Typography from '@mui/material/Typography'
import { Row, Col } from './Grid'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { titleCase } from '../utils/stringUtils'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import useStyles from '../theme/AccordionStyles'
import { useTheme } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'

/** 
 * @param {object} props
 * @param {{
 *  title: string,
 *  expandable: boolean,
 *  detailText: string | null
 *  value: string,
 *  tooltipText?: string
 *  formatter?: (value: string | number) => string
 * }[]} props.fields
 */
const Accordion = ({ fields }) => {

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /** @typedef {false | `panel${number}`} Expanded */
  const [expanded, setExpanded] = useState(/** @type {Expanded} */ (false))

  const iconStyle = { opacity: '0.7', fontSize: '1.25em', margin: '0 .5em' }

  /** @type {(panel: `panel${number}`)=> (_: any, isExpanded: boolean) => void} */
  const handleChange = panel => (_, isExpanded) => setExpanded(isExpanded ? panel : false)

  return (
    <Row justifyContent='center' style={{ marginTop: '2.5rem', marginBottom: '1.5rem' }}>
      <Col xs={10} sm={8} md={6}>
        {fields.map((item, index) => {
          const { title, detailText, expandable, formatter, value } = item ?? {}
          const finalValue = formatter ? formatter(value) : value

          if (
            finalValue === null ||
            finalValue === undefined
          ) { return null }

          return (
            <MuiAccordion
              expanded={expandable ? expanded === 'panel' + index : false}
              key={index}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header'>
                <div className={classes.summary}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Margin values below are temporary, body1 is mostly used in section text and is styled with margin auto auto, will clean this up ~Kent */ }
                    <Typography
                      variant='body1'
                      style={{ marginLeft: 'unset', marginRight: 'unset'}}>
                      <strong>{title}</strong>
                    </Typography>
                    {expandable && <HelpOutlineIcon style={iconStyle} />}
                  </div>
                  {
                    (typeof item.tooltipText === 'string' && item.tooltipText.length > 0)
                      ? <Tooltip title={item.tooltipText}>
                        <Typography
                          variant='body1'
                          style={{ marginLeft: 'unset', marginRight: 'unset'}}>
                          {titleCase(finalValue ?? '-')}
                        </Typography>
                      </Tooltip>
                      : <Typography
                        variant='body1'
                        style={{ marginLeft: 'unset', marginRight: 'unset'}}>
                        {titleCase(finalValue ?? '-')}
                      </Typography>
                  }
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Typography style={{ opacity: '0.7' }}>{detailText && detailText}</Typography>
              </AccordionDetails>
            </MuiAccordion>
          )
        })}
      </Col>
    </Row>
  )
}

export default memo(Accordion)
