import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(
  theme => ({
    container: {
      height: '400px',
      width: '100%'
    }
  })
)
