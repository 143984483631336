import makeStyles from '@mui/styles/makeStyles'

/*
  Component Styles: provides non-global styles for a specific component
    If a style is global it should go in Theme.js not here
*/

export default makeStyles(theme => ({
  graphLegend: {
    display: 'flex',
    justifyContent: 'beginning',
    flex: '1',
    flexWrap: 'wrap',
    marginBottom: 15,
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: '0.8rem',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
  legendText: { // Responsive class for proof of concept
    '@media (max-width:389px)': { // adjust font size
      fontSize: '0.8rem',
    },
  },
  viz: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: '1px',
    cursor: 'grab',
    /* These elements have to be specified with the '& ...' syntax
     * Because they are appended dynamically through d3, not by react
     * */
    '& .edgelabel': {
      pointerEvents: 'none',
    },
    '& textPath': {
      textAnchor: 'middle',
      pointerEvents: 'none',
    },
    '& .link': {
      stroke: theme.palette.graph.links,
    },
    '& .node': {
      '& text': {
        fontSize: 15,
        fontWeight: 'normal',
        textAlign: 'center',
        fill: theme.palette.primary.contrastText,
        pointerEvents: 'none',
      },
      '& rect': {
        cursor: 'pointer',
        opacity: '0.6',
      },
      '& circle': {
        cursor: 'pointer',
      },
    },
  },
}))
