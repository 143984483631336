import  { useState, useMemo, useEffect, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useAppSelector } from 'src/state/hooks'
import { useAppDispatch } from 'src/state/hooks'
import DebugInfo from './DataDebug'
import { isNull, isEmpty, getChildProps, useTimeout } from './DataUtils'
import { fetchData } from './DataState'

/* 
  DataInner: this component is part of the Data component and is not meant to work standalone
  props:
    fetchArray: an array of objects specifying data that needs to be fetched (required)
    isDebugEnabled: a boolean value specifying whether to enable debug mode (reveals a network statistics bar) (optional)
    subComponentChildren[]: an array specifying children to render for various stages of the fetch lifecycle (optional)
  Notes:
    Encapsulates the lifecycle of fetching data and conditional rendering of sub-components for various data states
*/

/* Help ensure fetches are performed only when needed */
export const validateFetchParams = (fetchValue, refValue) => {

  if (isEmpty(fetchValue)) return false
  if (isEmpty(refValue)) return true /* First run */

  const isValidUrls = fetchValue.filter(x => x && typeof x.url === 'string' && x.url.length > 0)?.length === fetchValue.length ? true : false
  if (!isValidUrls) return false /* For edification */
  const isChanged = fetchValue.filter((x, i) => x && refValue[i] && (x.url !== refValue[i].url || x.slice !== refValue[i].slice)).length > 0
  if (isChanged) return true /* Deep equality comparison */
  return false
}

const DataInner = forwardRef((props, ref) => {

  const isDebugEnabled = props.isDebugEnabled
  const subComponentChildren = props.subComponentChildren ?? {}
  const fetchArray = isDebugEnabled
    ? props.fetchArray?.map(x => ({ ...x, debug: true }))
    : props.fetchArray
  const onFetchPromise = props.onFetchPromise
  const previousFetchArray = useRef([])
  const resources = []
  const dispatch = useAppDispatch()
  const [childrenToRender, setChildrenToRender] = useState(null) /* triggers renders when updated */
  const isFetchInProgress = useRef(false) /* does NOT trigger renders when updated */
  const isResolved = useRef(false)
  const secondsToWait = useMemo( /* returns a cached value that changes with its dependency */
    () => getChildProps(subComponentChildren.timeout)?.secondsToWait ?? 0,
    [subComponentChildren.timeout]
  )
  const isTimeout = useTimeout(secondsToWait, isResolved.current)

  /* Get all state slices from Redux */
  const slices = useAppSelector(state => state?._fetch)

  /* Dispatch a redux action to fetch data when the value of fetch changes */
  useEffect(() => {
    if (validateFetchParams(fetchArray, previousFetchArray.current)) {
      const promise = dispatch(fetchData(fetchArray))
      onFetchPromise?.(promise)
      isFetchInProgress.current = true
      isResolved.current = false
      previousFetchArray.current = fetchArray
    }
  }, [dispatch, onFetchPromise, fetchArray])
  
  /* Wait for data to become available */
  if (isNull(props)) { return null }

  /* Pass-Through Mode: if fetch is not valid then display <Data.Fulfilled> */
  if (isEmpty(fetchArray)) {
    return (
      <DebugInfo
        {...props}
        resources={resources}
        isDebugEnabled={isDebugEnabled}
        childrenToRender={subComponentChildren.fulfilled}
        ref={ref}
      />
    )
  }

  /* Get statistics for all fetch requests to use for conditional rendering */
  let [numRequests, numPending, numFulfilled, numNoData, numRejected] = [0,0,0,0,0]
  if (!isEmpty(slices)) {
    fetchArray.forEach(request => {

      const slice = request.slice
      const resource = slices?.[slice]

      if (isNull(resource)) { return }

      if (!isEmpty(resource)) {
        if (resource.isDataRequired === false) {
          console.info(`%c Data for slice '${slice}' is marked as not required`)
        }
        if (resource.pending) { numPending++ }
        if (resource.fulfilled) {
          numFulfilled++
          if (resource.response?.ok === false) {
            numNoData++ /* Includes 404 errors returned from the API */
          }
          else {
            if (isEmpty(resource.json) && resource.isDataRequired === true) {
              numNoData++
            }
          }
        }
        if (resource.rejected) { numRejected++ }
        if (resource.fulfilled || resource.rejected) {
          resources.push(resource)
        }
        numRequests++
      }
    })
  }

  /* Conditionally render sub-components */
  if (isTimeout === true) {
    const timeoutChildren = subComponentChildren.timeout /* <Data.Timeout> */
    if (childrenToRender !== timeoutChildren) setChildrenToRender(timeoutChildren)
  }
  else if (numRequests > 0 && numRequests === numFulfilled + numRejected) {

    /* Update operational state */
    isResolved.current = true /* Stop the timer */
    isFetchInProgress.current = false

    /* Conditionally render sub-components */
    if (numRejected > 0 && !isDebugEnabled) {
      const rejectedChildren = subComponentChildren.rejected /* <Data.Rejected> */
      if (childrenToRender !== rejectedChildren) setChildrenToRender(rejectedChildren)
    }
    if (
      numRequests === numFulfilled ||
      (isDebugEnabled && numRequests === numFulfilled + numRejected)
    ) {
      if (numNoData > 0) {
        const noDataChildren = subComponentChildren.nodata /* <Data.NoData> */
        if (childrenToRender !== noDataChildren) setChildrenToRender(noDataChildren)
      }
      else {
        const fulfilledChildren = subComponentChildren.fulfilled /* <Data.Fulfilled> */
        if (childrenToRender !== fulfilledChildren) setChildrenToRender(fulfilledChildren)
      }
    }
  }
  else {
    if (numPending > 0) {
      const pendingChildren = subComponentChildren.pending /* <Data.Pending> */
      if (childrenToRender !== pendingChildren) setChildrenToRender(pendingChildren)
    }
  }

  return (
    <DebugInfo
      {...props}
      resources={resources}
      isDebugEnabled={isDebugEnabled}
      childrenToRender={childrenToRender}
      ref={ref}
    />
  )

})

DataInner.propTypes = {
  fetchArray: PropTypes.array,
  subComponentChildren: PropTypes.object,
  isDebugEnabled: PropTypes.bool
}

export default DataInner