import { useMemo, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import useStyles from '../../../../theme/ListStyles'
import FilterListIcon from '@mui/icons-material/FilterList'
import ToggleButton from '@mui/material/ToggleButton'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { parseDate, titleCase } from '../../../../utils/stringUtils'
import dayjs from 'dayjs'

/** 
 * @typedef {(r: any, index: number) => boolean} Filter 
 * @typedef {{[index: number]: Filter}} FilterConditions
*/

/** Template for filters -- creates basic UI, and leaves logic and state
 * for wrapper components. Individual inputs should be passed as children.
 * @param {object} props
 * @param {() => void} props.resetFilter
 * @param {() => void} props.updateFilter
 * @param {ReactNode} props.children
 */
function BaseFilter({ resetFilter, updateFilter, children }) {
  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  const [isActive, setIsActive] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <Grid className={classes.filter}>
      <ToggleButton
        value='check'
        selected={isActive}
        onClick={event => setAnchorEl(event.currentTarget)}
        className={classes.filterButton}
        aria-label='Show filters'>
        <FilterListIcon className={classes.filterIcon} />
      </ToggleButton>
      <Popover
        classes={{ paper: classes.filterPopup }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        {children}
        <Grid container flexDirection='row' justifyContent='center' gap='1rem'>
          <Button
            variant='contained'
            onClick={() => {
              resetFilter()
              setIsActive(false)
              setAnchorEl(null)
            }}>
            Reset
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              updateFilter()
              setIsActive(true)
              setAnchorEl(null)
            }}>
            Submit
          </Button>
        </Grid>
      </Popover>
    </Grid>
  )
}

/** Displays a minimum and maximum bound for toggling 
 * @param {object} props
 * @param {number[]} props.values
 * @param {import('@c/DataTable/DataTable').Column} props.column
 * @param {number} props.columnIndex
 * @param {SetState<FilterConditions>} props.setFilterConditions
*/
function NumberFilter({ values, column, columnIndex, setFilterConditions }) {
  const [minValue, setMinValue] = useState(Math.min(...values))
  const [maxValue, setMaxValue] = useState(Math.max(...values))

  function updateFilter() {
    /** @type {Filter} */
    const newFilter = row => {
      const value = column.sortSelector ? column.sortSelector(row) : row[column.field]
      return value >= minValue && value <= maxValue
    }
    setFilterConditions(oldFilters => ({ ...oldFilters, [columnIndex]: newFilter }))
  }

  function resetFilter() {
    setFilterConditions(oldFilters => ({ ...oldFilters, [columnIndex]: null }))
    setMaxValue(Math.max(...values))
    setMinValue(Math.min(...values))
  }

  return (
    <BaseFilter resetFilter={resetFilter} updateFilter={updateFilter}>
      <TextField
        label='Equal to or greater than'
        type='number'
        InputLabelProps={{
          shrink: true,
        }}
        value={minValue}
        onChange={e => setMinValue(Number(e.target.value))}
      />
      <TextField
        label='Equal to or less than'
        type='number'
        InputLabelProps={{
          shrink: true,
        }}
        value={maxValue}
        onChange={e => setMaxValue(Number(e.target.value))}
      />
    </BaseFilter>
  )
}

/** Displays a checklist of strings; checking a string includes rows with that value 
 * @param {object} props
 * @param {string[]} props.values
 * @param {import('@c/DataTable/DataTable').Column} props.column
 * @param {number} props.columnIndex
 * @param {SetState<FilterConditions>} props.setFilterConditions
*/
function StringFilter({ values, column, columnIndex, setFilterConditions }) {
  const theme = useTheme()
  const classes = useStyles(theme)

  const uniqueValues = useMemo(() => {
    return values.reduce((prev, curr) => {
      if (!curr) return prev
      prev[curr] = true
      return prev
    }, /** @type {{[k: string]: boolean}} */ ({}))
  }, [values])

  const [checked, setChecked] = useState(uniqueValues)

  if (!Object.keys(uniqueValues).length) return

  function updateFilter() {
    /** @type {Filter} */
    const newFilter = row => {
      const value = column.sortSelector ? column.sortSelector(row) : row[column.field]
      return checked[value]
    }
    setFilterConditions(oldFilters => ({ ...oldFilters, [columnIndex]: newFilter}))
  }

  /** @param {ChangeEvent<HTMLInputElement>} event */
  function handleChange(event) {
    setChecked({ ...checked, [event.target.name]: event.target.checked })
  }

  function resetFilter() {
    setFilterConditions(oldFilters => ({ ...oldFilters, [columnIndex]: null }))
    setChecked(uniqueValues)
  }

  return (
    <BaseFilter resetFilter={resetFilter} updateFilter={updateFilter}>
      <FormGroup>
        <Grid
          container
          flexDirection='column'
          flexWrap='nowrap'
          className={classes.filterOptionsContainer}>
          {Object.keys(checked).map(value => {
            return (
              <FormControlLabel
                key={value}
                control={<Checkbox checked={checked[value]} onChange={handleChange} name={value} />}
                label={
                  <Typography className={classes.filterLabel} variant='body1'>
                    {titleCase(value)}
                  </Typography>
                }
              />
            )
          })}
        </Grid>
        <Grid container flexDirection='row' justifyContent='center' gap='1rem'>
          <Button
            variant='contained'
            onClick={() => {
              setChecked(prevState =>
                Object.keys(prevState).reduce((prev, curr) => ({ ...prev, [curr]: false }), {})
              )
            }}>
            Deselect all
          </Button>
          <Button variant='contained' onClick={() => setChecked(uniqueValues)}>
            Select all
          </Button>
        </Grid>
      </FormGroup>
    </BaseFilter>
  )
}

/** Displays a two-line form, setting a start date and end date to filter down to a range of rows 
 * @param {object} props
 * @param {string[]} props.values
 * @param {import('@c/DataTable/DataTable').Column} props.column
 * @param {number} props.columnIndex
 * @param {SetState<FilterConditions>} props.setFilterConditions
*/
function DateFilter({ values, column, columnIndex, setFilterConditions }) {
  const dates = values.map(parseDate)

  const [startDate, setStartDate] = useState(dayjs(Math.min(...(dates.map(Number)))))
  const [endDate, setEndDate] = useState(dayjs(Math.max(...(dates.map(Number)))))

  function updateFilter() {
    setFilterConditions(oldFilters => {
      /** @type {Filter} */
      const newFilter = row => {
        const date = column.sortSelector
          ? parseDate(column.sortSelector(row))
          : parseDate(row[column.field])
        const day = dayjs(date)
        return (
          (day.isSame(startDate) || day.isAfter(startDate)) &&
          (day.isBefore(endDate) || day.isSame(endDate))
        )
      }
      return { ...oldFilters, [columnIndex]: newFilter }
    })
  }

  function resetFilter() {
    setFilterConditions(oldFilter => ({ ...oldFilter, [columnIndex]: null }))
    setStartDate(dayjs(Math.min(...(dates.map(Number)))))
    setEndDate(dayjs(Math.max(...(dates.map(Number)))))
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BaseFilter updateFilter={updateFilter} resetFilter={resetFilter}>
        <DatePicker
          label='On or after'
          value={startDate}
          onChange={setStartDate}
        />
        <DatePicker
          label='On or before'
          value={endDate}
          onChange={setEndDate}
        />
      </BaseFilter>
    </LocalizationProvider>
  )
}

/** Generic Filter component to distribute to type-specific filters
 * @param {object} props
 * @param {any[]} props.rows
 * @param {import('@c/DataTable/DataTable').Column} props.column
 * @param {number} props.columnIndex
 * @param {SetState<FilterConditions>} props.setFilterConditions
*/
function Filter(props) {
  const { rows, column } = props
  if (!column.type) return

  const values = column.sortSelector
    ? rows.map(column.sortSelector)
    : rows.map(row => row[column.field])

  switch (props.column.type) {
    case 'date':
      return <DateFilter values={values} {...props} />
    case 'number':
      return <NumberFilter values={values} {...props} />
    case 'string':
      return <StringFilter values={values} {...props} />
    default:
      return <StringFilter values={values} {...props} />
  }
}

export default Filter
