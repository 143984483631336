import makeStyles from '@mui/styles/makeStyles'

/*
  Component Styles: provides non-global styles for a specific component
    If a style is global it should go in Theme.js not here
*/

export default makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.main,
      border: `solid 1px ${theme.palette.secondary.dark}`,
      padding: 0,
      '& > *': {
        margin: '0 1rem',
      },
      textDecoration: 'none'
    },
    title: {
      textDecoration: 'none',
      display: 'flex',
      placeItems: 'center',
    },
    titleText: {
      fontFamily: 'Oswald',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      fontWeight: 600,
      textDecoration: 'none',
      '&::hover': {
        textDecoration: 'none !important',
      },
    },
    searchBarDiv: {
      flex: 1,
      justifySelf: 'flex-end',
      margin: '0.5rem',
    },
    searchBarMobile: {
      flex: 1,
      alignSelf: 'flex-start',
      justifySelf: 'flex-end',
      margin: '0.4rem',
    },
    login: {
      display: 'flex',
      placeItems: 'center',
      gap: '0.5rem',
    }
  })
)
