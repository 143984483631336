import { memo, useState } from 'react'
import { useAppSelector } from 'src/state/hooks'
import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import Button from '@mui/material/Button'
import { commaSeparatedBolded } from '../utils/stringUtils'
import { isEmpty, selectRecords } from './DataPage/DataPage'
import { useTheme } from '@mui/material/styles'
import { Row } from './Grid'
import useStyles from '../theme/ListStyles'
import { useTranslation } from 'react-i18next'
import { generateGraphIdUrl } from '../utils/tableUtils'
import i18n from '../i18n'
import { ReactComponent as NetworkLink } from '../images/network-icon.svg'
import { config } from '../config/config'

/**
 * Shows a list of related networks
 * @param {object} props
 * @param {string} props.slice
 * @param {string} props.noDataMessage
*/
const NetworkList = ({
  slice,
  noDataMessage
}) => {

  /* Set up API data fields and selectors */
  const select = config.api.response.networkList.selectors
  const value = config.api.response.networkList.values

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'NetworkList', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Local state */
  const [showAll, setShowAll] = useState(false)

  /* Set hooks to use Redux global state */
  const related = useAppSelector(selectRecords(slice))

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  const ownershipNetworkLabel = t('Ownership network containing')

  if (isEmpty(related))
    return (
      <>
        <Typography variant='h2'>{t('Related Ownership Networks')}</Typography>
        <Typography variant='body1'>{noDataMessage}</Typography>
      </>
    )

  /* TODO: a lot of translations need to be added below. Issue #594 created */

  const handleShowMoreClick = () => setShowAll(true)

  return (
    <>
      <Typography variant='h2'>{t('Related Ownership Networks')}</Typography>
      <Typography variant='body1'>
        {t('Related ownership networks are those which have something important in common with the ownership network highlighted on this page. A summary of each related network is shown below.')}
      </Typography>
      <br />
      { /* always show the first three networks */
        related.slice(0, showAll ? related.length : 3).map((network, i) => {

          const description = select.networkDescription(network)
          const id = select.networkId(network)

          // creates the rows for each related network card, providing
          // an explanation for why we believe the networks are related
          const explanation_section = select.networkExplanations(network).map((exp) => {
            let firstChipLabel
            let firstChipCount
            let secondChipLabel
            let secondChipCount
            let cardText

            switch (select.explanationType(exp)) {
              case value.businessesListedAtAddresses:
                // Case: There are businesses in one networks that have
                //       listed office addresses in the other network
                firstChipCount = select.numAddresses(exp)
                firstChipLabel = t('Address', { count: firstChipCount })
                secondChipCount = select.numBusinesses(exp)
                secondChipLabel = t('Business', { count: secondChipCount })
                cardText = t('shared between networks')
                break

              case value.relatedToExcluded:
                // Case: There are businesses in the networks that are
                //       connected via a business or an address that we 
                //       have determined to be a likely "unreliable"
                //       connector (e.g. might be an address for a 
                //       business filing service)


                firstChipCount = select.numRelatedToExcluded(exp)
                firstChipLabel = t('Business', { count: firstChipCount })
                cardText = t('between networks associated with a likely unreliable business or address connection')
                break

              case value.businessesOwningParcels:
                // Case: There are businesses in one networks that are 
                //       listed as the property owner for properties in
                //       the other network 


                firstChipCount = select.numBusinesses(exp)
                firstChipLabel = t('Business', { count: firstChipCount })
                secondChipCount = select.numParcels(exp)
                secondChipLabel = t('Property', { count: secondChipCount })
                cardText = t('shared between networks')
                break

              case value.parcelsOwnedBySameNonBusinessOwner:
                // Case: There are properties in the two networks that
                //       both have a non-business owner. E.g. might be a 
                //       person or a trust that owns both. 

                firstChipCount = select.numParcels(exp)
                firstChipLabel = t('Property', { count: firstChipCount })
                cardText = t('owned by same non-business owner between networks')
                break

              case value.similarAddresses:
                // Case: There are office addresses in one network that
                //       have the same street address as addresses in the other
                //       (i.e. they are in the same building but have different
                //       suite / unit numbers)

                cardText = t('Similar business addresses between networks')
                break

              default:
                break
            }

            /* Return for network.explanations.map((exp) => */
            return (
              <div key={`${select.explanationType(exp)}`}>
                {<hr style={{
                  border: 0,
                  opacity: '0.6',
                  borderTop: '1px solid'
                }} />}
                {firstChipLabel && firstChipCount && <Chip
                  sx={{
                    bgcolor: theme.palette.chip.backgroundColor,
                    color: theme.palette.chip.color
                  }}
                  size="small"
                  avatar={<Avatar variant="circular" style={{
                    backgroundColor: theme.palette.chip.avatarBackgroundColor,
                    color: theme.palette.chip.avatarColor,
                    width: (firstChipCount > 99) ? '24px' : null
                  }}>{firstChipCount}</Avatar>}
                  label={t('{{firstChipLabel}}', {firstChipLabel})}
                  style={{
                    marginRight: '3px',
                    cursor: 'pointer'
                  }}
                />}
                {secondChipLabel && secondChipCount && <Chip
                  sx={{
                    bgcolor: theme.palette.chip.backgroundColor,
                    color: theme.palette.chip.color
                  }}
                  size="small"
                  avatar={<Avatar variant="circular" style={{
                    backgroundColor: theme.palette.chip.avatarBackgroundColor,
                    color: theme.palette.chip.avatarColor,
                    width: (firstChipCount > 99) ? '24px' : null
                  }}>{secondChipCount}</Avatar>}
                  label={secondChipLabel}
                  style={{
                    marginRight: '3px',
                    cursor: 'pointer'
                  }}
                />}
                {<span>
                  {' '}{cardText}
                </span>
                }
              </div>
            )
          })

          /* Return for related.slice(0, 3).map((network,i) => */
          return (
            <Row
              key={`${description}-${i}`}>
              <Card style={{
                borderRadius: '5px',
                marginBottom: 15,
                marginTop: 15,
                fontFamily: 'Source Sans Pro, sans-serif',
                border: 0,
                fontSize: '0.9rem',
                width: '100%',
                overflowX: 'auto',
              }}>
                <CardActionArea
                  href={generateGraphIdUrl(id)}>
                  <CardContent style={{
                    paddingTop: 10,
                    paddingBottom: 10
                  }}>
                    <div className={classes.cardText} style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                      <div style={{
                        marginLeft: 10,
                        display: 'flex',
                        flexDirection: 'row'
                      }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <span style={{
                              width: 55,
                              margin: '0',
                              padding: '0'
                            }}>
                              <NetworkLink style={{
                                width: 55,
                                height: 50
                              }} />
                            </span>
                            <Typography variant='body1'
                              style={{
                                margin: '0 10px',
                                fontSize: '1em'
                              }}>
                              {ownershipNetworkLabel}{' '}
                              {commaSeparatedBolded([
                                select.topBusinessName(description),
                                select.topOwner(description),
                                select.topAddress(description)
                              ])}
                            </Typography>
                          </div>
                          {explanation_section}
                        </div>
                      </div>
                      <div style={{
                        marginLeft: 10,
                        marginRight: 10,
                        whiteSpace: 'nowrap',
                        display: 'flex'
                      }}>
                        <Divider orientation='vertical' flexItem />
                        <div style={{
                          flexDirection: 'column',
                          marginLeft: 15,
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <div>{select.numParcelsInDescription(description)}{' '}{t('properties')}</div>
                          <div>{select.numUnitsInDescription(description)}{' '}{t('units')}</div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Row>
          )
        })
      }
      { /* render a "show more" button when there are more than three networks */
        related.length > 3 && showAll === false &&
        <Button className={classes.showMoreButton} onClick={handleShowMoreClick}>
          <Typography variant='body1'>Show More</Typography><ArrowDropDown />
        </Button>
      }
    </>
  )

}

export default memo(NetworkList)
