import { updateEdgeLabels } from './links'
import * as d3 from 'd3'

/* Create unique IDs for neighbor pairs
 * Stored in object for fast lookup
 * Object kept outside of the function for ergonomics (temporary)
 */
let adjacentNodeList = {}
export function createAdjacentNodeList(graph) {
  adjacentNodeList = {}
  graph.links.forEach(d => {
    adjacentNodeList[d.source.index + '-' + d.target.index] = true
    adjacentNodeList[d.target.index + '-' + d.source.index] = true
  })
}

function focus(_, activeNode, graph, theme) {
  const { index, dragging } = activeNode
  d3.selectAll('.node').style('opacity', function (o) {
    function areNeighbors(a, b) {
      return a === b || adjacentNodeList[a + '-' + b]
    }
    return areNeighbors(index, o.index) ? 1 : 0.6
  })
  d3.selectAll('.links.link').style('opacity', d => {
    return d.source.index === index || d.target.index === index ? 1 : 0.6
  })

  // calculate newEdgelabels if the current node isn't being dragged
  //    otherwise don't show anything, to keep performance
  const newEdgelabels = !dragging
    ? graph.links.filter(d => {
        const isBeingDragged = d.source.index === index || d.target.index === index
        return isBeingDragged ? 1 : 0
      })
    : []

  updateEdgeLabels(newEdgelabels, theme)
}

export function unfocus(theme) {
  d3.selectAll('.node').transition().duration(100).style('opacity', 1)
  d3.selectAll('.link').transition().duration(100).style('opacity', 1)
  updateEdgeLabels([], theme)
}

export function onMouseOver(event, d, graph, theme) {
  focus(event, d, graph, theme)
}

export function onMouseMove(event, d, graph, theme) {
  focus(event, d, graph, theme)
}

export function onMouseOut(d, theme) {
  if (!d.dragging) unfocus(theme)
}
