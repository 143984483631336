/**
 * @param {object} props 
 * @param {'topleft'|'topright'|'bottomleft'|'bottomright'} props.position
 * @param {ReactNode} props.children
 * @returns 
 */
const Overlay = props => {

  const margin = 4
  /** @type {CSSProperties} */
  let styleObject = {
    position: 'absolute',
    zIndex: 10000
  }

  switch (props.position) {
    case 'topleft':
      styleObject.top = margin
      styleObject.left = margin
      break
    case 'topright':
      styleObject.top = margin
      styleObject.right = margin
      break
    case 'bottomleft':
      styleObject.bottom = margin
      styleObject.left = margin
      break
    case 'bottomright':
      styleObject.bottom = margin + 30 /* Attribution */
      styleObject.right = margin
      break
    default:
      break
  }

  return (<div
    style={styleObject}>
    {props.children}
  </div>)
}

export default Overlay