import makeStyles from '@mui/styles/makeStyles'

/*
  Component Styles: provides non-global styles for a specific component
    If a style is global it should go in Theme.js not here
*/

export default makeStyles(
  theme => ({
    summary: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '1rem'
    },
    details: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
)
