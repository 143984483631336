import  { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Children } from './DataUtils'

/* 
  DataDebug: a component to render debug information in the UI
*/

const DebugInfo = forwardRef(({
  childrenToRender, /* Child elements that should be rendered (optional) */
  isDebugEnabled = false,
  resources
}, ref) => {

  /* Set up Material-UI theme and mix in local styling */
  const theme = useTheme()
  const useStyles = makeStyles(theme =>
    ({
      root: { fontSize: 10, marginBottom: 2 },
      title: { fontSize: 12 },
      body: { fontSize: 10 },
      cardcontent: { padding: 4, '&:last-child': { paddingBottom: 4 } },
      paper: { paddingLeft: 5, paddingRight: 5},
      td: { paddingRight: 20 }
    })
  )
  const classes = useStyles(theme)

  /* If debug is disabled then just render children */
  if (isDebugEnabled === false || resources?.length === 0) {
    return <Children childrenToRender={childrenToRender} ref={ref} />
  }

  /* Helper components to render repetitive content to enhance readability */
  const TH = p => <td className={classes.td}><Paper className={classes.paper}>{p.children}</Paper></td> /* Header */
  const TD = p => <td className={classes.td}>{p.children}</td> /* Columns */

  const Info = props =>
    <Card className={classes.root}>
      <CardContent className={classes.cardcontent}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          [Debug Mode] Network Statistics
        </Typography>
        <div className={classes.body}>
          <table>
            <tbody>
              <tr><TH>Name</TH><TH>Url</TH><TH>Status</TH><TH>Size</TH><TH>Roundtrip</TH><TH>Encoding</TH><TH>Cache</TH></tr>
              {Array.isArray(resources) &&
                resources.map(resource => 
                  <tr key={resource.slice}>
                    <TD>{resource.slice}</TD>
                    <TD>{resource.url}</TD>
                    <TD>{resource.response?.status}</TD>
                    <TD>{JSON.stringify(resource.json)?.length}b</TD>
                    <TD>{(resource.response?.timeReceived - resource.response?.timeSent) / 1000}s</TD>
                    <TD>{resource.response?.contentEncoding}</TD>
                    <TD>{resource.response?.cacheControl}</TD>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </CardContent>
    </Card>

  return (
    <>
      {isDebugEnabled === true && <Info resources={resources} />}
      <Children childrenToRender={childrenToRender} ref={ref} />
    </>
  )
})

DebugInfo.propTypes = {
  childrenToRender: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  resources: PropTypes.array,
  isDebugEnabled: PropTypes.bool
}

export default memo(DebugInfo)