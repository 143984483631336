import makeStyles from '@mui/styles/makeStyles'

/*
  Component Styles: provides non-global styles for a specific component
    If a style is global it should go in Theme.js not here
*/

export default makeStyles(
  theme => ({
    root: {
      position: 'relative',
      height: 'fit-content',
      '& .search-results-item': {
        display: 'flex',
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        width: 'inherit',
        height: '3.5rem',
        ariaLabel: 'search results',
        borderBottom: `1px ${theme.palette.primary.light} solid`
      },
      '& .search-results-item div.searchResultText:hover': {
        textDecoration: 'always',
      },
      '& .search-results-item:hover': {
        backgroundColor: theme.palette.background.menuHover,
        color: theme.palette.primary.light
      },
      /* Outline for when link is focused on via keyboard */
      /* DO NOT REMOVE. THIS IS FOR KEYBOARD ACCESSIBILITY. */
      '& .search-results-item:focus': {
        outline: `3px solid ${theme.palette.primary.light}`,
      },
      /* text in focused Link div. works in tandem with the rule above. */
      '& .search-results-item:focus div.searchResultText': {
        textDecoration: 'always',
        fontWeight: '900',
      },
      '& button:focus': {
        outline: `3px solid ${theme.palette.primary.light}`,
      },
      '& .wrapper-div': {
        flex: 1,
        height: 'fit-content',
        zIndex: 900,
        alignItems: 'center',
        marginRight: '0',
        paddingRight: '0',
      },
      '& .search-bar': {
        position: 'absolute',
        width: '100%',
        margin: '0 auto',
        marginBottom: -0.5,
        //border: `1px ${theme.palette.primary.light} solid`,
      },
      '& .search-bar-header': {
        width: '100%',
        // add't header styles can go here
      },
      '& .search-form': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        justifyContent: 'center',
      },
      '& .searchResultText': {
        fontSize: '18px',
        color: theme.palette.primary.main,
      },
      '& .searchTypeText': {
        //doesn't actually seem to be overriding the MUI typography css
        color: theme.palette.secondary.main,
        fontSize: 13,
      },
      '& .input': {
        backgroundColor: theme.palette.background.default,
        height: `${theme.mixins.toolbar.minHeight}`,
        '& *::selection': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        },
      },
      '& .result-icon': {
        color: theme.palette.primary.light,
        margin: 0,
        padding: 0,
      },
    },
    searchResults: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: '0, auto',
      width: '100%',
      backgroundColor: theme.palette.background.default,
      marginTop: -2,
      zIndex: 998,
      borderTop: `1px ${theme.palette.primary.light} solid`,
      borderLeft: `2px ${theme.palette.primary.light} solid`,
      borderRight: `2px ${theme.palette.primary.light} solid`,
      borderBottom: `1px ${theme.palette.primary.light} solid`
    },
  })
)
