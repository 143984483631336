import  { memo } from 'react'
import { useAppSelector } from 'src/state/hooks'
import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import { Link } from 'react-router-dom'
import DataPage, { selectRecords, isEmpty } from '../components/DataPage/DataPage'
import { Row, Col } from '../components/Grid'
import PageTitle from '../components/PageTitle'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { config } from '../config/config'
import OaklandMapImage from '../images/maps/main_v0.2_300_lg.jpeg'
import SFMapImage from '../images/maps/sf.jpeg'
import { useParams } from 'react-router-dom'

const columnStyle = /** @type {const} */ ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

/**
 * NeighborhoodListPage: shows a list of all neighborhoods in Oakland
 * This page is temporary
 * The plan is to add neighborhood features to the interactive map on EvictionPage
 * which will replace what is done here on a separate page
*/
const NeighborhoodListPage = () => {

  /* Set hooks to use Redux global state */
  const neighborhoodList = useAppSelector(selectRecords('neighborhood-list'))

  /* Sort the network list, which has been frozen with Object.freeze() by Redux */
  const sortedNetworkList = Array.isArray(neighborhoodList)
    ? [...neighborhoodList].sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
    : []
  
  /* Set up API data fields and selectors */
  const select = config.api.response.neighborhoodListPage.selectors
  const values = config.api.response.neighborhoodListPage.values

  /* Get the id path parameter from the URL bar */
  const params = useParams()
  const { geography } = params ?? {}

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'NeighborhoodListPage' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) {
    return null
  }

  const NeighborhoodMap = () => {
    return (
      <Row alignItems="center" justifyContent="center">
        <Col>
          <img
            src={geography === values.oakland ? OaklandMapImage : SFMapImage}
            alt={t('Map of {{geography}} Neighborhoods', {
              geography: geography === values.oakland ? 'Oakland' : 'San Francisco'
            })}
            style={{ maxWidth: '100%', height: 'auto' }}>
          </img>
        </Col>
      </Row>
    )
  }

  const NeighborhoodList = () => {

    /* Wait for data to become available */
    if (isEmpty(sortedNetworkList)) {
      return null
    }

    return (
      <Row style={{ marginTop: '2.5rem', display: 'inline-flex', flexFlow: 'row wrap' }}>
        {
          sortedNetworkList.map(neighborhood => (
            <Col key={select.id(neighborhood)}>
              <Card
                style={{
                  borderRadius: '3px',
                  margin: 5,
                  border: 0
                }}>
                <CardActionArea component={Link} to={`${select.detailsURL(neighborhood)}`}>
                  <CardContent
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px',
                    }}>
                    <Typography variant='body1' style={{ fontSize: '0.9rem' }}>
                      {select.name(neighborhood)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Col>
          ))
        }
      </Row>
    )
  }

  return (
    <Row>
      <Col xs={12}>
        <DataPage
          fetchArray={[
            { slice: 'neighborhood-list', url: `/api/neighborhood/${geography}/list` },
          ]}
          isDebugEnabled={false}>
          <NeighborhoodMap />
          <Row justifyContent='center'>
            <Col xs={10} sm={8} style={columnStyle}>
              <PageTitle
                text={geography === values.oakland ? t('Oakland Neighborhoods') : t('San Francisco Neighborhoods')}
              />
              <NeighborhoodList />
            </Col>
          </Row>
        </DataPage>
      </Col>
    </Row>
  )
}

export default memo(NeighborhoodListPage)
