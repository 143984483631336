import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(
  theme => ({
    page: {
      display: 'flex',
      flexDirection: 'column',
      placeItems: 'center'
    },
    container: {
      width: '40ch'
    },
    inputs: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    },
    button: {
      color: theme.palette.primary.contrastText,
      width: '100%'
    },
  })
)
