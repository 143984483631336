import { useState, forwardRef, useMemo, useEffect, memo, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import ArrowRight from '@mui/icons-material/ArrowRight'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { isNull, isEmpty } from '../../Data/DataUtils'
import { Row } from '../../Grid'
import Link from '@mui/material/Link'
import useStyles from '../../../theme/ListStyles'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import BaseTable from './BaseTable/BaseTable'
import { CSVLink } from 'react-csv'
import { toUnderscore } from '../../../utils/stringUtils'
import InputAdornment from '@mui/material/InputAdornment'
import { flatten } from '../../../utils/dataUtils'
import { config } from '../../../config/config'

const select = config.api.response.dataTable.selectors

/** 
 * @typedef {'asc' | 'desc'} Order
 * @typedef {{
 *  field: string,
 *  sort: Order
 * }} SortModel
 */

/**
 * @typedef {{
 *  source: string,
 *  href: string,
 * notes: string
 * }} AboutData
 */

/** 
 * @typedef {Object} AdornedTablePropsBase
 * @prop {any[]} rows
 * @prop {import('../DataTable').Column[]} columns
 * @prop {('desc' | 'asc' | null)[]} [sortingOrder]
 * @prop {string} [name] Is this parameter used anywhere here?
 * @prop {string} csvFileName
 * @prop {() => ReactNode} [searchHint]
 * @prop {string} [noDataMessage]
 * @prop {string} [nofilteredDataMessage]
 * @prop {AboutData} [aboutData]
 * @prop {string} [title]
 * @prop {boolean} [isDebugEnabled]
 * @prop {string} [className]
 * @prop {(rows: any[]) => void} [onSearchFilter]
 * 
 * @typedef {AdornedTablePropsBase & import("./BaseTable/BaseTable").BaseTablePublicProps} AdornedTableProps
 */

/** AdornedTable: a version of the BaseTable component with header and footer components
 * @param {AdornedTableProps} props
 * @param {Ref<HTMLDivElement>} ref
 */
const StyledTable = (props, ref) => {

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [showAbout, setShowAbout] = useState(false)
  const timeOfLastSearchFieldKeyPress = useRef(null)
  const onSearchFilter = props.onSearchFilter
  const SearchHint = props.searchHint
  const aboutData = props.aboutData
  const csvFileName = props.csvFileName ||
    'please_add_csvFileName_property_to_DataTable'

  const rows = props.rows

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'DataTable', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  const nofilteredDataMessage = props.nofilteredDataMessage ??
    t('No data is present with the specified search term.')

  /* Set final state for TextField input changes post-debounce */
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedSearchTerm(searchTerm), 1000)
    return () => clearTimeout(timer)
  }, [searchTerm])

  /* List of field names to search for table searching */
  const columnNames = useMemo(
    () => props.columns.map(x => x.field)
    , [props.columns])

  /* Map field names to searchSelector functions for efficient lookups */
  const searchSelectors = new Map()
  props.columns.forEach(x => {
    if (typeof x.searchSelector === 'function') {
      searchSelectors.set(x.field, x.searchSelector)
    }
  })
  /** @param {string} term */
  const normalizeSearchTerm = term => {
    return term?.toLowerCase() ?? ''
  }

  /** Returns a searchable text value for a given row and column
   * There is some extra complexity here since some column values
   * require using a selector function to retrieve the value. The
   * logic to support that is why 'row' and 'fieldName' are needed
   * @param {any} value
   * @param {string} fieldName}
   * @param {typeof rows[number]} row
  */
  const normalizeColumnSearchValue = (value, fieldName, row) => {
    let newValue = ''
    if (searchSelectors.has(fieldName)) {
      /* If there is a searchSelector for this field then use it get the value */
      const selector = searchSelectors.get(fieldName)
      value = selector(row)
    }
    if (value === null || value === undefined) {
      newValue = 'unknown'
    }
    else if (typeof value === 'string') {
      if (value === '') {
        newValue = 'unknown'
      }
      else {
        newValue = value.toLowerCase()
      }
    }
    else if (typeof value === 'number') {
      newValue = value.toString().toLowerCase()
    }
    else if (typeof value === 'object') {
      newValue = JSON.stringify(value).toLowerCase()
    }
    /* Return only lowercase strings */
    return newValue
  }

  /* Filter table rows with a search term */
  /* Only search columns that are displayed in the table */
  const filteredRows = useMemo(() => {
    const normalTerm = normalizeSearchTerm(debouncedSearchTerm)
    if (normalTerm) {
      let filteredResults = []
      for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < columnNames.length; j++) {
          let normalY = normalizeColumnSearchValue(
            rows[i][columnNames[j]], /* Value */
            columnNames[j], /* Field name */
            rows[i] /* Row */
          )
          if (normalY) {
            if (
              (normalY === 'unknown' && normalTerm === 'unknown') || /* The user is searching for unknown and this field matches */
              normalY.indexOf(normalTerm) >= 0 /* The term the user is searching for is a substring */
            ) {
              filteredResults.push(rows[i])
              break /* out of inner loop */ 
            }
          }
        }
      }
      return filteredResults ? filteredResults : rows
    }
    else return rows
    // eslint-disable-next-line
  }, [debouncedSearchTerm, rows])

  /* Call the onSearchFilter event handler if present */
  useEffect(() => {
    if (typeof onSearchFilter === 'function') {
      onSearchFilter(filteredRows)
    }
    // eslint-disable-next-line
  }, [filteredRows])

  /* Wait for data to become available */
  if (isNull(props)) { return null }

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  /* Component to define the content of the toolbar at the top of the table */
  const Toolbar = memo(() => {

    /** Set intermediate state for TextField input changes pre-debounce 
     * @param {ChangeEvent<HTMLInputElement>} e
    */
    const handleTextFieldChange = e => {
      const newTerm = typeof e.target.value === 'string' ? e.target.value : ''
      setSearchTerm(newTerm)
    }

    /* Flatten all available data in CSV downloads */
    const csvRows = useMemo(() => {
      return filteredRows?.map(row => {
        const flatRow = flatten(row)
        return ({ ...flatRow })
      })
      // eslint-disable-next-line
    },[filteredRows])

    return (
      <Row direction='row' justifyContent='space-between' className='header'>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          marginBottom: 3
        }}>
          {(
            (!isEmpty(csvRows) && Array.isArray(csvRows)) &&
            (typeof csvFileName === 'string' && csvFileName.length > 0)
          ) && <CSVLink
            data={csvRows}
            className={classes.alwaysUnderline}
            filename={toUnderscore(csvFileName) + '.csv'}>
            <Row direction='row' wrap='nowrap'>
              <GetAppOutlinedIcon htmlColor={theme.palette.primary.main}/>
              <Typography variant='body1'>Download as CSV</Typography>
            </Row>
          </CSVLink>}
        </div>
        <div>
          <Row alignItems='center' sx={{ mb: 1 }}>
            <div style={{marginRight: 20, fontWeight: 100}}>
              { searchTerm !== '' && SearchHint && <SearchHint/> }
            </div>
            <TextField
              inputRef={input =>
                input && /* Proceed only if the input param exists */
                (/* If a key was pressed recently (persist cursor across re-renders) */
                  Date.now() - timeOfLastSearchFieldKeyPress.current < 5000 ||
                  searchTerm !== debouncedSearchTerm /* If a debounce is in progress */
                ) && /* If either of the above are true */
                input.focus() /* Steal focus */
              }
              autoComplete='off'
              aria-label={t('search the table')}
              placeholder={t('Search Table')}
              size='small'
              variant="standard"
              onChange={handleTextFieldChange}
              onKeyUp={e => timeOfLastSearchFieldKeyPress.current = Date.now()}
              value={searchTerm}
              ref={ref}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon htmlColor={theme.palette.primary.main} />
                  </InputAdornment>
                )}}
            />
          </Row>
        </div>
      </Row>
    )
  }, [SearchHint])

  function AboutHeader() {

    const handleClick = _ => setShowAbout(!showAbout)

    return (
      !isNull(aboutData) &&
      <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
        <Button
          className={classes.aboutButton}
          color="primary"
          size="small"
          onClick={handleClick}>
          <Row direction='row' alignItems='center' wrap='nowrap'>
            <Typography variant='body1' style={{
              flexWrap: 'nowrap',
              whiteSpace: 'nowrap'
            }}>
              &nbsp;{t('About this data')}&nbsp;
            </Typography>
            { showAbout ? <ArrowDropDown/> : <ArrowRight/> }
          </Row>
        </Button>
      </div>
    )
  }

  function AboutContent() {

    return (
      !isNull(aboutData) &&
      <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
        <Box display={showAbout ? 'block' : 'none'}>
          <Card className={classes.aboutCard}>
            <Row direction='row' alignItems='center'>
              <Typography
                variant='body1'
                style={{ marginLeft: 'unset', marginRight: 'unset', note: 'This is temporary, body1 is mostly used in section text and is styled with margin auto auto, will clean this up ~Kent' }}>
                {t('Source:')}&nbsp;
              </Typography>
              { !isNull(select.aboutDataUrl(aboutData))
                ? <Typography variant='body1'>
                  <Link
                    component={RouterLink}
                    style={{
                      color: theme.palette.primary.main,
                      '& a::hover': { textDecoration: 'always' }
                    }}
                    to={{pathname: select.aboutDataUrl(aboutData)}}>
                    {select.aboutDataSource(aboutData)}
                  </Link>
                </Typography>
                : <Typography variant='body1'>
                  {select.aboutDataSource(aboutData)}
                </Typography>
              }
            </Row>
            <Row direction='row' alignItems='flex-start'>
              <Typography variant='body1'>{t('Notes:')}&nbsp;</Typography>
              <Typography variant='body1'>{select.aboutDataNotes(aboutData)}</Typography>
            </Row>
          </Card>
        </Box>
      </div>
    )
  }

  return (
    <div
      className={props.className}
      style={{ contain: 'layout', marginLeft: 'auto', width: '100%',  overflowX: 'auto'  }}
      tag='allow the browser to optimize rendering performance'>
      {/* `marginLeft: 'auto'` and `minWidth: '500px'` are
      necessary for readable scrolling and overflow behavior on
      small screen sizes */}
      <div
        style={{ width: '100%', minWidth: '500px'}}>
        <Toolbar/>
        <BaseTable
          { ...props }
          rows={filteredRows}
          aboutHeader={AboutHeader}
          aboutContent={AboutContent}
          searchFilter={debouncedSearchTerm}
          nofilteredDataMessage={nofilteredDataMessage}
        />
      </div>
    </div>
  )
}

export default memo(forwardRef(StyledTable))
