import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { Row } from '../components/Grid'
import InterpolateTranslation from '../components/InterpolateTranslation'

const iframeURL = 'https://docs.google.com/forms/d/e/1FAIpQLSeRV_CdzTvEiQAOcDtE4Oq92SabyFfpb1OHM8MrfVXO1LuaoQ/viewform?embedded=true'

const useStyles = makeStyles(
  theme => ({
    linkStyle: {
      opacity: '0.6', 
      cursor: 'pointer', 
      textDecoration: 'always',
      paddingTop: '3em', 
      fontSize: '1em' 
    },
    column: {
      height: 850, 
      backgroundColor: theme.palette.primary.main, 
      color: theme.palette.primary.contrastText, 
      marginTop: '1em'
    },
    intro: {
      padding: '1rem',
    },
    inlineLink: {
      textDecoration: 'always'
    },
  })
)

const TakeAction = () => {

  const classes = useStyles()

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'TakeActionPage', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )
  if (!translationReady) return null

  return (
    <>
      <Row justifyContent="center" className={classes.intro}>
        <Typography variant='body1'>
          <InterpolateTranslation components={[
            ({children}) => <Link to='/about' className={classes.inlineLink}>
              {children}
            </Link>]}>
            {t('Evictorbook was made by the Anti-Eviction Mapping Project; you can read more about our story on our <1>about page</1>.')}
          </InterpolateTranslation>
          <InterpolateTranslation components={[
            ({children}) => <Link to={{pathname: '//www.flipcause.com/secure/cause_pdetails/MTQ5OTE3'}} target = '_blank' className={classes.inlineLink}>
              {children}
            </Link>
          ]}>
            {t(' Please fill out the form below if you\'d like to get involved with our work, and please consider donating to support our cause!')}
          </InterpolateTranslation>
        </Typography>
      </Row>
      <Grid
        style={{ height: '100vh', minHeight: 1850 }}
        container
        spacing={0}
        wrap="nowrap"
        direction="column"
        alignItems="center"
      >
        <Row justifyContent="center" style={{ minHeight: 1850 }}>
          <iframe src={iframeURL}
            id="iframe"
            width='100%'
            height='fit-content' 
            frameBorder={0} 
            marginHeight={0}
            marginWidth={0}
            title="feedback form"
          >
          </iframe>
        </Row>
      </Grid>
    </>
  )
}

export default TakeAction
