import makeStyles from '@mui/styles/makeStyles'

/*
  Component Styles: provides non-global styles for a specific component
    If a style is global it should go in Theme.js not here
*/

export default makeStyles(
  theme => ({
    title: {
      color: theme.palette.primary.main,
      fontSize: '4rem',
      '@media (max-width:389px)': {
        fontSize: '3rem',
      },  
      textAlign: 'center',
      margin: '2rem 0',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none !important',
      },
    },
    introBackground: {
      backgroundColor: theme.palette.secondary.main,
      border: `solid 0 1px 1px 1px ${theme.palette.secondary.dark}`,
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      placeItems: 'center',
      padding: '4rem 0',
      color: theme.palette.primary.main,
    },
    intro: {
      width: '130ch',
      maxWidth: '90%',
    },
    iconStyle: {
      height: 70,
      width: 70,
      marginBottom: 20,
    },
    colStyle: {
      width: '100%',
      marginBottom: 25
    },
    infoStyle: {
      minHeight: '45vh',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      alignItems: 'center',
      padding: 25,
    },
    heading: {
      fontWeight: 600,
      fontSize: '1.5rem',
      color: theme.palette.primary.main,
    },
    link: {
      fontWeight: 600,
      fontSize: '1.5rem',
      verticalAlign: 'text-bottom',
      textDecoration: 'underline'
    },
    linkIcon: {
      fontSize: '2rem',
      verticalAlign: 'bottom',
      height: '100%',
    },
    columnText: {
      fontSize: 18,
      marginBottom: 25,
      color: theme.palette.primary.main,
      maxWidth: '100%'
    },
    primaryLinks: {
      marginTop: '1.75rem',
      gap: '1rem'
    }
  })
)
