import { Fragment } from 'react'

/** This component receives a string that will be translated and renders its
 * associated markup dictated by the `components` array. This allows us to use the
 * `t()` function a single time for a sentence with markup, like "This is my <1>sentence with markup</1>", where
 * "<1>" is intended to be a React or HTML component.
 *
 * The string and markup should be of the form `my string <1>my markup</1>`, where `<1>`
 * refers to the first component in the `components` param.
 *
 * This component is fragile in the sense that it will error on malformed syntax. Also,
 * absolutely no nesting of components is allowed -- despite the resemblance
 * of the separator syntax to XML, no syntax tree is formed.
 * @param {object} props
 * @param {string} props.children - the string to parse and interpolate, wrapped in t()
 * function, passed as a child to this component. Example: `t("My string <1>my markup</1> end")`
 * <Interpolate components=[...]> {t()}}</Interpolate>
 * @param {ElementType[]} props.components - Array of React components to substitute
 * the `<1>` markup-like expressions with. Must be anonymous functions without props except children.
 * Example: `[({children}) => <em>{children}</em>]`
 */
const InterpolateTranslation = ({ children, components }) => {
  if (typeof children !== 'string') return
  // split string by opening and closing tags -- we'll call these "tokens"
  const expr = /(<\/?[0-9]+\/?>)/
  const tokens = children.split(expr)

  /* The following logic constructs an array of components, like:
    ["a test string", <Component>child string</Component>, "ending string"] */
  return tokens.reduce((markup, token, index) => {
    if (token.match(expr)) {
      // test if it's a closing tag by seeing if it has an opening slash in tag name
      const isClosing = token.match(/<\//)
      // if closing tag for a component, skip (it served its purpose for
      // identifying the markup content and no further action is needed)
      if (isClosing) return markup
      // if not, it's an opening tag, store component (will be modified with following token)
      const componentIndex = Number(token.match('[0-9]+')[0]) - 1
      return [...markup, components[componentIndex]]
    }

    // if last token was an opening tag, then this token is its child
    const lastToken = tokens[index - 1]
    if (lastToken && lastToken.match(/<[0-9]+\/?/)) {
      const Component = markup[markup.length - 1]
      return [...markup.slice(0, -1), <Component key={index}>{token}</Component>]
    }

    // Otherwise it's just an ordinary string, so return that as the "component"
    return [...markup, <Fragment key={index}>{token}</Fragment>]
  }, [])
}

export default InterpolateTranslation
