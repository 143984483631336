import { useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import BusinessIcon from '@mui/icons-material/Business'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import { isNull } from '../Data/DataUtils'
import { useTheme } from '@mui/material/styles'
import { config } from '../../config/config'

const MAX_DROPDOWN_SEARCH_RESULTS = 5

/* ------------- SEARCH BAR DROPDOWN RESULT LIST HELPERS ------------------- */

const select = config.api.response.search.selectors
const value = config.api.response.search.values

/** 
 * @param {object} props
 * @param {string} props.category
 * @returns 
 */
const CustomListIcon = props => (
  <ListItemIcon color="secondary" className='result-icon'>
    {props.category === value.parcel ? <HomeOutlinedIcon/> 
      : props.category === value.neighborhoodSummary ? <RoomOutlinedIcon/> 
        : props.category === value.businessEntity ? <BusinessIcon/>
          : null}
  </ListItemIcon>
)

/**
 * @param {object} props
 * @param {boolean} props.isLoading
 * @param {any} props.data
 * @param {MouseEventHandler} props.handleResultClick
 */
export const CustomDropdownList = ({ isLoading, data, handleResultClick }) => {

  const theme = useTheme()

  const persistedData = useRef([])

  if (data && data.length > 0) {
    persistedData.current = data
  }

  const isMediumDisplay = useMediaQuery('(min-width: 800px')

  const trimmed_data = persistedData.current?.slice(0, MAX_DROPDOWN_SEARCH_RESULTS)

  /* Wait for data to become available */
  if (isNull(trimmed_data)) { return null }

  return (
    <List style={{margin: 0, padding: 0, display: 'inline' }}>
      {trimmed_data.map((item, index) => (
        <ListItem
          key={index}
          component={RouterLink}
          to={select.detailsUrl(item)}
          onClick={handleResultClick}
          className='search-results-item'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            textDecoration: 'none!important',
            '&::hover': { textDecoration: 'always' }
          }}>
          <div style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row'
          }}>
            <CustomListIcon category={select.category(item)} />
            <ListItemText inset
              style={{ padding: 0, margin: 0 }}
              primary={
                <Typography variant='body1' style={{ opacity: isLoading ? '0.7' : '1.0' }}>
                  {select.label(item)}
                </Typography>
              }
            />
          </div>
          {isMediumDisplay &&
            <div style={{ alignItems: 'flex-end' }}>
              <ListItemText inset primary={
                <Typography variant='body1' className = 'searchTypeText' style={{
                  textDecoration: 'none!important',
                  '&::hover': { textDecoration: 'always' }
                }}>
                  {select.categoryLabel(item)}
                </Typography>
              }
              />
            </div>
          }
        </ListItem>
      ))}
    </List>
  )
}
