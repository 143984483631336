import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

/*
  i18n Initialization
  notes:

    Language Translations In This Application

      This application uses react-i18next and associated libraries to perform
      translation from English to languages such as Chinese and Spanish.

      Translation files are located under ui/public/locales and are in JSON
      format. There is a folder under 'locales' for each language.

      The translations must be performed by hand by copying the files
      under the 'en' folder to other folders such as 'zh' (Chinese)
      and 'es' (Spanish). The JSON files have key/value pairs. The key must
      remain untranslated, and the value on the right of the : is what should
      be translated. Spaces in the text should be preserved unless there is
      a language reason not to. Variables wrapped with {{ and }} should be
      preserved and not translated or they will not work.

      Only static text that does not change on the site can be translated.
      If the data itself is in English, it must remain in English even if
      a translation is in use.

      The t() function performs the translation. The first argument is the key,
      the value to the left of the : in local files, which in this application
      is the text which also will be translated. The second argument is an
      object with key/value pairs for variables embedded in the text string
      of the first argument, if any, with syntax such as {{ variable }}.

      When changing the source code for the translations, make one change at
      a time and test that the parser picks up the changes, as it is unable
      to detect some code syntax, so there can be unexpected quirks

      It is not necessary to edit the JSON translation files manually, for
      the most part.

      Run 'npx i18next' in the 'ui' directory to parse source files and
      automatically extract text per config file 'ui/i18next-parser.config.js'.
      The configuration can be changed to support what you are trying to do.

      The parser will remove keys that no longer exist. Any new keys will be
      added to the file, and existing keys will not automatically be updated.
      The parser can create _plural keys when certain variables are present,
      but the text grammar needs to be manually updated.

      The translation JSON files need to be curated to update the grammar for
      plurals and to remove any falsely detected plurals as well as to find
      any oddities.

      If the text in these files needs to be changed, it must be changed in
      the source code first, then 'npx i18next' run from the 'ui' folder in
      order to re-add the modified text.

      Below is the configuration for i18n within the application. This is
      different from the configuration for i18next-parser.

      To see all status messages for i18n in the console, set debug to
      enabled in the configuration below.
*/

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: true
    },
    lng: 'en',
    load: 'all',
    ns: ['HomePage'],
    preload: ['en'],
    debug: false, /* Enable verbose console logging for troubleshooting */
    fallbackLng: 'en',
    lowerCaseLng: true,
    interpolation: { escapeValue: false },
    nsSeparator: false,
    keySeparator: false
  })

/* Enable console logging for errors */
i18n.on('missingKey', (lng, namespace, key, fallbackValue) => {
  console.warn(lng, namespace, key, fallbackValue)
})
i18n.on('failedLoading', (lng, namespace, msg) => {
  console.warn(lng, namespace, msg)
})

/* Enable console logging when the language changes */
i18n.on('languageChanged', (lng) => {
  console.info(`Language changed to ${lng} `)
})

export default i18n