import { createSlice } from '@reduxjs/toolkit'
import { purgeFetched } from '../../components/Data/DataState'
import { createAppAsyncThunk } from '../hooks'


/** Check out ./searchStateSlice.js for impl. details around redux,
 * but TLDR is:
 *
 * - to *set* login status, import attemptLogin() and useDispatch() and call
 *   dispatch(attemptLogin({username, password}))
 *    - this sends a POST request to the server
 *
 *   If you must, for example on the first site load, you can
 *   set the login status manually by importing setLoggedIn
 *   and calling dispatch(setLoggedIn(newLoginState))
 *
 * - to *get* login status, call useAppSelector(state => state.login)
 *  - NB that components calling useAppSelector like that will
 *    automatically rerender on login state changes
 *
 * the login state is of the shape: {
 *    isLoggedIn: true | false,
 *    fetchStatus: 'idle' | 'pending' | 'success' | 'rejected' |
 *    'loggedout'
 * }
 */

export const attemptLogin = createAppAsyncThunk('login', async (req, { rejectWithValue }) => {
  const isLoggedIn = await fetch('/api/login', {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(req),
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
    .then(res => res.json())
    .catch(e => rejectWithValue({ req, error: e }))
  return isLoggedIn
})

export const logout = createAppAsyncThunk('logout', (_, thunkApi) => {
  const {dispatch} = thunkApi
  dispatch(purgeFetched())
  return fetch('/api/logout', { method: 'POST', credentials: 'same-origin' }).then(res =>
    res.json()
  )
})

const loginSlice = createSlice({
  name: 'login',
  initialState: { isLoggedIn: false, fetchStatus: 'idle' },
  reducers: {
    setLoggedIn(state, action) {
      const { isLoggedIn, fetchStatus } = action.payload
      // only rewrite existing state if updated 
      // property is provided
      return {
        ...state,
        isLoggedIn: typeof isLoggedIn === 'undefined' ? 
          state.isLoggedIn : 
          isLoggedIn,
        fetchStatus: typeof fetchStatus === 'undefined' ? 
          state.fetchStatus :
          fetchStatus
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(attemptLogin.fulfilled, (_, action) => {
        return {
          isLoggedIn: action.payload.isLoggedIn,
          fetchStatus: 'success'
        }
      })
      .addCase(attemptLogin.pending, state => {
        return {
          ...state,
          fetchStatus: 'pending'
        }
      })
      .addCase(logout.pending, state => {
        return {
          ...state,
          fetchStatus: 'pending'
        }
      })
      .addCase(logout.fulfilled, () => {
        return {
          isLoggedIn: false,
          fetchStatus: 'loggedout'
        }
      })
  }
})

export default loginSlice.reducer
export const { setLoggedIn } = loginSlice.actions
