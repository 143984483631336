import makeStyles from '@mui/styles/makeStyles'

/*
  Component Styles: provides non-global styles for a specific component
    If a style is global it should go in Theme.js not here
*/

export default makeStyles(
  theme => ({
    page: {
      marginTop: 20,
      marginLeft: 20,
      display: 'flex',
      placeContent: 'center'
    },
    content: {
      maxWidth: '70ch'
    },
    header: {
      marginBottom: theme.spacing(3),
      color: theme.palette.primary.main,
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: '1.25rem',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    subHeader: {
      color: theme.palette.primary.main,
      opacity: 0.5,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      }
    },
    card: {
      marginBottom: '.5rem',
      width: '100%',
      textAlign: 'left',
      borderRadius: '3px',
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
        textDecoration: 'none'
      },
    },
    cardContainer: {
      minWidth: '400px',
    },
    avatar: {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.contrastText,
    },
    searchTable: {
      //overriding ListStyles.js
      '& table': {
        backgroundColor: theme.palette.primary.main,
      },
      '& thead': {
        display: 'none',
      },
    },
    missingData: {
      opacity: '0.6',
      padding: '1rem'
    }
  })
)
