import {useRef, useEffect} from 'react'

/* Functions to improve readability and consistency with loading and error state */
export const applyDefaultPendingState = state => Object.assign(state, { loading: true, error: false })
export const applyDefaultRejectedState = (state, action) => {
  Object.assign(state, { error: action?.payload?.error, loading: false })
  console.error(action?.payload?.error)
}
export const applyDefaultFulfilledState = state => Object.assign(state, { loading: false, error: false })

/* Functions to improve readability with data validation */
export const isNull = x => x === undefined || x === null
export const isNotString = x => isNull(x) || typeof x !== 'string'
export const isNotArray = x => isNull(x) || !Array.isArray(x)
export const isNotObject = x => isNull(x) || typeof x !== 'object'
export const isEmpty = x => {
  const isArray = Array.isArray(x)
  const isObject = typeof x === 'object'
  if (isNull(x)) { return true }
  if (isArray && x.length <= 0) { return true }
  if (isObject && Object.keys(x).length <= 0) { return true }
  return false
}

/** Helper function to determine indefinite article
 * This is idiomatic English that can't be computed logically 
 * @param {string} status
 * */
export const beStatusIndefiniteArticle = status => {
  switch (status) {
    case 'SOS/FTB Suspended/Forfeited':
    case 'Active':
    case 'Inactive': return 'an'
    default:
      return 'a'
  }
}

/* Logic to determine equality from primary and secondary data sources */
export const compareState = (primary, secondary) => {
  return ({ isEqual: primary === secondary })
}

export const stableSort = (arr, compare) => arr
  .map((item, index) => ({item, index}))
  .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
  .map(({item}) => item)

/* taken from https://usehooks.com/useMemoCompare/ */ 
export const useMemoCompare = (next, compare) => {
  // Ref for storing previous value
  const previousRef = useRef()
  const previous = previousRef.current
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next)
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next
    }
  })
  // Finally, if equal then return the previous value
  return isEqual ? previous : next
}

/* Flattens data out of a hierarchical structure */
export const flatten = (obj, prefix = [], current = {}) => {
  if (typeof(obj) === 'object' && obj !== null) {
    for (const key of Object.keys(obj)) {
      flatten(obj[key], prefix.concat(key), current)
    }
  } else {
    current[prefix.join('.')] = obj
  }
  return current
}
