import  { memo } from 'react'
import { useAppSelector } from 'src/state/hooks'
import { Typography } from '@mui/material'
import DataTable, { isEmpty, selectRecords } from './DataTable/DataTable'
import { titleCase } from '../utils/stringUtils'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { renderParcelLink, renderOwnerLink } from '../utils/tableUtils'
import { config } from '../config/config'

/** PropertyList: render a table with a list of properties
 * @param {import('./DataTable/DataTable').ListProps} props
*/
const PropertyList = ({
  title, 
  subTitle, 
  noDataMessage, 
  slice, 
  aboutData, 
  onSearchFilter, 
  searchHint, 
  csvFileName
}) => {

  /* Set up API data fields and selectors */
  const select = config.api.response.propertyList.selectors
  const field = config.api.response.propertyList.fields

  /* Set hooks to use Redux global state */
  const portfolio = useAppSelector(selectRecords(slice)) ?? []
  const ownerDetails = useAppSelector(selectRecords('owner-details'))

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'PropertyList', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  if (isEmpty(portfolio)) {
    return <>
      <Typography variant='h2'>{title}</Typography>
      <Typography variant='body1'>{noDataMessage}</Typography>
    </>
  }
  
  return (
    <>
      <Typography variant='h2'>{title}</Typography>
      <Typography variant='body1'>{subTitle}</Typography>
      <DataTable
        noDataMessage={noDataMessage}
        aboutData={aboutData}
        sortModel={[{ field: field.parcelAddress, sort: 'asc' }]}
        isDebugEnabled={false}
        name='properties'
        rows={portfolio}
        onSearchFilter={onSearchFilter}
        searchHint={searchHint}
        columns={[
          {
            field: field.parcelAddress,
            headerName: t('Address'),
            flex: 2,
            type: 'string',
            renderCell: p => renderParcelLink(
              select.parcelId(p.row),
              titleCase(select.parcelAddress(p.row))
            ),
            searchSelector: row => select.parcelAddress(row),
            sortSelector: row => select.parcelAddress(row)
          },
          {
            field: field.ownerName,
            headerName: t('Owner Name'),
            flex: 3,
            type: 'string',
            renderCell: p => renderOwnerLink(
              select.ownerName(p.row),
              select.businessType(p.row)
            ),
            searchSelector: row => select.ownerName(row),
            sortSelector: row => select.ownerName(row)
          },
          {
            field: field.ownerAddress,
            headerName: t('Owner Mailing Address'),
            flex: 3, 
            type: 'string',
            renderCell: p => renderParcelLink(
              select.ownerAddressParcelId(p.row),
              select.ownerAddress(p.row)
            ),
            searchSelector: row => select.ownerAddress(row),
            sortSelector: row => select.ownerAddress(row)
          },
          { field: field.numUnits, headerName: t('# of Units'), flex: 1.5, type: 'number'},
          { field: field.numEvictions, headerName: t('Evictions Filed'), flex: 1.5, type: 'number'}
        ]}
        csvFileName={csvFileName}
      />
    </>
  )
}

export default memo(PropertyList)