import  { memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { Row, Col } from '../components/Grid'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import PageTitle from '../components/PageTitle'
import Link from '@mui/material/Link'
import InterpolateTranslation from '../components/InterpolateTranslation'

const Disclaimer = () => {
  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'DisclaimerPage', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )
  
  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  const lineBreak = <span style={{ width: '100%', display: 'inline-block' }} />

  return (
    <Row justifyContent='center'>
      <Col xs={10} sm={8} md={6}>
        <PageTitle text={t('Disclaimer')} />
        <hr style={{ opacity: '0.6' }} />
        <Typography variant='body1'>
          {t('The information on this website is sourced from publicly available data that is provided by, created by, and maintained by third-party sites. We are making that data available here. ')}
          <InterpolateTranslation
            components={[({children}) => (
              <Link style={{textDecoration: 'always', fontWeight: 'bold'}}
                to={{pathname: '/methodology'}}
                component={RouterLink}>
                {children}
              </Link>
            )]}
          >
            {t('<1>Our methodology page</1> lists the sources we use, as well as the dates on which we last retrieved data from each source. We do not collect data on the users of this website.')}
          </InterpolateTranslation>

          {lineBreak}
          {t('An evictor is defined here as an entity or corporation taking legal action to remove someone from occupying a building. Evictions themselves are not defamatory. Moreover, the appearance of a person, entity, or corporation on this website does not necessarily mean that they have evicted someone or taken advantage of eviction laws.')}
          {lineBreak}
          {t('The information on this website is provided “as is,” “as available,” and “with all faults.” Your access to, any reliance upon, and/or use of the information is at your sole risk. To the fullest extent permissible by law, Mapping Action Collective and Anti-Eviction Mapping Project hereby disclaim all express or implied warranties of any kind including but not limited to accuracy and non-infringement, and make no representations, warranties, endorsements, or promises, express or implied, in connection with, or otherwise directly or indirectly related to, the information provided on this website. Under no circumstances shall Mapping Action Collective or Anti-Eviction Mapping Project or its members, employees, volunteers, and affiliates be liable for any indirect, incidental, punitive, special, or consequential damages (including but not limited to damages from personal injury and/or death, lost revenues or profits, loss of business or loss of data) that are directly or indirectly related to the use of the website.')}
          {lineBreak}
          {t('The information on this website does not constitute legal advice and must not be used as a substitute for the advice of a lawyer qualified to give advice on legal issues pertaining to housing. We can help direct you to free legal services if necessary.')}
          {lineBreak}
          {t('We at the Mapping Action Collective and Anti-Eviction Mapping Project do not condone any violent, harassing, threatening, or destructive behavior directed at landlords, their families, their associates, or their property, or at anyone else. Rather, we encourage our website users to conduct themselves peacefully and responsibly at all times.')}
        </Typography>
      </Col>
    </Row>
  )

}

export default memo(Disclaimer)
