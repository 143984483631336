import * as d3 from 'd3'
import { renderLinks, updateEdgeLabels, renderEdgePaths, updateLabelPositions } from './links'
import { renderNodes } from './nodes'
import { createAdjacentNodeList } from './hover'

export const NODE_RADIUS = 6
export const MARKER_SIZE = 0

export default function drawGraph(graph, nodeColors, theme) {
  if (!d3.select('#viz').size()) return

  const svg = d3.select('#viz')
  const width = svg.node().getBoundingClientRect().width
  const height = width
  svg.style('height', height + 'px')
  const container = svg.append('g').attr('class', 'container')

  const zoom = d3
    .zoom()
    .scaleExtent([0.25, 4])
    .on('zoom', event => container.attr('transform', event.transform))
  svg.call(zoom)

  // set up d3 force simulation
  const simulation = d3
    .forceSimulation(graph.nodes)
    .force('charge', d3.forceManyBody().strength(-1500)) // repel all nodes from each other
    .force('center', d3.forceCenter(width / 2, height / 2))
    .force(
      'link',
      d3
        .forceLink(graph.links)
        .id(n => n.id)
        .distance(150)
        .strength(1)
    )
    .alphaDecay(0.1) // make simulation cool faster
    .on('tick', tick)

  createAdjacentNodeList(graph)

  /* Create containers; handled outside of render function because 
      they are a one-time event, while rendering can be done multiple times as 
      data changes
  */
  container.append('g').attr('class', 'edgelabels')
  container.append('g').attr('class', 'edgepaths')
  container.append('g').attr('class', 'links')
  container.append('g').attr('class', 'nodes')

  renderEdgePaths(graph)
  renderLinks(graph)
  renderNodes(graph, nodeColors, theme, simulation)

  /* Wait for the network to settle, then zoom to fit the graph */
  setTimeout(() => {
    const bounds = container.node().getBBox()
    const parent = container.node().parentElement

    const { width, height } = bounds
    const midX = bounds.x + width / 2
    const midY = bounds.y + height / 2

    const scale = 1 / Math.max(width / parent.clientWidth, height / parent.clientHeight)
    const translate = [
      parent.clientWidth / 2 - scale * midX,
      parent.clientHeight / 2 - scale * midY,
    ]

    const transformer = d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)

    svg.transition().duration(500).call(zoom.transform, transformer)
  }, 1000)

  function tick() {
    // unless near end of simulation, don't show labels
    const alphaRemaining = simulation.alpha() - simulation.alphaMin()
    if (alphaRemaining < 0.1) {
      updateLabelPositions()
    } else {
      updateEdgeLabels([], theme)
    }

    d3.selectAll('g.node').attr('transform', function (d) {
      if (!isFinite(d.x) || !isFinite(d.y)) return ''
      let damper = 0
      d.x = d.x + (width / 2 - d.x) * (damper + 0.02) * 0.1
      d.y = d.y + (height / 2 - d.y) * (damper + 0.02) * 0.1
      return 'translate(' + d.x + ',' + d.y + ')'
    })

    d3.selectAll('line.link').each(function (d) {
      let x1 = d.source.x
      let y1 = d.source.y
      let x2 = d.target.x
      let y2 = d.target.y
      let angle = Math.atan2(y2 - y1, x2 - x1)
      d.sourceX = x1 + Math.cos(angle) * (NODE_RADIUS + MARKER_SIZE / 2)
      d.sourceY = y1 + Math.sin(angle) * (NODE_RADIUS + MARKER_SIZE / 2)
      d.targetX = x2 - Math.cos(angle) * (NODE_RADIUS + MARKER_SIZE / 2)
      d.targetY = y2 - Math.sin(angle) * (NODE_RADIUS + MARKER_SIZE / 2)
    })

    d3.selectAll('line.link')
      .attr('x1', d => d.sourceX)
      .attr('y1', d => d.sourceY)
      .attr('x2', d => d.targetX)
      .attr('y2', d => d.targetY)
  }
}
