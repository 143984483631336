import { titleCase } from '../../utils/stringUtils'
import * as d3 from 'd3'
import { onMouseOver, onMouseMove, onMouseOut } from './hover'
import { dragStarted, dragging, dragEnded } from './drag'
import { NODE_RADIUS } from './draw_graph'
import updateOnClick from './click'

const TEXT_PADDING = 2

export function renderNodes(graph, nodeColors, theme, simulation) {
  // This line takes the array of nodeColors specified by D3Graph and
  // cleans it into an object readily usable by the nodes here
  // The object is 'flat' as in there are no nested arrays or objects
  const flatColors = nodeColors.reduce(
    (acc, d) => ({ ...acc, ...d.code.reduce((a, x) => ({ ...a, [x]: d.color }), {}) }),
    {}
  )
  const filteredNodes = graph.nodes.filter(n => !n.shouldHide)

  // create single `g` element to hold each node
  const nodes = d3.select('g.nodes').selectAll('g').data(filteredNodes)

  nodes.exit().remove()

  const nodesEnter = nodes.enter().append('g').attr('class', 'node')

  // append circle svgs
  nodesEnter
    .append('circle')
    .attr('r', NODE_RADIUS)
    .style('fill', d => flatColors[d.type])
    .style('stroke', d => d3.rgb(flatColors[d.type]).darker())

  nodesEnter
    .append('text')
    .text(d => titleCase(d.name?.trim()))
    .attr('dx', 10 + TEXT_PADDING)
    .attr('dy', NODE_RADIUS)

  /* Update bounding boxes after the text is rendered 
      `this` refers to the DOM element associated with each node
      and will change if refactored into an arrow function -- leave as is!
    */
  nodesEnter.selectAll('text').each(function (d) {
    d.bbox = this.getBBox()
  })

  // insert node label background
  // need insert because this needs to be added before the text:
  //   https://cambridge-intelligence.com/customize-graph-visualization-d3-keylines/
  nodesEnter
    .insert('rect', 'text')
    .attr('x', d => d.bbox.x - TEXT_PADDING)
    .attr('y', d => d.bbox.y - TEXT_PADDING)
    .attr('rx', '2px')
    .attr('ry', '2px')
    .attr('width', d => d.bbox.width + TEXT_PADDING * 2)
    .attr('height', d => d.bbox.height + TEXT_PADDING * 2)
    .style('fill', d => flatColors[d.type])

  // d3 will remove already existing event handlers before
  // appending new ones, so no chance of memory leak
  // focus on node + neighbors on hover
  // notably these receive the full, unfiltered graph
  nodesEnter
    .on('mouseover', (event, d) => onMouseOver(event, d, graph, theme))
    .on('mousemove', (event, d) => onMouseMove(event, d, graph, theme))
    .on('mouseout', (_, d) => onMouseOut(d, theme))
    .on('click', (event, d) => updateOnClick(event, d, graph, nodeColors, theme, simulation))

  nodesEnter.call(
    d3
      .drag()
      .on('start', (event, d) => dragStarted(event, d, simulation, theme))
      .on('drag', dragging)
      .on('end', (event, d) => dragEnded(event, d, simulation))
  )

  return nodesEnter
}
