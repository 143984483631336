import { useMemo, memo, useState, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import useStyles from '../../../../theme/ListStyles'
import { Row, Col } from '../../../Grid'
import { isNull } from '../../../Data/DataUtils'
import useMediaQuery from '@mui/material/useMediaQuery'
import { stableSort } from '../../../../utils/dataUtils'
import Typography from '@mui/material/Typography'
import { v4 } from 'uuid'
import Filter from './Filter'
import { Grid } from '@mui/material'

const StyledTableHeaderCell = withStyles(() => ({
  body: { fontSize: 16, padding: 8 },
}))(TableCell)

/**
 * @typedef {object} TableHeaderProps
 * @prop {any[]} rows
 * @prop {import('@c/DataTable/DataTable').Column[]} columns
 * @prop {import('../AdornedTable').Order} order
 * @prop {string} orderBy
 * @prop {(e: import('react').MouseEvent<HTMLSpanElement>, property: string) => void} onRequestSort
 * @prop {SetState<{}>} setFilterConditions
 * @prop {any} [headerStyles={}]
 * @prop {any} classes
 * @prop {any} numSelected
 * @prop {any} onSelectAllClick
 *
 * @param {TableHeaderProps} props
 */
function TableHeader({ rows, columns, classes, order, orderBy, onRequestSort, setFilterConditions, headerStyles}) {

  /* Wait for data to become available */
  if (isNull(columns)) { return null }
  
  /** 
   * @param {string} property
   * @returns {MouseEventHandler}
   */
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {/* using index since UUID generation is sensitive to rerenders */
          columns.map((headCell, index) => (<StyledTableHeaderCell
            style={headerStyles}
            className={classes.headerCell}
            variant='head'
            key={index}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}>
            <Grid
              container
              flexDirection="row"
              flexWrap='unset'
              justifyContent='space-between'
              alignItems='center'>
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={createSortHandler(headCell.field)}>
                {headCell.headerName}
              </TableSortLabel>
              <Filter rows={rows} column={headCell} columnIndex={index} setFilterConditions={setFilterConditions} />
            </Grid>
          </StyledTableHeaderCell>
          ))}
      </TableRow>
    </TableHead>
  )
}

/**
 * @typedef {object} BaseTablePublicProps
 * @prop {any[]} rows
 * @prop {import('@c/DataTable/DataTable').Column[]} columns
 * @prop {CSSProperties} [cellStyles]
 * @prop {number} [paginationPageSize]
 * @prop {boolean} [hideUnpopulatedColumns=false]
 * @prop {string} [nofilteredDataMessage='']
 * @prop {string} [searchFilter='']
 * @prop {import('../AdornedTable').SortModel[]} [sortModel]
 * @prop {(page: number) => void} [onPageChange]
 * @prop {(number | {value: number; label: string;})[]} [rowsPerPageOptions]
 * @prop {(opts: import('@mui/material').LabelDisplayedRowsArgs) => ReactNode} [labelDisplayedRows]
 * 
 * @typedef {object} BaseTablePrivateProps
 * @prop {() => ReactNode} aboutHeader
 * @prop {() => ReactNode} aboutContent
 *
 * @param {BaseTablePrivateProps & BaseTablePublicProps} props
 */
function BaseTable({
  rows,
  columns:originalColumns,
  aboutHeader:AboutHeader,
  aboutContent:AboutContent,
  cellStyles,
  paginationPageSize = 10,
  hideUnpopulatedColumns = false,
  nofilteredDataMessage = '',
  searchFilter = '',
  sortModel, 
  onPageChange,
  rowsPerPageOptions,
  labelDisplayedRows,
}) {

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  const [order, setOrder] = useState(sortModel?.[0]?.sort || 'asc')
  const [orderBy, setOrderBy] = useState(sortModel?.[0]?.field || '')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(paginationPageSize)

  /* filter conditions are in an object: {[columnIndex]: (rows) => newRows functions}
   * object instead of array for easier update of individual properties,
   */
  const [filterConditions, setFilterConditions] = useState({})
  const breakpointMd = useMediaQuery(theme.breakpoints.up('md'))
  const rowsPerPageLabel = breakpointMd ? 'Rows per page:' : 'Rows:'

  useEffect(() => { setPage(0) }, [rows, rows.length])

  /* If hideUnpopulatedColumns is true then hide columns with no data */
  const columns = hideUnpopulatedColumns
    ? originalColumns.filter(column => {
      const numRowsWithColumnPopulated = rows.filter(row => {
        return !isNull(row[column.field])
      }).length
      return numRowsWithColumnPopulated
    })
    : originalColumns

  /* Map field names to sortSelector functions for efficient lookups */
  const sortSelectors = new Map()
  columns.forEach(x => {
    if (typeof x.sortSelector === 'function') {
      sortSelectors.set(x.field, x.sortSelector)
    }
  })

  /** Base sort function for table rows 
   * @param a
   * @param b
   * @param {string} orderBy
  */
  function descendingComparator(a, b, orderBy) {
    const field = orderBy
    let aValue, bValue
    if (sortSelectors.has(field)) {
      aValue = (sortSelectors.get(field))?.(a)
      bValue = (sortSelectors.get(field))?.(b)
    }
    else {
      aValue = a[field]
      bValue = b[field]
    }
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }

  /** Returns the appropriate sorting function based on the sort order 
   * @param {import('../AdornedTable').Order} order
   * @param {string} orderBy
  */
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  /** @type {(event: React.MouseEvent<HTMLButtonElement>, page: number) => void} */
  const handleChangePage = (_, newPage) => {
    onPageChange && onPageChange(newPage)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = 0

  const MemoizedRow = ({ columns, row, cellStyles }) => {

    /* Memoize columns so that re-renders of the parent don't reset column state */
    const MemoizedColumns = useMemo(
      () => () =>
        columns?.map(column => {
          let displayValue = ''
          if (typeof column.valueFormatter === 'function') {
            displayValue = column.valueFormatter({ value: row[column.field] })
          } else if (typeof column.renderCell === 'function') {
            displayValue = column.renderCell({ row })
          } else {
            displayValue = row[column.field]
          }
          if (displayValue === null ||
            displayValue === undefined ||
            displayValue === ''
          ) displayValue = 'Unknown'
          return (
            displayValue === 'Unknown'
              ? <TableCell
                style={cellStyles}
                className={classes.tableCellWithUnknownValue}
                variant='body'
                component='th'
                scope='row'
                padding='none'
                color='primary'
                key={v4()}>
                {displayValue}
              </TableCell>
              : <TableCell
                style={cellStyles}
                className={classes.tableCell}
                variant='body'
                component='th'
                scope='row'
                padding='none'
                color='primary'
                key={v4()}>
                {displayValue}
              </TableCell>
          )
        }),
      [columns, row, cellStyles]
    )

    return (
      <TableRow hover role='checkbox' tabIndex={-1} key={row?.name}>
        <MemoizedColumns />
      </TableRow>
    )
  }

  // unpack filters from an object into a simple array
  const filters = Object.values(filterConditions).filter(condition => condition)
  const filteredRows = filters.reduce((prevRows, filter) => prevRows.filter(filter), rows)
  const paginatedRows = stableSort(filteredRows, getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  
  return (
    <div>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-labelledby='tableTitle' aria-label='enhanced table' style={{background: 'white'}}>
            <TableHeader
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rows={rows}
              columns={columns}
              setFilterConditions={setFilterConditions}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick} />
            <TableBody>
              {paginatedRows.map((row, index) => (
                <MemoizedRow key={index} 
                  row={row} columns={columns} 
                  cellStyles={cellStyles}/>
              ))}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {
            (searchFilter?.length > 0 && rows?.length === 0) &&
              <Row justifyContent='center' style={{ paddingTop: 10, paddingBottom: 10}}>
                <Typography variant='body1'>{nofilteredDataMessage}</Typography>
              </Row>
          }
        </TableContainer>
      </Paper>
      <Row justifyContent='space-between' wrap='nowrap'>
        <Col><AboutHeader /></Col>
        <Col><TablePagination
          className={classes.tablePagination}
          classes={{ menuItem: classes.paginationOption }}
          rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25, 50, 100]}
          labelRowsPerPage={rowsPerPageLabel}
          labelDisplayedRows={
            labelDisplayedRows || 
              (({ from, to, count }) => `${from}-${to} of ${count} total rows`)
          }
          component='div'
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={(page > 0 && filteredRows.length < rowsPerPage) ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          getItemAriaLabel={() => ('')}
        /></Col>
      </Row>
      <Row justifyContent='space-between' wrap='nowrap'>
        <AboutContent />
      </Row>
    </div>
  )
}

export default memo(BaseTable)
