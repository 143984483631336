import { titleCase } from './stringUtils'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { Row } from '../components/Grid'

/*
 * renderOwnerLink: Attempts to create a title-cased link to an owner page
 *
 * If the business type is either non-existent or an invalid business type,
 * returns the title-cased input name
 */
export const renderOwnerLink = (owner_name, be_type, variant) => {
  if (!be_type) {
    return titleCase(owner_name)
  }

  // if be_type indicates not suitable for owner page, return no link
  if (be_type !== 'Business' && be_type !== 'LP' && be_type !== 'Corp'){
    return titleCase(owner_name)
  }

  return (
    <Link
      component={RouterLink}
      to={`/owner/${encodeURIComponent(owner_name.toUpperCase())}`}
      variant={variant}>
      <span
        style={{
          textDecoration: 'always',
          alignItems: 'center',
        }}>
        {titleCase(owner_name)}
      </span>
    </Link>
  )
}

/*
 * renderNetworkLink: Attempts to create a link to a network page
 *
 * If the network ID is non-existent, returns the title-cased input text
 */
export const renderNetworkLink = (network_id, text) => {
  if (!network_id || network_id === '') {
    return text
  }

  return (
    <Link
      component={RouterLink}
      to={`/graph/${encodeURIComponent(network_id)}`}>
      <span
        style={{
          textDecoration: 'always',
          alignItems: 'center',
        }}>
        {text}
      </span>
    </Link>
  )
}

/*
 * renderParcelLink: Attempts to create a link to a parcel page (consisting of
 * title-cased address)
 *
 * If the parcel_id or address is non-existent, returns the title-cased input address
 */
export const renderParcelLink = (parcel_id, address, className) => {
  if (!parcel_id || !address) {
    return titleCase(address)
  }

  return (
    <Link
      component={RouterLink}
      className={className}
      to={`/parcel/${encodeURIComponent(parcel_id)}` }>
      <Row alignItems='center' className={className}>
        {titleCase(address)}
      </Row>
    </Link>
  )
}

/* As above, but for neighborhoods
 * Honestly a bit overkill to do it this way, but why not
 * */
export const renderNeighborhoodLink = (neighborhood) => {
  if (!neighborhood){
    return titleCase(neighborhood)
  }

  return (
    <Link component={RouterLink}
      to={`/neighborhood/${encodeURIComponent(neighborhood)}` }>
      <span
        style={{
          textDecoration: 'always',
          alignItems: 'center',
        }}>
        {titleCase(neighborhood)}
      </span>
    </Link>
  )
}


export const generateGraphIdUrl = id => `/graph/${encodeURIComponent(id)}`
