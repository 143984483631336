import { memo } from 'react'
import { useAppSelector } from 'src/state/hooks'
import { Typography } from '@mui/material'
import { isEmpty, isNull, selectRecords } from './Data/DataUtils'
import DataTable from './DataTable/DataTable'
import { parseDate, reformatDate, titleCase } from '../utils/stringUtils'
import { renderParcelLink, renderOwnerLink } from '../utils/tableUtils'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { useTheme } from '@mui/material/styles'
import useStyles from '../theme/ListStyles'
import { config } from '../config/config'
import InterpolateTranslation from './InterpolateTranslation'

/** EvictionList: render a table with a list of evictions
 * @param {import('./DataTable/DataTable').ListProps & {showAddress?: boolean}} props
 */
const EvictionList = ({
  title,
  subTitle,
  noDataMessage,
  slice,
  aboutData,
  onSearchFilter,
  searchHint,
  csvFileName,
  showAddress = true,
}) => {
  /* Set up API data fields and selectors */
  const select = config.api.response.evictionList.selectors
  const field = config.api.response.evictionList.fields

  /* Set hooks to use Redux global state */
  const evictions = useAppSelector(selectRecords(slice))

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'EvictionList' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  if (isEmpty(evictions)) {
    return (
      <>
        <Typography variant='h2'>
          {typeof title === 'string' && title.length > 0 && title}
        </Typography>
        <Typography variant='body1'>{noDataMessage}</Typography>
      </>
    )
  }

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) {
    return null
  }

  /**
   * @param {object} props
   * @param {string} props.primaryEvictorName
   * @param {string} props.primaryEvictorType
   * @param {string[]} props.secondaryEvictors
   */
  const AttributedEvictor = ({
    primaryEvictorName,
    primaryEvictorType,
    secondaryEvictors = [],
  }) => {
    return (
      <Accordion className={classes.accordionInTable} elevation={0}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'>
          <div>
            {renderOwnerLink(primaryEvictorName, primaryEvictorType)}
            {!isNull(primaryEvictorName) && secondaryEvictors.length > 0 && (
              <div>
                <InterpolateTranslation
                  components={[({ children }) => <strong>{children}</strong>]}>
                  {t('(and <1>{{num_secondary_evictors}}</1> more)', {
                    num_secondary_evictors: secondaryEvictors.length,
                  })}
                </InterpolateTranslation>
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div>
            {secondaryEvictors.map(evictor => (
              <div key={evictor} className={classes.nonOverflowingTableCell}>
                {titleCase(evictor)}
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <>
      <Typography variant='h2'>{typeof title === 'string' && title.length > 0 && title}</Typography>
      <Typography variant='body1'>{subTitle !== null && subTitle}</Typography>
      <DataTable
        sortModel={[{ field: field.evictionDate, sort: 'desc' }]}
        sortingOrder={['desc', 'asc', null]}
        name='evictions'
        noDataMessage={noDataMessage}
        aboutData={aboutData}
        rows={evictions}
        onSearchFilter={onSearchFilter}
        searchHint={searchHint}
        columns={[
          /** @type {import("./DataTable/DataTable.js").Column} */ ({
            field: field.evictionDate,
            headerName: 'Date',
            type: 'date',
            flex: 1.5,
            valueFormatter: p => reformatDate(p.value, true),
            searchSelector: row => select.evictionDate(row),
            sortSelector: row => parseDate(select.evictionDateRaw(row)),
          }),
        ]
          .concat(
            showAddress
              ? [
                {
                  field: field.evictionAddress,
                  headerName: 'Address',
                  type: 'string',
                  flex: 2,
                  renderCell: p => {
                    return renderParcelLink(select.parcelId(p.row), select.evictionAddress(p.row))
                  },
                  searchSelector: row => select.evictionAddress(row),
                  sortSelector: row => select.evictionAddress(row),
                },
              ]
              : []
          )
          .concat([
            {
              field: field.evictorName,
              headerName: 'Attributed Evictor(s)',
              type: 'string',
              flex: 3,
              renderCell: p => {
                const primaryEvictorName = select.primaryEvictorName(p.row)
                const primaryEvictorType = select.primaryEvictorBusinessType(p.row)
                const secondaryEvictors = select
                  .secondaryEvictors(p.row)
                  .map(e => renderOwnerLink(e.evictorName, e.evictorType))

                if (isNull(primaryEvictorName)) return 'Unknown'

                return isEmpty(secondaryEvictors) ? (
                  renderOwnerLink(primaryEvictorName, primaryEvictorType)
                ) : (
                  <AttributedEvictor
                    primaryEvictorName={primaryEvictorName}
                    primaryEvictorType={primaryEvictorType}
                    secondaryEvictors={secondaryEvictors}
                  />
                )
              },
              searchSelector: row => select.primaryEvictorName(row),
              sortSelector: row => select.primaryEvictorName(row),
            },
            {
              field: field.evictionType,
              headerName: 'Eviction Type',
              type: 'string',
              flex: 1.5,
              valueFormatter: p => p.value ?? 'Unknown',
              searchSelector: row => select.evictionType(row),
              sortSelector: row => select.evictionType(row),
            },
          ])}
        csvFileName={csvFileName}
      />
    </>
  )
}

export default memo(EvictionList)
