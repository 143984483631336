/**
 * cf. https://data.sfgov.org/resource/pa56-ek2h.json
 * We should get these into the DB, but since that won't happen soon...
 * @typedef {object} PropertyClass 
 * @prop {string} [use_code]
 * @prop {string} use_definition
 * @prop {string} class_code
 * @prop {string} [class_definition]
 */

/** @type {PropertyClass[]} */
const propertyClassesSF = ([
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'A',
    class_definition: 'Apartment',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'A15',
    class_definition: 'Apartment 15 Units or more ',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'A5',
    class_definition: 'Apartment 5 to 14 Units',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'AC',
    class_definition: 'Apartmnt & Commercial Store',
  },
  {
    use_code: 'COMR',
    use_definition: 'Commercial Retail',
    class_code: 'B',
    class_definition: 'Bank',
  },
  {
    use_code: 'COMR',
    use_definition: 'Commercial Retail',
    class_code: 'BZ',
    class_definition: 'Bank Condominium',
  },
  {
    use_code: 'COMR',
    use_definition: 'Commercial Retail',
    class_code: 'C',
    class_definition: 'Commercial Stores',
  },
  {
    use_code: 'COMR',
    use_definition: 'Commercial Retail',
    class_code: 'C1',
    class_definition: 'Shopping Center',
  },
  {
    use_code: 'COMR',
    use_definition: 'Commercial Retail',
    class_code: 'CD',
    class_definition: 'Commercial Department Stores',
  },
  {
    use_code: 'COMR',
    use_definition: 'Commercial Retail',
    class_code: 'CM',
    class_definition: 'Commercial/Mixed use',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'CO',
    class_definition: 'Coop Units Unsegregated',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'COS',
    class_definition: 'Coop Units Segregated',
  },
  {
    use_code: 'GOVT',
    use_definition: 'Government',
    class_code: 'CP',
    class_definition: 'City Property',
  },
  {
    use_code: 'COMR',
    use_definition: 'Commercial Retail',
    class_code: 'CZ',
    class_definition: 'Commercial Store Condo',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'D',
    class_definition: 'Dwelling',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'DA',
    class_definition: 'Dwellings - Apartments',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'DA15',
    class_definition: 'Dwellings - Apt 15 units or more ',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'DA5',
    class_definition: 'Dwellings - Apt 5 to 14 units ',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'DBM',
    class_definition: 'Dwelling BMR',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'DCON',
    class_definition: 'Legal Multi-Family Con to SFR ',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'DD',
    class_definition: '2 Dwellings on One Parcel',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'DD5',
    class_definition: '2 Dwellings on 1 Parcel 5 to 14 units ',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'DF',
    class_definition: '1 Flat & Dwelling-1 Parcel',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'E',
    class_definition: 'Schools',
  },
  {
    use_code: 'COMR',
    use_definition: 'Commercial Retail',
    class_code: 'EC',
    class_definition: 'Entertainment Complex',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'F',
    class_definition: 'Flats & Duplex',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'F15',
    class_definition: 'Flats 15 units +',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'F2',
    class_definition: 'Flat & Store',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'F5',
    class_definition: 'Flats 5 to 14 units ',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'FA',
    class_definition: '1 Flat & 1 Apt Bldg-1 Parcel',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'FA5',
    class_definition: '1 Flat & 1 Apt - 1 Parcel 5 to 14 units ',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'FS',
    class_definition: 'Flat & Store 4 units or less',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'FS15',
    class_definition: 'Flat & Store 15 units +',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'FS5',
    class_definition: 'Flat & Store 5 to 14 units',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'G',
    class_definition: 'Garages (Commercial)',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'GC',
    class_definition: 'Golf Course',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'GCU         ',
    class_definition: 'Golf Course                             ',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'GZ',
    class_definition: 'Garage Condominium',
  },
  {
    use_code: 'COMH',
    use_definition: 'Commercial Hotel',
    class_code: 'H',
    class_definition: 'Hotel',
  },
  {
    use_code: 'COMH',
    use_definition: 'Commercial Hotel',
    class_code: 'H1',
    class_definition: 'Hotel',
  },
  {
    use_code: 'COMH',
    use_definition: 'Commercial Hotel',
    class_code: 'H2',
    class_definition: 'Hotels - Other',
  },
  {
    use_code: 'COMH',
    use_definition: 'Commercial Hotel',
    class_code: 'HC',
    class_definition: 'Hotel Commercial (H2w/Com)',
  },
  {
    use_code: 'IND',
    use_definition: 'Industrial',
    class_code: 'I',
    class_definition: 'Industrial',
  },
  {
    use_code: 'IND',
    use_definition: 'Industrial',
    class_code: 'IDC',
    class_definition: 'Industrial Data Center',
  },
  {
    use_code: 'IND',
    use_definition: 'Industrial',
    class_code: 'IW',
    class_definition: 'Industial Warehouse',
  },
  {
    use_code: 'IND',
    use_definition: 'Industrial',
    class_code: 'IX',
    class_definition: 'Industrial Mixed/Other Use',
  },
  {
    use_code: 'IND',
    use_definition: 'Industrial',
    class_code: 'IZ',
    class_definition: 'Industrial Condominium',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'LZ',
    class_definition: 'Live/Work Condominium',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'LZBM',
    class_definition: 'Live/Work Condominium BMR',
  },
  {
    use_code: 'COMH',
    use_definition: 'Commercial Hotel',
    class_code: 'M',
    class_definition: 'Motels',
  },
  {
    use_code: 'MISC',
    use_definition: 'Miscellaneous/Mixed-Use',
    class_code: 'MB',
    class_definition: 'Mission Bay',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'MIX',
    class_definition: 'Mixed use',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'N1',
    class_definition: 'Hospitals',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'N2',
    class_definition: 'Convalescent/Nursing Homes',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'O',
    class_definition: 'Office',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'O35',
    class_definition: 'Office Portion Leased of 35 or More ',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'OA',
    class_definition: 'Office and Apartments',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'OA15',
    class_definition: 'Office and Apartments 15 units +',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'OA5',
    class_definition: 'Office and Apartments 5 to 14 units',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'OAH',
    class_definition: 'Office - High Class A',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'OAL         ',
    class_definition: 'Office - Low Class A                     ',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'OAT',
    class_definition: 'Office - "Trophy" Class A',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'OBH',
    class_definition: 'Office - High Class B',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'OBM',
    class_definition: 'Office - Middle Class B',
  },
  {
    use_code: 'COMR',
    use_definition: 'Commercial Retail',
    class_code: 'OC',
    class_definition: 'Office with Major Retail Units',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'OCH',
    class_definition: 'Office - High Class C',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'OCL',
    class_definition: 'Office - Low Class C',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'OCM',
    class_definition: 'Office - Middle Class C',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'OMD',
    class_definition: 'Medical- dental Office Building',
  },
  {
    use_code: 'COMO',
    use_definition: 'Commercial Office',
    class_code: 'OZ',
    class_definition: 'Office - Condominium',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'OZEU',
    class_definition: 'Office Condo Economic Unit ',
  },
  {
    use_code: 'GOVT',
    use_definition: 'Government',
    class_code: 'P',
    class_definition: 'Public Buildings (Govt)',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'PD',
    class_definition: 'PUD (Planned Unit Development)',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'PL',
    class_definition: 'Parking Lot',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'PZ',
    class_definition: 'Parking Stall Condominium',
  },
  {
    use_code: 'GOVT',
    use_definition: 'Government',
    class_code: 'RDAP',
    class_definition: 'Redevelopment Agency Property',
  },
  {
    use_code: 'COMH',
    use_definition: 'Commercial Hotel',
    class_code: 'RH',
    class_definition: 'Residential Hotel & SRO',
  },
  {
    use_code: 'COMH',
    use_definition: 'Commercial Hotel',
    class_code: 'RH1',
    class_definition: 'Retail & Hotel',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'S',
    class_definition: 'Gas Station',
  },
  {
    use_code: 'GOVT',
    use_definition: 'Government',
    class_code: 'SP',
    class_definition: 'State of California Property',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'T',
    class_definition: 'Theatres',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'TH',
    class_definition: 'Town House',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'THBM',
    class_definition: 'Town House BMR',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'TI15',
    class_definition: 'TIC Bldg 15 units + ',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'TIA',
    class_definition: 'TI Apartment',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'TIC',
    class_definition: 'TIC Bldg 4 units or less ',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'TIC5        ',
    class_definition: 'TIC Bldg 5 to 14 units                  ',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'TIF',
    class_definition: 'TI Flats & Duplex',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'TS',
    class_definition: 'Timeshare',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'TSF',
    class_definition: 'Time Share Fractional',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'TSU',
    class_definition: 'Time Share Unsegregated',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'U',
    class_definition: 'Clubs,Lodges,Fraternal Organizations',
  },
  {
    use_code: 'GOVT',
    use_definition: 'Government',
    class_code: 'UCP',
    class_definition: 'University of California Property',
  },
  {
    use_code: 'GOVT',
    use_definition: 'Government',
    class_code: 'USP',
    class_definition: 'U.S. Government Property',
  },
  {
    use_code: 'MISC',
    use_definition: 'Miscellaneous/Mixed-Use',
    class_code: 'UWL',
    class_definition: 'Under Water Lot',
  },
  {
    use_code: 'MISC',
    use_definition: 'Miscellaneous/Mixed-Use',
    class_code: 'V',
    class_definition: 'Vacant Lot',
  },
  {
    use_code: 'MISC',
    use_definition: 'Miscellaneous/Mixed-Use',
    class_code: 'VA15',
    class_definition: 'Vacant land- residential 15+ units ',
  },
  {
    use_code: 'MISC',
    use_definition: 'Miscellaneous/Mixed-Use',
    class_code: 'VCI',
    class_definition: 'Vacant Lot Comm and Ind ',
  },
  {
    use_code: 'MISC',
    use_definition: 'Miscellaneous/Mixed-Use',
    class_code: 'VCIX        ',
    class_definition: 'Vacant Lot Comm and Ind w/ Restriction   ',
  },
  {
    use_code: 'MISC',
    use_definition: 'Miscellaneous/Mixed-Use',
    class_code: 'VPUB',
    class_definition: 'Vacant Lot Public Use',
  },
  {
    use_code: 'MISC',
    use_definition: 'Miscellaneous/Mixed-Use',
    class_code: 'VR',
    class_definition: 'Vacant Lot - Restrictions',
  },
  {
    use_code: 'MISC',
    use_definition: 'Miscellaneous/Mixed-Use',
    class_code: 'VRX',
    class_definition: 'Vacant Lot Residential w/ Restriction ',
  },
  {
    use_code: 'COMM',
    use_definition: 'Commercial Misc',
    class_code: 'W',
    class_definition: 'Churches,Convents,Rectories',
  },
  {
    use_code: 'MISC',
    use_definition: 'Miscellaneous/Mixed-Use',
    class_code: 'X',
    class_definition: 'Misc',
  },
  {
    use_code: 'MRES',
    use_definition: 'Multi-Family Residential',
    class_code: 'XV',
    class_definition: "Single Struct on Multi Lot(D & F's only)",
  },
  {
    use_code: 'GOVT',
    use_definition: 'Government',
    class_code: 'Y',
    class_definition: 'Port Commission Property',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'Z',
    class_definition: 'Condominium',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'ZBM',
    class_definition: 'Condominium BMR',
  },
  {
    use_code: 'SRES',
    use_definition: 'Single Family Residential',
    class_code: 'ZEU',
    class_definition: 'Condominium Economic Unit ',
  },
])

// 
/** 
 * https://www.acgov.org/MS/prop/useCodeList.aspx
 * @type {PropertyClass[]}
 *  */
const propertyClassesAlameda = ([
  {
    class_code: '0300',
    use_definition: 'Exempt Public Agency',
  },
  {
    class_code: '0400',
    use_definition: 'Property leased to a public utility',
  },
  {
    class_code: '0500',
    use_definition: 'Property owned by a public utility',
  },
  {
    class_code: '0600',
    use_definition: 'Mobile home (on single family residential or rural land)',
  },
  {
    class_code: '0700',
    use_definition: 'Mobile home (in a mobile home park)',
  },
  {
    class_code: '0750',
    use_definition: 'Floating home',
  },
  {
    class_code: '0800',
    use_definition: 'Vacant residential tract lot',
  },
  {
    class_code: '0840',
    use_definition: 'Tract land',
  },
  {
    class_code: '0900',
    use_definition: 'Partially complete residential tract home',
  },
  {
    class_code: '0940',
    use_definition: 'Tract residential planned community',
  },
  {
    class_code: '1000',
    use_definition: 'Vacant residential land (zoned 4 units or less)',
  },
  {
    class_code: '1040',
    use_definition: 'Vacant residential land',
  },
  {
    class_code: '1100',
    use_definition: 'Single family residential home',
  },
  {
    class_code: '1101',
    use_definition:
      'Medical-Residential Care Facility (in Single Family Residential with Residential Improvement)',
  },
  {
    class_code: '1120',
    use_definition: 'Residential Improvement on Commercial Land',
  },
  {
    class_code: '1130',
    use_definition: 'Residential Improvement on Industrial Land',
  },
  {
    class_code: '1140',
    use_definition: 'Single family residential home',
  },
  {
    class_code: '1150',
    use_definition: 'Historical residential',
  },
  {
    class_code: '1160',
    use_definition: 'Land Trust (residential improvement on leased land)',
  },
  {
    class_code: '1166',
    use_definition: 'Land Trust (common area)',
  },
  {
    class_code: '1190',
    use_definition: 'Single family residential (tract common area or use)',
  },
  {
    class_code: '1200',
    use_definition: 'Single family residential home with 2nd unit',
  },
  {
    class_code: '1201',
    use_definition: 'Single family residential with junior accessory dwelling unit',
  },
  {
    class_code: '1300',
    use_definition: 'Single family residential home with slight commercial/industrial use',
  },
  {
    class_code: '1400',
    use_definition: 'Single family residential - attached duplex ("Duet Style")',
  },
  {
    class_code: '1420',
    use_definition: 'Single family residential - attached duplex ("Duet Style")',
  },
  {
    class_code: '1430',
    use_definition: 'Single family residential - attached duplex ("Duet Style")',
  },
  {
    class_code: '1440',
    use_definition: 'Single family residential - attached duplex ("Duet Style")',
  },
  {
    class_code: '1500',
    use_definition: 'Townhouse (in Planned Development)',
  },
  {
    class_code: '1520',
    use_definition: 'Townhouse (in Planned Development)',
  },
  {
    class_code: '1530',
    use_definition: 'Townhouse (in Planned Development)',
  },
  {
    class_code: '1540',
    use_definition: 'Townhouse (in Planned Development)',
  },
  {
    class_code: '1590',
    use_definition: 'Townhouse (in Planned Development, Common Area or use)',
  },
  {
    class_code: '1600',
    use_definition: 'Single family residential (Detached Site Condominium)',
  },
  {
    class_code: '1620',
    use_definition: 'Single family residential (Detached Site Condominium)',
  },
  {
    class_code: '1630',
    use_definition: 'Single family residential (Detached Site Condominium)',
  },
  {
    class_code: '1640',
    use_definition: 'Single family residential (Detached Site Condominium)',
  },
  {
    class_code: '1690',
    use_definition: 'Single family residential (Detached Site Condominium , Common Area or use)',
  },
  {
    class_code: '1700',
    use_definition: 'Single family residential home converted to boarding house',
  },
  {
    class_code: '1800',
    use_definition: 'Single family residential (Planned Development Tract with Common Area)',
  },
  {
    class_code: '1820',
    use_definition: 'Single family residential (Planned Development Tract)',
  },
  {
    class_code: '1830',
    use_definition: 'Single family residential (Planned Development Tract)',
  },
  {
    class_code: '1840',
    use_definition: 'Single family residential (Planned Development Tract)',
  },
  {
    class_code: '1850',
    use_definition: 'Duet/Duplex/Triplex (Planned Development Tract w/Common Area)',
  },
  {
    class_code: '1860',
    use_definition: 'Duet/Duplex/Triplex (Planned Development Tract)',
  },
  {
    class_code: '1890',
    use_definition: 'Single family residential (Planned Development Tract, Common Area or use)',
  },
  {
    class_code: '1900',
    use_definition: 'Single family residential (Manufactured Home on permanent foundation)',
  },
  {
    class_code: '1901',
    use_definition: 'Single family modular built off site',
  },
  {
    class_code: '1950',
    use_definition: 'Non-Condo Live/Work',
  },
  {
    class_code: '2100',
    use_definition: 'Two, three or four single family homes',
  },
  {
    class_code: '2200',
    use_definition: 'Double or duplex type (two units)',
  },
  {
    class_code: '2300',
    use_definition: 'Triplex; (double or duplex with single family home)',
  },
  {
    class_code: '2400',
    use_definition: 'Four living units (e.g. fourplex or triplex plus single family residential)',
  },
  {
    class_code: '2440',
    use_definition: 'Four residential living units',
  },
  {
    class_code: '2500',
    use_definition: '2 units (lesser quality / unknown legal status)',
  },
  {
    class_code: '2501',
    use_definition: '2 units (single family residential with attached accessory dwelling unit)',
  },
  {
    class_code: '2502',
    use_definition: '2 units (single family residential with detached accessory dwelling unit)',
  },
  {
    class_code: '2541',
    use_definition: '2 units (single family residential with attached accessory dwelling unit)',
  },
  {
    class_code: '2542',
    use_definition: '2 units (single family residential with detached accessory dwelling unit)',
  },
  {
    class_code: '2600',
    use_definition: '3 units (lesser quality or unknown legal status)',
  },
  {
    class_code: '2700',
    use_definition: '4 units (lesser quality or unknown legal status)',
  },
  {
    class_code: '2800',
    use_definition: 'Rooming house residential property of 2,3 or 4 units',
  },
  {
    class_code: '2900',
    use_definition: 'More than 1 mobile home',
  },
  {
    class_code: '3000',
    use_definition: 'Vacant commercial land (may include misc. improvements)',
  },
  {
    class_code: '3100',
    use_definition: 'Single-tenant retail store',
  },
  {
    class_code: '3120',
    use_definition: 'Commercial Improvement on residential land',
  },
  {
    class_code: '3200',
    use_definition: 'Store/Office with apartments or lofts',
  },
  {
    class_code: '3300',
    use_definition: 'Miscellaneous improved commercial property',
  },
  {
    class_code: '3400',
    use_definition: 'Department store',
  },
  {
    class_code: '3500',
    use_definition: 'National Chain Retailer',
  },
  {
    class_code: '3600',
    use_definition: 'small walk-in restaurant or cafe',
  },
  {
    class_code: '3605',
    use_definition: 'free-standing restaurant',
  },
  {
    class_code: '3610',
    use_definition: 'Fast Food restaurant',
  },
  {
    class_code: '3620',
    use_definition: 'Bar',
  },
  {
    class_code: '3700',
    use_definition: 'Shopping Center-NBHD/Grocery or Retail anchor',
  },
  {
    class_code: '3701',
    use_definition: 'Shopping Center-Community',
  },
  {
    class_code: '3702',
    use_definition: 'Shopping Center-Regional Mall',
  },
  {
    class_code: '3703',
    use_definition: 'Shopping Center-NBHD without anchor (strip mall)',
  },
  {
    class_code: '3704',
    use_definition: 'Shopping Center-Power Center',
  },
  {
    class_code: '3705',
    use_definition: 'Shopping Center + Residential + Other',
  },
  {
    class_code: '3800',
    use_definition: 'Supermarket',
  },
  {
    class_code: '3900',
    use_definition: 'Condominium with commercial retail',
  },
  {
    class_code: '3990',
    use_definition: 'Condominium with commercial retail, common areas or uses',
  },
  {
    class_code: '4000',
    use_definition: 'Vacant industrial land (may include misc. improvements)',
  },
  {
    class_code: '4100',
    use_definition: 'Warehouse',
  },
  {
    class_code: '4101',
    use_definition: 'Condominium in industrial area',
  },
  {
    class_code: '4102',
    use_definition: 'Warehouse-Self Storage',
  },
  {
    class_code: '4103',
    use_definition: 'Warehouse-Cold Storage',
  },
  {
    class_code: '4191',
    use_definition: 'Condominium in industrial (with common area or use)',
  },
  {
    class_code: '4200',
    use_definition: 'Industrial Light/Manufacturing',
  },
  {
    class_code: '4201',
    use_definition: 'Industrial Flex/R&D',
  },
  {
    class_code: '4202',
    use_definition: 'Data Center',
  },
  {
    class_code: '4205',
    use_definition: 'Advanced Tech manufacturing with R&D/Large-scale',
  },
  {
    class_code: '4300',
    use_definition: 'Heavy industrial',
  },
  {
    class_code: '4400',
    use_definition: 'Misc. industrial (improved); no other ind code',
  },
  {
    class_code: '4500',
    use_definition: 'Nurseries',
  },
  {
    class_code: '4600',
    use_definition: 'Quarries, Sand and Gravel',
  },
  {
    class_code: '4601',
    use_definition: 'Landfill',
  },
  {
    class_code: '4700',
    use_definition: 'Salt Ponds',
  },
  {
    class_code: '4800',
    use_definition: 'Terminals, trucking and distribution',
  },
  {
    class_code: '4900',
    use_definition: 'Wrecking yards',
  },
  {
    class_code: '5000',
    use_definition: 'Vacant rural orresidential homesites (may incl misc. improvements)',
  },
  {
    class_code: '5100',
    use_definition: 'Improved rural-residential homesite.',
  },
  {
    class_code: '5300',
    use_definition: 'Rural property used for agriculture and/or commercial <10 acre',
  },
  {
    class_code: '5400',
    use_definition: 'Rural property with industrial use',
  },
  {
    class_code: '5500',
    use_definition: 'Rural property used for agriculture and/or commercial 10+ acre',
  },
  {
    class_code: '5600',
    use_definition: 'Rural property in transition to a higher use',
  },
  {
    class_code: '5700',
    use_definition: 'Vacant rural land, not usable even for agriculture',
  },
  {
    class_code: '5800',
    use_definition: 'Improved rural land, non-renewal Williamson Act',
  },
  {
    class_code: '5900',
    use_definition: 'Vacant rural land, non-renewal Williamson Act',
  },
  {
    class_code: '6000',
    use_definition: 'Vacant land necessary part of institutional property',
  },
  {
    class_code: '6001',
    use_definition: 'Government owned property - vacant land',
  },
  {
    class_code: '6100',
    use_definition: 'Government owned property - improved',
  },
  {
    class_code: '6200',
    use_definition: 'Secured PI',
  },
  {
    class_code: '6300',
    use_definition: 'Golf course',
  },
  {
    class_code: '6400',
    use_definition: 'School',
  },
  {
    class_code: '6500',
    use_definition: 'Cemetery',
  },
  {
    class_code: '6590',
    use_definition: 'Cemetery - Exempt',
  },
  {
    class_code: '6600',
    use_definition: 'Church',
  },
  {
    class_code: '6700',
    use_definition: 'Other institutional property',
  },
  {
    class_code: '6800',
    use_definition: 'Lodgehall and/or clubhouse',
  },
  {
    class_code: '6850',
    use_definition: 'Historical commercial',
  },
  {
    class_code: '7000',
    use_definition: 'Vacant land for apartments (capable of 5 or more units)',
  },
  {
    class_code: '7040',
    use_definition: 'Vacant land for apartments',
  },
  {
    class_code: '7090',
    use_definition: 'Vacant apartment (with common area or uses)',
  },
  {
    class_code: '7100',
    use_definition: 'Five or more single family residential homes',
  },
  {
    class_code: '7200',
    use_definition: 'Residential property converted to 5 or more units',
  },
  {
    class_code: '7300',
    use_definition: 'Condominium (single residential living unit)',
  },
  {
    class_code: '7301',
    use_definition: 'Condominium (with a residential live/work unit)',
  },
  {
    class_code: '7302',
    use_definition: 'Condominium (urban residential unit above retail/office)',
  },
  {
    class_code: '7305',
    use_definition: 'Condominium (townhouse style)',
  },
  {
    class_code: '7320',
    use_definition: 'Condominium (single residential unit)',
  },
  {
    class_code: '7321',
    use_definition: 'Condominium (residential live/work unit)',
  },
  {
    class_code: '7322',
    use_definition: 'Condominium (urban residential unit)',
  },
  {
    class_code: '7325',
    use_definition: 'Condominium (townhouse)',
  },
  {
    class_code: '7330',
    use_definition: 'Condominium (single residential unit)',
  },
  {
    class_code: '7335',
    use_definition: 'Condominium (townhouse)',
  },
  {
    class_code: '7340',
    use_definition: 'Condominium (single residential unit)',
  },
  {
    class_code: '7341',
    use_definition: 'Condominium (residential live/work unit)',
  },
  {
    class_code: '7342',
    use_definition: 'Condominium (urban residential above retail/offices)',
  },
  {
    class_code: '7345',
    use_definition: 'Condominium (townhouse)',
  },
  {
    class_code: '7390',
    use_definition: 'Condominium (with common area or use)',
  },
  {
    class_code: '7391',
    use_definition: 'Condominium (residential live/work, with common area or use)',
  },
  {
    class_code: '7392',
    use_definition:
      'Condominium (urban residential unit above retail/offices, with common area or use)',
  },
  {
    class_code: '7395',
    use_definition: 'Condominium - (townhouse, with common area)',
  },
  {
    class_code: '7400',
    use_definition: 'Cooperatives (divided)',
  },
  {
    class_code: '7430',
    use_definition: 'Cooperatives (undivided)',
  },
  {
    class_code: '7500',
    use_definition: 'Restricted residential income property',
  },
  {
    class_code: '7600',
    use_definition: 'Fraternities and sororities',
  },
  {
    class_code: '7700',
    use_definition: 'Multiple residential building of 5 or more units',
  },
  {
    class_code: '7701',
    use_definition: 'Assisted Living Apartments',
  },
  {
    class_code: '7705',
    use_definition: 'Multiple residential building of 5 or more units (plus commercial units)',
  },
  {
    class_code: '7706',
    use_definition: 'Multiple residential building of 5 or more units ( plus commercial)',
  },
  {
    class_code: '7800',
    use_definition: 'Residential high-rise (7 or more stories)',
  },
  {
    class_code: '7900',
    use_definition: 'Church Home',
  },
  {
    class_code: '8000',
    use_definition: 'Car wash',
  },
  {
    class_code: '8100',
    use_definition: 'Commercial repair garage',
  },
  {
    class_code: '8200',
    use_definition: 'Automobile dealership',
  },
  {
    class_code: '8300',
    use_definition: 'Parking lot',
  },
  {
    class_code: '8400',
    use_definition: 'Parking garage',
  },
  {
    class_code: '8500',
    use_definition: 'Service Stations',
  },
  {
    class_code: '8600',
    use_definition: 'Funeral home',
  },
  {
    class_code: '8700',
    use_definition: 'Nursing/Custodial Care Facility',
  },
  {
    class_code: '8800',
    use_definition: 'Hospital (general)',
  },
  {
    class_code: '8801',
    use_definition: 'Medical clinic/outpatient surgery',
  },
  {
    class_code: '8802',
    use_definition: 'Skilled Nursing Facility',
  },
  {
    class_code: '8900',
    use_definition: 'Hotel',
  },
  {
    class_code: '8901',
    use_definition: 'SRO (single residence occupancy) Hotel',
  },
  {
    class_code: '9000',
    use_definition: 'Motel',
  },
  {
    class_code: '9100',
    use_definition: 'Mobile home park parcel with improvements',
  },
  {
    class_code: '9200',
    use_definition: 'Bank',
  },
  {
    class_code: '9300',
    use_definition: 'Medical - Dental building',
  },
  {
    class_code: '9301',
    use_definition: 'Veterinarian Office',
  },
  {
    class_code: '9400',
    use_definition: 'One to five story office building',
  },
  {
    class_code: '9401',
    use_definition: 'Condominium (with office)',
  },
  {
    class_code: '9405',
    use_definition: 'Condominium (with medical office)',
  },
  {
    class_code: '9491',
    use_definition: 'Condominium (with office, common area or uses)',
  },
  {
    class_code: '9500',
    use_definition: 'Over five story office building',
  },
  {
    class_code: '9600',
    use_definition: 'Bowling alley',
  },
  {
    class_code: '9700',
    use_definition: 'Walk-in theater',
  },
  {
    class_code: '9800',
    use_definition: 'Drive-in theater',
  },
  {
    class_code: '9801',
    use_definition: 'Winery',
  },
  {
    class_code: '9802',
    use_definition: 'Winery, including retail/event center',
  },
  {
    class_code: '9900',
    use_definition: 'Other recreational activity, e.g. rinks, stadiums',
  },
  {
    class_code: '9901',
    use_definition: 'Boat berth privately owned',
  },
  {
    class_code: '9902',
    use_definition: 'Subsurface right-oil, gas, mineral',
  },
  {
    class_code: '9905',
    use_definition: 'Fitness Center/Health Club/Gym',
  },
  {
    class_code: '9910',
    use_definition: 'Museums, Historical Societies/Clubs',
  },
  {
    class_code: '9999',
    use_definition: 'P19 - Intergenerational Transfers',
  },
])

const unknownClass = {
  use_code: 'UNKNOWN',
  use_definition: 'Unknown',
  class_code: 'X',
  class_definition: 'Unknown',
}

/** 
 * @param {string} classCode
 * @param {'sf' | 'alameda'} geography
 */
export const getClassByCode = (classCode, geography) => {
  if (geography === 'sf') {
    const results = propertyClassesSF.filter(
      propertyClass => propertyClass.class_code === classCode
    )
    if (results.length >= 1) return results[0]
  }
  if (geography === 'alameda') {
    const results = propertyClassesAlameda.filter(
      propertyClass => propertyClass.class_code === classCode
    )
    if (results.length >= 1) return results[0]
  }
  return unknownClass
}

/**
 * @param {PropertyClass} propertyClass
 * @returns {string}
 */
export const formatPropertyClass = propertyClass => {
  const use_definition = propertyClass.use_definition
    ? propertyClass.use_definition.toLocaleLowerCase()
    : undefined
  
  const class_definition = propertyClass.class_definition 
    ? propertyClass.class_definition.toLocaleLowerCase()
    : undefined

  if (use_definition && use_definition !== 'Unknown') {
    if (class_definition)
      // CASE: both use and class definition
      return `${use_definition} (${class_definition})`
    // CASE: only use definition
    else return `${use_definition}`
  }

  // CASE: no use definition
  return ' building '
}
