import  { memo } from 'react'
import { useAppSelector } from 'src/state/hooks'
import { Typography } from '@mui/material'
import { selectRecord, isNull } from './Data/DataUtils'

/** 
 * @param {object} props
 * @param {string} [props.text]
 * @param {string} [props.slice]
 * @param {(records: any) => string} [props.appendTextSelector]
 * @param {CSSProperties} [props.style]
 */
const PageTitle = props => {

  const slice = props.slice
  const appendTextSelector = props.appendTextSelector
  const text = props.text

  /* Set hooks to use Redux global state */
  const records = useAppSelector(selectRecord(slice))

  if (isNull(records))
    return <Typography variant='h1'>{text}</Typography>

  return (
    <Typography variant='h1' style={props.style ?? {}}>
      {text}
      {appendTextSelector?.(records) ?? ''}
    </Typography>
  )

}

export default memo(PageTitle)