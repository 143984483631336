import { forwardRef } from 'react'
import Grid from '@mui/material/Grid'

/* Helper functions to enhance readability since with Material UI a row is '<Grid container>' and a column is '<Grid item>' */

/**
 * @param {PropsWithRef<typeof Grid>} props
 * @param {Ref<HTMLDivElement>} ref
 */
const RowBase = (props, ref) =>
  <Grid container {...props} ref={ref}>
    {props.children}
  </Grid>
export const Row = forwardRef(RowBase)

/**
 * @param {PropsWithRef<typeof Grid>} props
 * @param {Ref<HTMLDivElement>} ref
 */
export const ColBase = (props, ref) =>
  <Grid item {...props} ref={ref}>
    {props.children}
  </Grid>
export const Col = forwardRef(ColBase)