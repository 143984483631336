import updateGraph from './updateGraph'
// when a node is clicked, hide neighboring nodes
// that have only this node as its neighbor
// Requires three passes over links dataset:
// 1) Creating candidate node list by seeing which are connected to clicked node
// 2) Assessing their validity by seeing if they have additional neighbors besides the clicked node
// 3) Updating the 'shouldHide' property on each link
// Then one pass over the nodes dataset to update properties on
// that dataset
// `nodes` and `links` here should be the Full graph dataset,
// not a subset as
// is passed to the nodes/links rendering functions
export default function updateOnClick(event, d, graph, nodeColors, theme, simulation) {
  if (event.defaultPrevented) return

  const toggledNodeIDs = {}

  // first pass: find nodes linked to `d` and add them to toggledNodeIDs
  graph.links.forEach(({ source, target }) => {
    if (source.id === d.id) {
      toggledNodeIDs[target.id] = target
    } else if (target.id === d.id) {
      toggledNodeIDs[source.id] = source
    }
  })

  // second pass: filter toggledNodeIDs for nodes linked only to `d`
  graph.links.forEach(({ source, target }) => {
    if (toggledNodeIDs[source.id] && target.id !== d.id) {
      delete toggledNodeIDs[source.id]
    }
    
    if (toggledNodeIDs[target.id] && source.id !== d.id) {
      delete toggledNodeIDs[target.id]
    }
  })

  // third pass: update the `shouldHide` property on relevant links
  // and nodes
  graph.links.forEach(link => {
    const { source, target } = link
    if (toggledNodeIDs[source.id] || toggledNodeIDs[target.id]) {
      link.shouldHide = !link.shouldHide
    }
  })

  graph.nodes.forEach(node => {
    if (toggledNodeIDs[node.id]) {
      node.shouldHide = !node.shouldHide
    }
  })

  /* Re-renders the graph with the updated nodes and links */
  updateGraph(graph, nodeColors, theme, simulation)
}
