import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { Row } from './Grid'
import StorageIcon from '@mui/icons-material/Storage'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ReactComponent as BrokenLink } from '../images/broken-link.svg'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import useStyles from '../theme/NotAvailableStyles'
import { useTheme } from '@mui/material/styles'

export const Loading = () => {

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Row justifyContent='center' alignItems='center' style={{ height: 100}}>
      <CircularProgress className={classes.iconStyle} />
    </Row>
  )
}

export const TableLoading = () => {

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'Status', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) return null

  return (
    <Row direction='column' alignItems='center' justifyContent='center'>
      <CircularProgress variant="determinate" className={classes.iconStyle} />
      <Typography variant='body1'>&nbsp;&nbsp;{t('Loading Table')}</Typography>
    </Row>
  )
}

export const NoPage = () => {
  
  /* Set up Material-UI theme */
  const theme = useTheme()

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'Status', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) return null

  return (
    <Row direction='column' alignItems='center' justifyContent='center'>
      <BrokenLink style={{ height: 72, fill: theme.palette.primary.main }}/>
      <Typography variant='body1'>{t('Page not found')}</Typography>
    </Row>
  )
}

export const NoData = () => {
  
  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'Status', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) return null

  return (
    <Row direction='column' alignItems='center' justifyContent='center'>
      <ErrorOutlineIcon className={classes.iconStyle} />
      <Typography variant='body1'>{t('Data not available')}</Typography>
    </Row>
  )
}

/** @param {{noDataMessage: string}} props */
export const TableNoData = ({ noDataMessage }) => {
  
  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Row direction='column' alignItems='center' justifyContent='center'>
      <StorageIcon className={classes.iconStyle} />
      <Typography variant='body1'>&nbsp;&nbsp;{noDataMessage}</Typography>
    </Row>  
  )
}

/** @param {{error: string, isDebugEnabled: boolean}} props */
export const Error = ({ error, isDebugEnabled }) => {
  
  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'Status', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) return null

  return (
    <Row direction='column' alignItems='center' justifyContent='center'>
      <ErrorOutlineIcon className={classes.iconStyle} />
      <Typography variant='body1'>
        {t('Error Loading Page')}
      </Typography>
      {isDebugEnabled === true &&
        <Typography variant='body1'>
          Error Details: {error}
        </Typography>
      }
    </Row>        
  )
}