import { Typography } from '@mui/material'
import { ReactComponent as ParcelIcon } from '../images/parcel-icon.svg'
import { ReactComponent as OwnerIcon } from '../images/owner-page-icon.svg'
import { ReactComponent as NetworkIcon } from '../images/network-page-icon.svg'
import { ReactComponent as PropertyPortfolioIcon } from '../images/pages/neighborhood-page-icon.svg'

const iconStyle = { height: '45px', width: '45px', margin: 'auto 50%' }

/** @param {{ownerName: string}} props */
export const OwnerSectionHeader = ({ ownerName }) => (
  <>
    <OwnerIcon style={{ ...iconStyle, marginTop: '2.5rem' }} />
    <Typography variant='h4' style={{ paddingBottom: '0' }}>
      Owner
    </Typography>
    <Typography variant='h1' style={{ marginTop: '.15rem', paddingTop: '0' }}>
      {ownerName}
    </Typography>
  </>
)

/** @param {{title: string}} props */
export const ParcelSectionHeader = ({ title }) => (
  <>
    <ParcelIcon style={{ ...iconStyle, marginTop: '2.5rem' }} />
    <Typography variant='h4' style={{ paddingBottom: '0' }}>
      Parcel
    </Typography>
    <Typography variant='h1' style={{ marginTop: '0', paddingTop: '0' }}>
      {title}
    </Typography>
  </>
)

/** @param {{network: string}} props */
export const NetworkSectionHeader = ({ network }) => (
  <>
    <NetworkIcon style={{ ...iconStyle, marginTop: '2.5rem' }} />
    <Typography variant='h4' style={{ paddingBottom: '0' }}>
      Ownership Network
    </Typography>
    <Typography variant='h1' style={{ marginTop: '0', paddingTop: '0' }}>
      {network}
    </Typography>
  </>
)

/** @param {{network: string}} props */
export const PropertyPorfolioSectionHeader = ({ network }) => (
  <>
    <PropertyPortfolioIcon style={{ ...iconStyle, marginTop: '2.5rem' }} />
    <Typography variant='h4' style={{ paddingBottom: '0' }}>
      Property Portfolio
    </Typography>
    <Typography variant='h1' style={{ marginTop: '0', paddingTop: '0' }}>
      {network}
    </Typography>
  </>
)