import ReactMarkdown from 'markdown-to-jsx'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

/* 
taken with minor modifications from 
  https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/blog/Markdown.js 
*/

/** @param {import('@mui/material').BoxProps} props */
function MarkdownListItem(props) {
  return (
    <Typography variant='body1' style={props.style}>
      <Box component='li' {...props} />
    </Typography>
  )
}

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: { variant: 'h1' },
    },
    h2: {
      component: Typography,
      props: { variant: 'h2' },
    },
    h3: {
      component: Typography,
      props: { variant: 'h3' },
    },
    p: {
      component: Typography,
      props: {
        variant: 'body1',
        style: { fontSize: '14pt', maxWidth: '70ch', margin: '0.5rem 0', textIndent: '3ch' },
      },
    },
    a: {
      props: {
        target: '_blank',
        rel: 'noreferrer',
      },
    },
    li: {
      component: MarkdownListItem,
      props: {
        style: { fontSize: '14pt', maxWidth: '70ch', margin: '0.5rem 0' },
      },
    },
  },
}

/** @param {PropsWithoutRef<typeof ReactMarkdown> & {children: string}} props */
export default function Markdown(props) {
  return (
    <ReactMarkdown className='markdown' options={options} {...props} />
  )
}
