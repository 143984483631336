import {
  searchedForMarker,
  relatedParcelMarker,
  directlyEvictedMarker,
} from './Icons/mapIcons'
import { config } from '../../config/config'

/* Set up API data fields and selectors */
const field = config.api.response.infoBox.fields
const value = config.api.response.infoBox.values

/** Determine the appropriate icon for the type of record being displayed:
 *
 *  It could be an eviction with evict_date and evict_id fields: <directlyEvictedMarker/>
 *  It could be a property that would have an addr and parcel_id properties
 *  It could be the searched for or a related property
 *  <searchedForMarker/>, <relatedParcelMarker/>
 *  Open question for us: should we be typing functions like these?
 *  @param {any} record
*/
export function getMapIcon(record) {
  if (typeof record[field.evictionDate] === 'string' && typeof record[field.evictionId] === 'string') {
    return directlyEvictedMarker
  } else if (typeof record[field.evictionAddress] === 'string' && typeof record[field.parcelId] === 'string') {
    return (
      record[field.evictionLocationAccuracy] === value.likely ||
      record[field.evictionType] === value.parcel
    )
      ? searchedForMarker
      : relatedParcelMarker
  } else {
    return relatedParcelMarker
  }
}