import { createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'

/**
 * This file contains Redux utilities to assist with 
 * typed responses. Redux needs to know the shape of our 
 * root state in order to type calls to dispatch() and createAsyncThunk()
 * correctly, so this file imports the shape of the store from store.js
 * and uses it in various utilities here.
 * 
 * For this reason, it has to import the shape of the rootReducer from 
 * store.js -- but store.js imports files like loginSlice.js that 
 * also import from this file. To avoid circular dependencies,
 * _only types_ can be imported from store.js in this file, and not any values
 * from store.js.
 */

/**
 * Type utilities to get inferred types from Redux
 * See [Redux Toolkit docs](https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types)
 * @typedef {typeof import('./store').default['dispatch']} AppDispatch
 * @typedef {ReturnType<typeof import('./rootReducer').default>} RootState
 * */

/** useDispatch hook but correctly typed for our app
 * See [React Redux docs](https://react-redux.js.org/using-react-redux/usage-with-typescript#typing-the-usedispatch-hook)
 */
export const useAppDispatch = () => /** @type {AppDispatch} */ (useDispatch())

/** 
 * createAsyncThunk but correctly typed for our app
 * See [Redux Toolkit docs](https://redux-toolkit.js.org/usage/usage-with-typescript/#defining-a-pre-typed-createasyncthunk)
 * @type {ReturnType<typeof createAsyncThunk.withTypes<{
 *  state: RootState
 *  dispatch: AppDispatch
 *  rejectValue: string
 * }>>}
 */
export const createAppAsyncThunk = createAsyncThunk

/** @type {import('react-redux').TypedUseSelectorHook<RootState>} */
export const useAppSelector  = useSelector
