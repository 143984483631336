import { memo } from 'react'
import { useLocation } from 'react-router-dom'
import { Row, Col } from './Grid'
import FeedbackIFrame from './FeedbackIFrame'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Box from '@mui/material/Box'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBezierCurve, faBalanceScale, faHandFist } from '@fortawesome/free-solid-svg-icons' // methodology, SFADC
import MenuBookIcon from '@mui/icons-material/MenuBook' // glossary
import InsertCommentIcon from '@mui/icons-material/InsertComment' // feedback
import InfoIcon from '@mui/icons-material/Info' // disclaimer
import useStyles from '../theme/FooterStyles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import InterpolateTranslation from './InterpolateTranslation'

/*
  conditionally renders either a mobile footer, or a footer for larger viewports
*/

function Footer() {
  /* Get a React Router location object */
  const location = useLocation()

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'Footer' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  // MaterialUI media query hook
  const isSmallDisplay = useMediaQuery('@media screen and (max-width: 900px')

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) return null

  if (isSmallDisplay) {
    return (
      <>
        {location.pathname !== '/' && (
          <Row justifyContent='center'>
            <FeedbackIFrame />
          </Row>
        )}

        <BottomNavigation
          showLabels
          className={classes.rootMobile}
          style={{ marginTop: location.pathname === '/' ? 0 : 45 }}
        >
          <BottomNavigationAction
            label={t('Tenant Resources')}
            icon={<FontAwesomeIcon icon={faBalanceScale} size='lg' className='fa-icon' />}
            className='footerItem'
            component={Link}
            to='/tenant-resources'
          />

          <BottomNavigationAction
            label={t('Get Involved')}
            icon={<FontAwesomeIcon icon={faHandFist} size='lg' className='fa-icon' />}
            className='footerItem'
            component={Link}
            to='/take-action'
          />

          <BottomNavigationAction
            label={t('Glossary')}
            icon={<MenuBookIcon />}
            className='footerItem'
            component={Link}
            to='/glossary'
          />

          <BottomNavigationAction
            label={t('Methodology')}
            icon={<FontAwesomeIcon icon={faBezierCurve} size='lg' className='fa-icon' />}
            className='footerItem'
            component={Link}
            to='/methodology'
          />

          <BottomNavigationAction
            label={t('Feedback')}
            icon={<InsertCommentIcon />}
            className='footerItem'
            target='_blank'
            component={Link}
            to='/feedback'
          />

          <BottomNavigationAction
            label={t('Disclaimer')}
            icon={<InfoIcon />}
            className='footerItem'
            component={Link}
            to='/disclaimer'
          />
        </BottomNavigation>
      </>
    )
  } else {
    return (
      <Row>
        {location.pathname !== '/' && (
          <Row justifyContent='center'>
            <FeedbackIFrame />
          </Row>
        )}

        <div className={classes.root} style={{ marginTop: location.pathname === '/' ? 0 : 55 }}>
          <Col style={{ maxWidth: '70ch' }}>
            <Typography variant='textInvertedColors1'>
              <InterpolateTranslation 
                components={[({children}) => <Link to='/about' className={classes.item}>
                  <Typography variant='textInvertedColors1'>
                    {children}
                  </Typography>
                </Link>]}>
                {t(
                  'Evictorbook is a tool made by the Anti-Eviction Mapping Project that combines data on property ownership, eviction records, and corporate business filings in Oakland and San Francisco. Read more on our <1>about page</1>'
                )}
              </InterpolateTranslation>
            </Typography>
          </Col>
          <Col className={classes.footerText} style={{ justifyContent: 'flex-end' }}>
            <Box sx={{ width: 'auto' }}>
              <Row direction='column' className={classes.links}>
                <Link
                  to={{ pathname: '/feedback' }}
                  state={{ showForm: true }}
                  className={classes.item}
                >
                  {t('Feedback')}
                </Link>
                <Link to='/glossary' className={classes.item}>
                  {t('Glossary')}
                </Link>
                <Link to='/methodology' className={classes.item}>
                  {t('Methodology')}
                </Link>
                <Link to='/disclaimer' className={classes.item}>
                  {t('Disclaimer')}
                </Link>
                <Link to='/take-action' className={classes.item}>
                  {t('Get Involved')}
                </Link>
                <Link to='/tenant-resources' className={classes.item}>
                  {t('Tenant Resources')}
                </Link>
              </Row>
            </Box>
          </Col>
        </div>
      </Row>
    )
  }
}

export default memo(Footer)
