import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(
  theme => ({
    iconStyle: {
      color: theme.palette.primary.main, 
      fontSize: '5rem', 
      marginBottom: '.5rem',
      marginTop: '.5rem'
    }
  })
)