import { renderEdgePaths, renderLinks } from './links'
import { renderNodes } from './nodes'
import * as d3 from 'd3'

/* Problem: how to isolate the full graph from the partial graph? */
export default function updateGraph(graph, nodeColors, theme, simulation) {
  const nodeData = graph.nodes.filter(n => !n.shouldHide)
  const linkData = graph.links.filter(n => !n.shouldHide)

  simulation.alpha(0.1)
  simulation.nodes(nodeData).force(
    d3
      .forceLink(linkData)
      .id(n => n.id)
      .distance(150)
      .strength(1)
  )

  simulation.restart()

  /* Update everything */
  renderLinks(graph)
  renderNodes(graph, nodeColors, theme, simulation)
  renderEdgePaths(graph)
}
