import  { memo } from 'react'
import { useAppSelector } from 'src/state/hooks'
import { Typography } from '@mui/material'
import { isEmpty, isNull, selectRecords } from './Data/DataUtils'
import DataTable from './DataTable/DataTable'
import { reformatDate } from '../utils/stringUtils'
import { renderOwnerLink, renderParcelLink } from '../utils/tableUtils'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { config } from '../config/config'

/** RAPPeitionList: render a table with a list of RAP petitions
 * @param {import('./DataTable/DataTable').ListProps & {showLocation: boolean, showOwner: boolean}} props
*/
const RAPPetitionList = ({
  title, /* The title displayed above the table */
  subTitle, /* Additional text displayed beneath the title, if any */
  noDataMessage, /* Text to display if there is no data */
  slice, /* Slice with the full list of RAP petitions */
  aboutData, /* Object with info to display under "about" section */
  onSearchFilter, /* Callback when rows are filtered */
  searchHint, /* Content to display when rows are filtered */
  csvFileName,
  showLocation, /* Booleans for whether to display respective cols */
  showOwner
}) => {

  /* Set up API data fields and selectors */
  const select = config.api.response.rapPetitionList.selectors
  const field = config.api.response.rapPetitionList.fields

  /* Set hooks to use Redux global state */
  const rapPetitions = useAppSelector(selectRecords(slice))

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'RAPPetitionList', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  /* Show a message if there is no data */
  if (isEmpty(rapPetitions)) {
    return (
      <>
        <Typography variant='h2'>
          {typeof title === 'string' && title.length > 0 && title}
        </Typography>
        <Typography variant='body1'>{noDataMessage}</Typography>
      </>
    )
  }

  return (
    <>
      <Typography variant='h2'>
        {typeof title === 'string' && title.length > 0 && title}
      </Typography>
      <Typography variant='body1'>
        {subTitle !== null && subTitle}
      </Typography>
      <DataTable
        sortModel={[{ field: field.fileDate, sort: 'desc' }]}
        sortingOrder={['desc', 'asc', null]}
        name='rapPetitions'
        noDataMessage={noDataMessage}
        aboutData={aboutData}
        rows={rapPetitions}
        onSearchFilter={onSearchFilter}
        searchHint={searchHint}
        columns={[/** @type {import("./DataTable/DataTable.js").Column} */ ({
          field: field.fileDate, headerName: t('Date Filed'), flex: 0.5,
          valueFormatter: p => reformatDate(p.value, true),
          searchSelector: row => select.dateFiled(row),
          sortSelector: row => parseInt(select.dateFiledRaw(row))
        })].concat( showLocation ? [
          {
            field: field.location, headerName: t('Location'), flex: 0.5,
            renderCell: p => {
              if (isNull(select.parcelId(p.row))) {
                return 'Unknown'
              }
              
              return (
                renderParcelLink(
                  select.parcelId(p.row), 
                  select.addr(p.row)
                )
              )
            }
          }] : []).concat( showOwner ? [
          {
            field: field.owner, headerName: t('Owner At Time of Petition'), flex: 1.5,
            renderCell: p => {
              if (isNull(select.types(p.row))) {
                return 'Unknown'
              }
        
              return (
                renderOwnerLink(
                  select.ownerName(p.row),
                  select.businessType(p.row)
                )
              )
            }
          }] : []).concat([
          {
            field: field.filer, headerName: t('Filed By'), flex: 2
          },
          {
            field: field.type,
            headerName: t('Filing Type'),
            flex: 2,
            renderCell: p => {
              const types = select.types(p.row)
              if (isNull(types) || types.length <= 0) return 'Unknown'
              if (!Array.isArray(types)) {
                return (<span>{types}</span>)
              }
              if (types.length === 1) {
                return (<span>{types[0]}</span>)
              }
              if (types.length > 1) {
                return (
                  <>
                    {types.map((x, i) =>
                      <div key={i} style={{
                        marginTop: i > 0 ? 12 : 0
                      }}>{x}</div>
                    )}
                  </>
                )
              }
            }
          }
        ])}
        csvFileName={csvFileName}
      />
    </>
  )

}

export default memo(RAPPetitionList)
