import { createSlice } from '@reduxjs/toolkit'
import { isEmpty, isNull } from './DataUtils'
import { createAppAsyncThunk } from '../../state/hooks'

/* 
  DataState: fetch() and Redux functionality for data-enabled components
  props:
    fetch[]: an object specifying data that needs to be fetched (required)
  notes:
    This file, at its core, contains a Redux thunk
    The thunk performs a fetch for each item in the fetch array
    The fetch checks if the slice is in memory and uses that in lieu of a fetch when possible
    Otherwise it performs the fetch, gathers some statistics, gets the JSON, and then returns the entire ensemble
    Finally, reducers modify the state during the fetch lifecycle (pending, fulfilled, rejected)
    Once completed, this process results in the fetched data being available in the Redux store
*/

/**
 * @template {keyof FetchSlices} T
 * @param {ApiRequest<T>} request
 * @param {Partial<FetchSlices>} slices 
 * @returns 
 */
const getCachedSlice = (request, slices) => {
  if (!isNull(slices)) {
    const cachedSlice = slices[request.slice]
    if (!isNull(request.url) && request.url === cachedSlice?.url &&
      cachedSlice?.status === 'fulfilled') {
      console.info(`%c Using cached slice '${request.slice}'`, 'color: cornflowerblue')
      return cachedSlice
    }
  }
  return null
}

/**
 * Perform a fetch for or retrieve from cache the given state slice 
 * @template {keyof FetchSlices} T
 * @param {ApiRequest<T>} request
 * @param {Partial<FetchSlices>} slices
 * @param {RejectWithValue} rejectWithValue
 * @returns {Promise<FetchSlices[T] | null>}
*/
const awaitJson = async (request, slices, rejectWithValue, signal) => {

  /* Wait for data to become available */
  if (isNull(request)) { return null }
  
  const { slice, url, select, debug } = request ?? {}
  
  let response = {}
  let json = {}
  let jsonRoot = null

  const cachedSlice = getCachedSlice(request, slices)
  if (cachedSlice) return cachedSlice

  if (debug) {
    console.info(`%c Fetching '${slice}'`, 'color: cornflowerblue')
  }

  if (signal.aborted) { throw new Error('Cancelled') }

  try {
    const timeSent = Date.now()
    /** @type {FetchSlices[]} */
    response = await fetch(url, { ...request, credentials: 'same-origin', signal })

    const timeReceived = Date.now()

    if (signal.aborted) { throw new Error('Cancelled') }

    if (debug) {
      console.info(`%c API Response for: '${slice}'`, 'color: cornflowerblue', response)
    }

    const { headers, ok = '', status = 0, statusText = '' } = response
    const contentEncoding = headers?.get('content-encoding') ?? ''
    const cacheControl = headers?.get('cache-control') ?? ''

    if (status <= 399) {
      jsonRoot = await response.json()
      json = typeof select === 'function' ? select?.(jsonRoot) : jsonRoot
    }
    else {
      throw new Error('Response returned with error status')
    }

    /** TSC gets a bit...overloaded at this point because 
     * of the repeated use of generics, so cannot check 
     * properly here, but this is correct. Less than ideally turning
     * typechecking off for this line
     */
    // @ts-ignore
    return ({ slice, url, json, response: 
      { ok, status, statusText, timeSent, timeReceived, contentEncoding, cacheControl }
    })
  }
  catch (e) {
    /* Throw errors so they become promise rejections */
    throw (rejectWithValue({ slice, request, url, response, json, error: String(e.message) }, {}))
  }
}

/** 
 * Thunks map asynchronous tasks to state 
 * The async payload creator function here feeds into the extraReducers section within createSlice() 
 * */
export const fetchData = createAppAsyncThunk('fetch', 
  /** @param {ApiRequest<keyof FetchSlices>[]} requests */
  async (requests, { getState, rejectWithValue, signal }) => {

    /* Wait for data to become available */
    if (isEmpty(requests)) { return null }

    const slices = getState()._fetch

    return Promise.allSettled(
      requests.map(async request => await awaitJson(request, slices, rejectWithValue, signal))
    )
  }
)

/** @type {Partial<FetchSlices>} */
const fetchInitialState = {}

/* createSlice() automatically creates actions that incorporate the reducer logic below */
/* Reducers are the functions that modify the state slice */

/**
 * @type {import('@reduxjs/toolkit').Slice<Partial<FetchSlices>, {purgeFetched(): {}}, "fetch">}
 */
const fetchSlice = createSlice({
  name: 'fetch',
  initialState: fetchInitialState,
  reducers: {
    purgeFetched(){
      return {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchData.pending, (state, { meta: { arg } }) => {
        arg.forEach(item => {
          const slice = item?.slice
          return Object.assign(state,
            { ...state, [slice]:
              { pending: true, fulfilled: false, rejected: false,
                /* Overwrite non-serializable select method */
                request: { ...state[slice]?.request, select: null }
              }
            })
        })
      })
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        if(!payload) return
        payload.forEach(item => {
          if (item.status === 'fulfilled') {
            const value = item?.value
            const slice = value?.slice
            if(!slice) return
            return Object.assign(state,
              { ...state, [slice]:
                { ...state[slice], ...value, pending: false, fulfilled: true, rejected: false}
              })
          }
          else if (item.status === 'rejected') {
            const value = item?.reason?.payload
            const slice = value?.slice
            return Object.assign(state,
              { ...state, [slice]:
                { ...state[slice], ...value,
                  response: { status: value?.response?.status, statusText: value?.response?.statusText },
                  pending: false, fulfilled: false, rejected: true}
              })
          }
        })
      })
      .addCase(fetchData.rejected, (state, action) => {
        // console.log('REJ PAYLOAD', action)
        /* This is a work in progress and shouldn't negatively affect the app */
        // error.message
        // meta.requestId
        // meta.requestStatus
        // const slice = meta.arg.find(x=>x !== undefined)?.slice
        // return Object.assign(state,
        //   { [slice]: {...state[slice], error: String(error.message), pending: false, fulfilled: false, rejected: true }
        //   })
      })
  }
})

/* Export the above reducers in a way that can be passed directly to combineReducers() */
export default fetchSlice.reducer
export const { purgeFetched } = fetchSlice.actions