import  { useEffect, useState, memo } from 'react'
import { isEmpty } from '../Data/DataUtils'
import { get_view_network } from './graphProcessor'
import drawGraph from './draw_graph'
import * as d3 from 'd3'
import { Row, Col } from './../Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import useStyles from '../../theme/GraphStyles'

/* D3Graph: This component renders a network graph of properties and owners. 

Props: 
width, height: width and height of the graph svg element. 
graph: The graph data that is fetched and passed by pages/NetworkPage.js into here.
*/
const D3Graph = ({ graph }) => {
  const [sizeFilter, setSizeFilter] = useState('All')

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'Graph' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  const theme = useTheme()
  const classes = useStyles(theme)
  
  /* Draw the graph after the component is done rendering */
  /* This does not run immediately (See docs for useEffect) */
  /* nodeColors will be populated by the time it eventually runs */
  useEffect(() => {
    if(isEmpty(graph)) return
    d3.select('#viz').selectAll('*').remove()
    const network = get_view_network(graph, sizeFilter, 6)
    drawGraph(network, nodeColors, theme)
  })

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  /* This array replaces NODE_COLOR in draw_graph.js for readability + DRY principle */
  /* info here is read into both the legend and the actual node colors in draw_graph */
  const nodeColors = [
    { code: ['Business'], label: t('Business Entity (e.g. LLC)'), color: theme.palette.graph.Business},
    { code: ['Business_Owner'], label: t('Business Owner'), color: theme.palette.graph.Business_Owner},
    { code: ['Address_Unit', 'Address_Main'], label: t('Owner and/or Business Address'), color: theme.palette.graph.Address_Unit },
    { code: ['A_Parcel'], label: t('Property'), color: theme.palette.graph.A_Parcel },
    { code: ['A_Parcel_Owner'], label: t('Property Owner'), color: theme.palette.graph.A_Parcel_Owner },
  ]

  return JSON.stringify(graph) === '{}' ? (
    <div style={{ display: 'flex', placeItems: 'center', width: '100%', justifyContent: 'center' }}>
      <CircularProgress variant="determinate" />
    </div>
  ) : (
    <>
      <Row justifyContent='flex-start'>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <select
            value={sizeFilter}
            onChange={e => setSizeFilter(e.target.value)}>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='All'>{t('Show all nodes')}</option>
          </select>
        </Col>
        <div className={classes.graphLegend}>
          {nodeColors.map((d, i) => {
            return (
              <div
                key={i}
                style={{
                  flexBasis: '33%',
                  display: 'flex',
                  marginTop: '10px',
                  justifyContent: 'right',
                  whiteSpace: 'nowrap',
                  maxWidth: 'fit-content',
                }}>
                <Col style={{ backgroundColor: d.color, marginLeft: 10, marginRight: 10 }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Col>
                <Col>
                  <Typography className={classes.legendText}>
                    {'  '}
                    {d.label}
                    {'  '}
                  </Typography>
                </Col>
              </div>
            )
          })}
        </div>
      </Row>
      <br />
      <svg
        id='viz'
        className={classes.viz}
        width='100%'
        preserveAspectRatio='xMidYMid meet'
      />
    </>
  )
}

export default memo(D3Graph)
