import  { memo } from 'react'
import { useAppSelector } from 'src/state/hooks'
import { Typography } from '@mui/material'
import { isEmpty, selectJson } from './Data/DataUtils'
import DataTable from './DataTable/DataTable'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { config } from '../config/config'

/** ForeclosureList: render a table with a list of foreclosures
 * @param {import('./DataTable/DataTable').ListProps} props
*/
const ForeclosureList = ({
  title /* The title displayed above the table */,
  subTitle /* Additional text displayed beneath the title, if any */,
  noDataMessage /* Text to display if there is no data */,
  slice, /* Slice with the full list of evictions */
  aboutData, /* Object with info to display under "about" section */
  csvFileName,
  onSearchFilter, /* Callback when rows are filtered */
  searchHint /* Content to display when rows are filtered */
}) => {
  /* Set up API data fields and selectors */
  const select = config.api.response.foreclosureList.selectors
  const field = config.api.response.foreclosureList.fields

  /* Set hooks to use Redux global state */
  const { records: foreclosures, error } = useAppSelector(selectJson(slice))

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'ForeclosureList' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  if (isEmpty(foreclosures)) {
    return (
      <>
        <Typography variant='h2'>
          {typeof title === 'string' && title.length > 0 && title}
        </Typography>
        <Typography variant='body1'>
          {error
            ? t(
              'Oh no! We ran into an error while retrieving foreclosure data. Could you try logging in again?'
            )
            : noDataMessage}
        </Typography>
      </>
    )
  }

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) {
    return null
  }

  return (
    <>
      <Typography variant='h2'>{typeof title === 'string' && title.length > 0 && title}</Typography>
      <Typography variant='body1'>{subTitle !== null && subTitle}</Typography>
      <DataTable
        sortModel={[{ field: field.foreclosureDate, sort: 'desc' }]}
        sortingOrder={['desc', 'asc', null]}
        name='foreclosures'
        noDataMessage={noDataMessage}
        aboutData={aboutData}
        rows={foreclosures}
        csvFileName={csvFileName}
        onSearchFilter={onSearchFilter}
        searchHint={searchHint}
        columns={[
          {
            field: field.foreclosureDate,
            headerName: t('Date'),
            flex: 1.5,
            renderCell: p => {
              return select.foreclosureDate(p.row)
            },
            searchSelector: row => select.foreclosureDate(row),
            sortSelector: row => parseInt(select.foreclosureDateRaw(row))
          },
          {
            field: field.foreclosureType,
            headerName: t('Type'),
            flex: 1.5,
            renderCell: p => {
              return select.foreclosureType(p.row)
            }
          },
          {
            field: field.loanAmount,
            headerName: t('Loan Amount'),
            flex: 1.5,
            renderCell: p => {
              return select.loanAmount(p.row)
            }
          },
          {
            field: field.defaultAmount,
            headerName: t('Default Amount'),
            flex: 1.5,
            renderCell: p => {
              return select.defaultAmount(p.row)
            }
          },
          {
            field: field.loanNumber,
            headerName: t('Loan Number'),
            flex: 2,
            renderCell: p => {
              return select.loanNumber(p.row)
            }
          },
          {
            field: field.borrowerName,
            headerName: t('Loan Borrower'),
            flex: 3,
            renderCell: p => {
              return select.borrowerName(p.row)
            }
          },
          {
            field: field.lenderName,
            headerName: t('Loan Lender'),
            flex: 3,
            renderCell: p => {
              return select.lenderName(p.row)
            }
          },
          {
            field: field.auctionDate,
            headerName: t('Auction Date'),
            flex: 2,
            renderCell: p => {
              return select.auctionDate(p.row)
            }
          }
        ]}
      />
    </>
  )
}

export default memo(ForeclosureList)
