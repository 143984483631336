import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(theme => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'center',
    maxWidth: '90ch'
  },
  body: {
    width: '100%',
    maxWidth: '100%',
    textIndent: '3ch'
  },
  subtitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    placeItems: 'center'
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecorationColor: theme.palette.background.underlineOverBackground,
    '&:hover': {
      textDecorationColor: theme.palette.primary.light
    }
  }
}))
