import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { Row, Col } from '../components/Grid'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PageTitle from '../components/PageTitle'
import { useTheme } from '@mui/material/styles'
import useStyles from '../theme/TenantResourcesStyles'

const TenantResources = () => {
  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'TenantResourcesPage' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )

  /* theming and styling */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) {
    return null
  }

  const resources = [
    {
      title: t('Tenant and Neighborhood Councils'),
      link: 'https://baytanc.com/tanc-locals/',
      description: t(
        'Tenant and Neighborhood Councils (TANC) is a member-run housing organization of locally organized councils of tenants. TANC encourage all tenants of private landlords, unhoused people, and public housing residents, to contact TANC locals and join organizing councils.'
      ),
    },
    {
      title: t('The Tenant Power Toolkit'),
      link: 'https://tenantpowertoolkit.org',
      description: t(
        "The Tenant Power Toolkit was created by the Debt Collective in partnership with the LA Tenants Union, Anti-Eviction Mapping Project, tenants' organizations and other anti-debt organizations, anti-eviction lawyers and legal service providers. This toolkit is a resource to keep people in their homes, help fight evictions, help fight rent debt, and build the collective power of tenants."
      ),
    },
    {
      title: t('Centro Legal de la Raza'),
      link: 'https://www.centrolegal.org',
      description: t(
        'Founded in 1969, Centro Legal de la Raza is a legal services agency protecting and advancing the rights of low-income, immigrant, Black, and Latinx communities through bilingual legal representation, education, and advocacy. By combining quality legal services with know-your-rights education and youth development, Centro Legal de la Raza ensures access to justice for thousands of individuals throughout Northern and Central California.'
      ),
    },
    {
      title: t('San Francisco Anti-Displacement Coalition (SFADC)'),
      link: 'https://sfadc.org/',
      description: t(
        'The SFADC is a group of tenant organizaitons and allies who organize against the soaring evictions and rent increases in our city, which have resulted in the displacement of thousands of San Franciscans. The Affordable Housing Alliance, the Anti-Eviction Mapping Project, the Community Tenants Association, and Eviction Free San Francisco are just a few of the many groups that fight together as the SFADC.'
      ),
    },
    {
      title: t('Housing Rights Committee of San Francisco'),
      link: 'http://hrcsf.org',
      description: t(
        'Housing Rights Committee has fought for tenants rights since 1979, when a group of seniors at Old St. Mary’s Church came together to organize against condo conversions displacing the elderly. They offer free counseling for San Francisco tenants in all types of housing, lead organizing efforts at the building level to fight against displacement and evictions, and help establish community-wide tenants groups to build power long-term.'
      ),
    },
    {
      title: t('Chinatown Community Development Center'),
      link: 'https://www.chinatowncdc.org',
      description: t(
        'The Chinatown Community Development Center works to build community and enhance the quality of life for San Francisco residents.  They are a place-based community development organization serving primarily the Chinatown neighborhood, and also serve other areas including North Beach and the Tenderloin. They are a community development organization with many roles - as neighborhood advocates, organizers and planners, and as developers and managers of affordable housing.'
      ),
    },
    {
      title: t('Eviction Defense Collaborative'),
      link: 'https://evictiondefense.org',
      description: t(
        'The Eviction Defense Collaborative is the principal organization in San Francisco helping low-income tenants respond to eviction lawsuits. Each year they provide emergency legal services and rental assistance to over 5,000 tenants in San Francisco. Their programs range from assisting tenants fight evictions in court, to helping tenants behind on rent obtain rental assistance, and leading advocacy for implementing San Francisco Right to Counsel.'
      ),
    },
    {
      title: t("The Oakland Tenants' Union"),
      link: 'http://www.oaklandtenantsunion.org/tenant-resources.html',
      description: t(
        "The Oakland Tenants Union (OTU) is an organization of housing activists. OTU's core values are to protect and expand tenant rights and interests. OTU works directly with tenants in their struggle with landlords, impacts legislation and public policy about housing, community education, and works with other organizations committed to furthering renters’ rights."
      ),
    },
    {
      title: t('The Alliance of Californians for Community Empowerment'),
      link: 'https://www.acceaction.org/about',
      description: t(
        'The Alliance of Californians for Community Empowerment (ACCE) is a grassroots collective of Californians that fights for tenants, immigrants, the working class, and people of color.'
      ),
    },
    {
      title: t('Causa Justa :: Just Cause'),
      link: 'https://cjjc.org',
      description: t(
        "Causa Justa :: Just Cause (CJJC) is a grassroots organization in the Bay Area, and an alliance between St. Peter's Housing Committee, Just Cause Oakland, and People Organized to Win Employment Rights. CJJC is fighting and organizing across East and West Oakland as well as San Francisco's Mission and Bayview neighborhoods."
      ),
    },
  ]

  return (
    <Row justifyContent='center'>
      <Col xs={10} sm={8} md={6} className={classes.page}>
        <PageTitle text={t('Tenant Resources')} />
        <Typography variant='body1' className={classes.body}>
          {t(
            "We're in this fight together. We help each other find resources for rent relief, take legal action, and campaign against rotten landlords."
          )}
        </Typography>
        <Typography variant='body1' className={classes.body}>
          {t(
            'This page details resources and organizing groups to help you find the support you need.'
          )}
        </Typography>
        {resources.map(({ title, link, description }, i) => {
          return (
            <section key={i}>
              <div className={classes.subtitle}>
                <Typography variant='h2'>
                  <a href={link} rel='noreferrer' target='_blank' className={classes.link}>
                    {title}&nbsp;
                    <OpenInNewIcon />
                  </a>
                </Typography>
              </div>
              <Typography variant='body1' className={classes.body}>
                {description}
              </Typography>
            </section>
          )
        })}
      </Col>
    </Row>
  )
}

export default TenantResources
