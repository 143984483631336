import { memo } from 'react'
import { useAppSelector } from 'src/state/hooks'
import { Typography } from '@mui/material'
import { isEmpty, isNull, selectRecords } from './Data/DataUtils'
import DataTable from './DataTable/DataTable'
import { reformatDate } from '../utils/stringUtils'
import { renderOwnerLink, renderParcelLink } from '../utils/tableUtils'
import { useTranslation } from 'react-i18next'
import useStyles from '../theme/ListStyles'
import { useTheme } from '@mui/material/styles'
import i18n from '../i18n'
import { config } from '../config/config'

/** BuildingPermitList: render a table with a list of building permits
 * @param {import('./DataTable/DataTable').ListProps & {showLocation: boolean, showOwner: boolean}} props
*/
const BuildingPermitList = ({
  title, 
  subTitle, 
  noDataMessage, 
  slice, 
  aboutData, 
  onSearchFilter, 
  searchHint, 
  csvFileName,
  showLocation, 
  showOwner, 
}) => {

  /* Set up API data fields and selectors */
  const select = config.api.response.buildingPermitList.selectors
  const field = config.api.response.buildingPermitList.fields

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Set hooks to use Redux global state */
  const buildingPermits = useAppSelector(selectRecords(slice))

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'BuildingPermitList', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  /* Show a message if there is no data */
  if (isEmpty(buildingPermits)) {
    return (
      <>
        <Typography variant='h2'>
          {typeof title === 'string' && title.length > 0 && title}
        </Typography>
        <Typography variant='body1'>{noDataMessage}</Typography>
      </>
    )
  }

  return (
    <>
      <Typography variant='h2'>
        {typeof title === 'string' && title.length > 0 && title}
      </Typography>
      <Typography variant='body1'>
        {subTitle !== null && subTitle}
      </Typography>
      <DataTable
        sortModel={[{ field: field.fileDate, sort: 'desc' }]}
        sortingOrder={['desc', 'asc', null]}
        name='buildingPermits'
        noDataMessage={noDataMessage}
        aboutData={aboutData}
        rows={buildingPermits}
        onSearchFilter={onSearchFilter}
        searchHint={searchHint}
        columns={[/** @type {import("./DataTable/DataTable.js").Column} */ ({
          field: field.fileDate,
          headerName: t('Date'),
          flex: 0.5,
          type: 'date',
          valueFormatter: p => reformatDate(p.value, true),
          searchSelector: row => select.fileDate(row),
          sortSelector: row => parseInt(select.fileDateRaw(row))
        })].concat( showLocation ? [
          {
            field: field.location,
            headerName: t('Location'),
            flex: 0.5,
            type: 'string',
            renderCell: p => {
              if (isNull(select.parcelId(p.row))) {
                return 'Unknown'
              }
              
              return (
                renderParcelLink(
                  select.parcelId(p.row), 
                  select.addr(p.row)
                )
              )
            }
          }] : []).concat( showOwner ? [
          {
            field: field.owner,
            headerName: t('Owner At Time of Permit'),
            flex: 1.5,
            type: 'string',
            renderCell: p => {
              if (isNull(select.ownerName(p.row))) {
                return 'Unknown'
              }
        
              return (
                renderOwnerLink(
                  select.ownerName(p.row),
                  select.businessType(p.row)
                )
              )
            },
            searchSelector: row => select.ownerName(row),
            sortSelector: row => select.ownerName(row)
          }] : []).concat([
          {
            field: field.type,
            headerName: t('Permit Type'),
            flex: 2,
            type: 'string'
          },
          {
            field: field.description,
            renderCell: p => {
              if (isNull(select.description(p.row))) {
                return 'Unknown'
              }

              return (
                <span className={classes.limitedTextLength}>{select.description(p.row)}</span>
              )
            },
            headerName: t('Description'),
            flex: 2,
            searchSelector: row => select.description(row),
            sortSelector: row => select.description(row)
          },
          {
            field: field.status,
            headerName: t('Status'),
            flex: 0.75,
            type: 'string'
          }
        ])}
        csvFileName={csvFileName}
      />
    </>
  )

}

export default memo(BuildingPermitList)
