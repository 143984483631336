import makeStyles from '@mui/styles/makeStyles'

/*
  Component Styles: provides non-global styles for a specific component
    If a style is global it should go in Theme.js not here
*/

export default makeStyles(
  theme => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '8.5rem',
      width: '100%',
      borderTop: `.5px solid ${theme.palette.primary.dark}`,
      padding: theme.spacing(2),
      background: theme.palette.primary.main,
    },
    rootMobile: {
      height: 'fit-content',
      width: '100%',
      padding: '1rem',
      display: 'flex',
      flexWrap: 'wrap',	
      borderTop: `1px solid ${theme.palette.primary.dark}`,
      backgroundColor: theme.palette.primary.main,
      '& .footerItem': {
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        height: 'fit-content',
        opacity: '0.7'
      },
      '& .footerItem:hover': {
        opacity: '1.0'
      },
      '& .fa-icon': {
        marginBottom: '4px',
        opacity: '1.0'
      },
    },
    item: {
      color: theme.palette.primary.contrastText,
      textDecorationColor: theme.palette.primary.contrastTextDecorationColor,
      '&:hover': {
        textDecoration: 'underline'
      },
      '&:focus': {
        textDecoration: 'underline'
      },
      fontSize: '.89rem',
    },
    footerText: {
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(2),
      display: 'flex',
      flex: 1,
      '&::selection': { color: theme.palette.primary.main, background: theme.palette.primary.contrastText },
      '& *::selection': { color: theme.palette.primary.main, background: theme.palette.primary.contrastText },
    },
    links: {
      textAlign: 'right'
    }
  })
)
