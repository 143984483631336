import  { forwardRef } from 'react'
import PropTypes from 'prop-types'
import DataInner from './DataInner'
import { ErrorBoundary } from 'react-error-boundary'
import { getChildren } from './DataUtils'
import { Error } from '../NotAvailable'
export { isEmpty, isNull, selectRecords, selectMarkerData, selectCoordsData, urlParam, selected, renderChildren } from './DataUtils'

/*
  Data: a component to add data fetching and conditional rendering to other components without manually-written Redux or fetch code
  Props:
    fetchArray: an object specifying data that needs to be fetched (optional)
    isDebugEnabled: a boolean that when true enables debug info in the UI and the console 
  notes:
    Wraps the following functionality into a composable component:
      Redux - manages storing state in Redux based on 'slice' names
      Pending - tracks the pending state and display content such as a loading indicator
      Fulfilled - tracks the fulfillment state and displays child content (typically a page's, map's, or table's contents)
      Rejected - tracks the rejection state and display constent such as an error indicator
      NoData - tracks requests that are fulfilled but lacked data and shows content such as a no-data indicator
      Timeout - tracks time in seconds and shows content such as a page-not-found indicator
*/

const Data = forwardRef(({
  children,
  isDebugEnabled,
  fetchArray,
  onFetchPromise,
  ...rest
}, ref) => {

  const subComponentChildren = ({
    pending: getChildren(children, 'Pending'),
    fulfilled: getChildren(children, 'Fulfilled'),
    rejected: getChildren(children, 'Rejected'),
    timeout: getChildren(children, 'Timeout'),
    nodata: getChildren(children, 'NoData')
  })

  function ErrorFallback({error, reset}) {
    return (
      <div style={{ marginTop: 30 }}>
        <Error />
      </div>
    )
  }

  return (
    /* Prevent an unexpected error from unmounting React */
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DataInner
        {...rest}
        fetchArray={fetchArray}
        onFetchPromise={onFetchPromise}
        children={children}
        isDebugEnabled={isDebugEnabled}
        subComponentChildren={subComponentChildren}
        ref={ref}
      />
    </ErrorBoundary>
  )

})

/* Define sub-components types */
const Pending = props => <>{props.children}</>
Pending.displayName = 'Pending'
Data.Pending = Pending

const Fulfilled = props => <>{props.children}</>
Fulfilled.displayName = 'Fulfilled'
Data.Fulfilled = Fulfilled

const Rejected = props => <>{props.children}</>
Rejected.displayName = 'Rejected'
Data.Rejected = Rejected

const Timeout = props => <>{props.children}</>
Timeout.displayName = 'Timeout'
Data.Timeout = Timeout

const NoData = props => <>{props.children}</>
NoData.displayName = 'NoData'
Data.NoData = NoData

Data.propTypes = {
  fetchArray: PropTypes.array,
  children: PropTypes.array,
  isDebugEnabled: PropTypes.bool
}

export default Data