import  { useState, memo } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { Row, Col } from '../components/Grid'
import PageTitle from '../components/PageTitle'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useStyles from '../theme/UtilStyles'
import { useTheme } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'

const Glossary = () => {

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles()

  /** @typedef {false | `panel${string | number}`} Expanded */
  const [expanded, setExpanded] = useState(/** @type {Expanded} */ (false))

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'GlossaryPage', /* i18next-parser needs string here */
    { useSuspense: false, i18n }
  )

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  const questions = [
    {
      siteId: 'just-cause-no-fault',
      question: t('What causes for eviction are considered just cause - no-fault?'),
      answer: [
        t('Owner/relative move-in'),
        t('Condo conversion'),
        t('Demolition / removal from rental market'),
        t('Temporary removal from rental market for capital improvements or rehabilitation work'),
        t('Substantial rehabilitationLead remediation or abatement work'),
        t('Demolition / removal from rental market in accordance with development agreement entered into by the City')
      ],
      link: 'https://sanfrancisco-policies.glitch.me/',
      credit: t('Tenants Together / Urban Displacement Project')
    },
    {
      siteId: 'just-cause-tenant-at-fault',
      question: t('What is Considered Just Cause - tenant at-fault?'),
      answer: [
        t('Failure to pay rent'),
        t('Violation of the rental agreement or lease'),
        t('Unreasonable interference with the comfort, safety or enjoyment of other tenants, or damaging the rental unit or the property'),
        t('Using your rental unit or the common areas of the property for an illegal purpose'),
        t('Refusal to renew a lease or rental agreement of like terms and conditions'),
        t('Refusing the landlord reasonable access to the rental unit for repairs and inspections to name a couple of examples'),
        t('At the end of a lease term, the landlord discovers the tenant is not the person who initially rented the unit and the landlord didn’t approve this person’s tenancy')
      ],
      link: 'https://sanfrancisco-policies.glitch.me/',
      credit: t('Tenants Together / Urban Displacement Project')
    },
    {
      siteId: 'pay-relocation-assistance',
      question: t('What triggers the obligation to pay relocation assistance?'),
      answer: [
        t('Owner/relative move-in (unless tenant is elderly or disabled, then not justified)'),
        t('Demolition / removal from rental market'),
        t('Temporary removal from rental market for capital improvements'),
        t('Substantial rehabilitation')
      ],
      link: 'https://sanfrancisco-policies.glitch.me/',
      credit: t('Tenants Together / Urban Displacement Project')
    }
  ]

  const terms = [
    {
      siteId: 'apn',
      term: t('APN'),
      definition: t(`APN is the assessor's parcel number - 
        a number assigned to property parcels by 
        the tax assessor of a particular jurisdiction 
        for identification and record-keeping. The 
        assigned number is unique within the 
        particular jurisdiction.`),
      link: 'https://en.wikipedia.org/wiki/Assessor%27s_parcel_number',
      credit: t('Wikipedia')
    },
    {
      siteId: 'assessors-office',
      term: t('Assessors Office'),
      definition: t(`Tax assessment, or assessment, is the job of 
        determining the value, and sometimes determining the 
        use, of property, usually to calculate a property tax. 
        This is usually done by an office called the assessor 
        or tax assessor.`),
      link: 'https://en.wikipedia.org/wiki/Tax_assessment',
      credit: t('Wikipedia')
    },
    {
      siteId: 'buyouts',
      term: t('Buyouts'),
      definition: t(`Buyouts are often offered to tenants as 
        ways to leave, recieve some funding, and not formally 
        be evicted. However, most tenants don’t understand their 
        rights to stay and when the buyout is coupled with threats 
        to evict, tenants are often intimidated into taking the buyout. 
        Lately buyouts have been on the rise as a means of 
        informally evicting tenants.`),
      link: 'https://www.sftu.org/buyouts/',
      credit: t('SF Tenants Union')
    },
    {
      siteId: 'capital-improvement-pass',
      term: t('Capital Improvement and Passthroughs'),
      definition: t(`For certain types of rent increases, landlords must file 
        a petition at the Rent Board before serving the tenants with a notice of 
        the increase. The tenants are not required to pay the increase until the 
        Rent Board approves it, but if approved, the rent increase will be retroactive 
        to the effective date specified in a valid notice of increase.There are six 
        types of rent increases that require the filing of a landlord petition. These are: 
        Capital Improvement Passthrough; Operating and Maintenance Expense Increase; Special 
        Circumstances Increase Based on Rents for Comparable Units;
        Rent Increase Based on the Past Rent History of a Proposition I Affected Unit; 
        Utility Passthrough (requires the filing of either a petition or a worksheet); 
        and Rules and Regulations Section 1.21 Increase where there is no Tenant in Occupancy`),
      link: 'https://sfrb.org/fact-sheet-5-landlord-petitions-and-passthroughs',
      credit: t('SF Rent Board')
    },
    {
      siteId: 'condos-tic-conversions',
      term: t('Condos and TIC Conversions'),
      definition: t(`TICs (Tenancies In Common) are usually the first step for removing housing 
        from rent control to create owner-occupied housing for the profit of real estate investors. 
        TICs are joint ownership of a building where each owner has a side agreement to only
        occupy a single unit although the owners have joint liability and usually a shared mortgage.`),
      link: 'https://www.sftu.org/newtic/',
      credit: t('SF Tenants Union')
    },
    {
      siteId: 'eligible-units',
      term: t('Eligible Units'),
      definition: t(`Housing Choice Voucher Program
        Over-FMR Tenancy program 
        Section 8
        Federal LIHTC (when a tenant’s occupancy of unit began before LIHTC regulatory agreement was recorded, 
        and rent is not controlled by City agencies)
        Any unit where a qualifying tenant has been in possession of the unit since 1/19/2007ADUs (accessory dwelling unit)`),
      link: 'https://docs.google.com/spreadsheets/d/13UjOKg07X-GP2AETMKcXqBDUCwJ_dvsLZzzvLkLCj1E/edit',
      credit: t('Tenants Together / Urban Displacement Project')
    },
    {
      siteId: 'ellis-act-ecivtions',
      term: t('Ellis Act Evictions'),
      definition: t(`The “Ellis Act” is a state law which says that landlords have the unconditional 
        right to evict tenants to “go out of business.” For an Ellis eviction, the landlord must remove 
        all of the units in the building from the rental market, i.e., the landlord must evict all 
        the tenants and cannot single out one tenant (for example, with low rent) and/or remove just 
        one unit out of several from the rental market. The Ellis Act is included in the just causes 
        for eviction under the Rent Ordinance as Section 37.9(a)13).`),
      link: 'https://www.sftu.org/ellis/',
      credit: t('SF Tenants Union')
    },
    {
      siteId: 'estoppel-agreement',
      term: t('Estoppel Agreement'),
      definition: t(`When a building is for sale, the realtor often gives tenants an “estoppel agreement” or 
      “rental questionnaire” to sign. The landlord is seeking information in this form to tie your down 
        to what you understand your rental agreement means. You do not have to fill out or sign this form 
        unless your rental agreement requires that you fill out any estoppel agreements or similar requests; 
        it is for the landlord’s convenience. Any statements you agree to (explicitly or through omission) may 
        come back to haunt you later.`),
      link: 'https://www.sftu.org/sale/',
      credit: t('SF Tenants Union')
    },
    {
      siteId: 'evictor',
      term: t('Evictor'),
      definition: t('An entity or corporation taking legal action to remove someone from occupying a building and/or their home. We recognize that evictions also take place through unofficial and nonlegal means, also by evictors. In this tool, however, we are only looking at formal evictions.'),
      link: 'https://antievictionmap.com/',
      credit: t('Anti-Eviction Mapping Project')
    },
    {
      siteId: 'exempt-eligibility',
      term: t('Exemptions from Eligibility'),
      definition: t(`Rental units located in a structure for which a certificate of occupancy was first issued 
        after the effective date of this ordinance (6/13/1979)
        Unit where substantial rehabilitation was undertaken after effective date of this ordinance
        Units exempted from rent stabilization by Costa-Hawkins: single family homes`),
      link: 'https://docs.google.com/spreadsheets/d/13UjOKg07X-GP2AETMKcXqBDUCwJ_dvsLZzzvLkLCj1E/edit',
      credit: t('Tenants Together / Urban Displacement Project')
    },
    {
      siteId: 'habitability-repairs',
      term: t('Habitability and Repairs'),
      definition: t(`Tenants have the right to safe and habitable homes in exchange for the rents we are paying. 
        If your landlord is not making repairs, he or she is violating your “Warranty of Habitability” and you 
        have the right to have repairs made. Tenants have the right to report landlords to city agencies and 
        rights to withhold rent or “repair and deduct.” And the landlord cannot retaliate against you; any 
        eviction or rent increase attempt within six months of such a complaint will be examined by the judge 
        for retaliation.`),
      link: 'https://www.sftu.org/repairs/',
      credit: t('SF Tenants Union')
    },
    {
      siteId: 'harassment',
      term: t('Harassment'),
      definition: t(`Your landlord, or anyone acting for your landlord, cannot harass you out of your home. 
        Landlords cannot evict you by just locking you out, shutting off your utilities, forcibly entering 
        your home without notice, or removing your belongings.`),
      link: 'https://www.sftu.org/harass/',
      credit: t('SF Tenants Union')
    },
    {
      siteId: 'just-cause-fault-eviction',
      term: t('Just Cause / Fault Eviction'),
      definition: t(`For rent-controlled units, the landlord must have honest intent, without ulterior motive 
        (e.g., a motive of raising the rent) to evict the tenant with “just cause.” Below are the just causes 
        summarized; for the legal wording get the rent control ordinance and see Section 37.9(a). 
        If done properly, the landlord may evict for these just causes. (The landlord may also evict without 
        a just cause under rent control if the landlord shares the rental unit with the tenant.) However, 
        many of these eviction attempts are done improperly and may be successfully defended. Talk to a 
        counselor and/or get our handbook to find out your defenses.`),
      link: 'https://www.sftu.org/justcauses/',
      credit: t('SF Tenants Union')
    },
    {
      siteId: 'llc',
      term: t('LLC'),
      definition: t(`A limited liability company (LLC) is the US-specific form of a private limited company. 
        It is a business structure that can combine the pass-through taxation of a partnership or sole 
        proprietorship with the limited liability of a corporation. An LLC is not a corporation under state law; 
        it is a legal form of a company that provides limited liability to its owners in many jurisdictions. 
        LLCs are well known for the flexibility that they provide to business owners; depending on the situation, 
        an LLC may elect to use corporate tax rules instead of being treated as a partnership and, under certain 
        circumstances, LLCs may be organized as not-for-profit. Many landlords and speculators use LLCs as shell 
        companies to buy properties and evict tenants. This provides them anonymity and protection.`),
      link: 'https://en.wikipedia.org/wiki/Limited_liability_company',
      credit: t('Wikipedia')
    },
    {
      siteId: 'mergers-demolitions',
      term: t('Mergers and Demolitions'),
      definition: t(`Mergers and demolition evictions should be done under eviction just cause 37.9(a)(10) if the rental 
        unit is covered under the San Francisco Rent Ordinance and is a single unit. Developments may be able to demolish 
        multiple units under just cause 37.9(a)(15). Just cause 37.9(a)(15) has been used to evict many tenants in developments 
        such as Trinity Properties and Park Merced. Other evictions are anticipated using this just cause unless the law is changed. 
        It is particularly important in these cases of demolition of multiple units to organize as tenants.`),
      link: 'https://www.sftu.org/merger/',
      credit: t('SF Tenants Union')
    },
    {
      siteId: 'omi-evictions',
      term: t('Owner Move In (OMI) Evictions'),
      definition: t(`For rent-controlled units, one of the most common evictions in San Francisco is for owner or relative move-in 
        (San Francisco Administrative Code Section 37.9(a)(8)). Subject to certain restrictions,a landlord can evict a tenant if 
        the landlord is going to move into the unit to live, or (only if the landlord is also going to be living in the building) 
        for a close relative to move in and live there. These evictions are highly abused and landlords who want to evict a tenant 
        in order to raise the rent on a new tenant typically use owner move-in evictions (OMIs/landlord move-in evictions/LMIs) that 
        are only allowed if done properly.`),
      link: 'https://www.sftu.org/omi/',
      credit: t('SF Tenants Union')
    },
    {
      siteId: 'rent-control',
      term: t('Rent Control'),
      definition: t(`In San Francisco, most residential tenants are covered by rent control. This means rents can only be raised by certain 
        amounts per year and the tenant can only be evicted for “just causes.” In addition, some rental units have restrictions on how much 
        the landlord can charge the new tenant due to previous evictions. Tenants who do not have rent control can have their rent increased 
        by any amount at any time with a proper written notice.`),
      link: 'https://www.sftu.org/rent-control/',
      credit: t('SF Tenants Union')
    },
    {
      siteid: 'sale-of-building',
      term: t('Sale of Building'),
      definition: t(`All agreements (such as written and oral month-to-month or lease agreements) you made with the old landlord are binding on the new 
        landlord. Similarly, the new landlord is bound to any legal orders or judgments: if the Department of Building Inspection ordered the old 
        landlord to make repairs, the new landlord must make those repairs; if the Rent Board has ordered rents reduced, the new landlord must follow 
        this order. The seller and buyer of a multi-unit building covered under the San Francisco Rent Ordinance must disclose to tenants (in writing) 
        that they cannot be evicted, have their rent increased, or have the terms of their tenancy changed just because the building is being sold or has sold. 
        Beware, though, many landlords try to use the sale or foreclosure of a building to intimidate tenants.`),
      link: 'https://www.sftu.org/sale/',
      credit: t('SF Tenants Union')
    },
    {
      siteId: 'short-term-rentals',
      term: t('Short Term Rentals'),
      definition: t(`Short term vacation rentals replace long term housing stock in San Francisco and in cities across the world. In San Franicsco, 
        Airbnb has reduced vacant rental housing stock by as much as 43 percent, making homes even less affordable. At times, tenants are illegally 
        evicted and their rooms become Airbnb units.`),
      link: 'https://www.sftu.org/short-term-rentals/',
      credit: t('SF Tenants Union')
    }
  ]

  /**
   * @param {`panel${number|string}`} panel
   * @returns {import('@mui/material').AccordionProps["onChange"]} 
   * */
  const handleChange = panel => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false)

  return (
    <Row justifyContent='center'>
      <Col xs={10} sm={8} md={6}>
        <PageTitle text={t('Glossary')} />
        {terms.map(({ term, definition, link, credit}, index) =>
          <Accordion
            expanded={expanded === 'panel' + index}
            key={index}
            onChange={handleChange(`panel${index}`)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant='body1' className={classes.leftAlignText}>{term}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant='body1' className={classes.leftAlignText}>{definition}</Typography>
              <Typography variant='body1' className={classes.leftAlignText} style={{marginTop: 20}}>
                {t('Source:')}&nbsp;
                <a className='link' href={link} target = '_blank' rel='noopener noreferrer'
                  style={{
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.contrastText
                  }}>
                  {credit}
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
        {questions.map(({ question, answer, siteId, link, credit }) =>
          <Accordion
            expanded={expanded === 'panel' + siteId}
            key={siteId}
            onChange={handleChange(`panel${siteId}`)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.leftAlignText} variant='body1'>
                {question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{display: 'flex', flexDirection: 'column'}}>
              {answer.map((point,index) =>
                <ListItem button key={index}>
                  <Typography className={classes.leftAlignText} variant='body1'>{point}</Typography>
                </ListItem>
              )}
              <Typography className={classes.leftAlignText} style={{marginTop: 20}} variant='body1'>
                {t('Source:')}&nbsp;
                <a className='link' href={link} target='_blank' rel='noopener noreferrer' style={{
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.contrastText
                }}>
                  {credit}
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
      </Col>
    </Row>
  )
}

export default memo(Glossary)