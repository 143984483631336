import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import { createLogger } from 'redux-logger'

const isProduction = typeof process.env.NODE_ENV === 'string' &&
  process.env.NODE_ENV === 'production'

/**
 * getDefaultMiddleware() returns the following by default:
 * [
 *  thunk, <-- allows Redux to perform asyncronous tasks
 * immutableStateInvariant, <-- throws an error when state is mutated
 * serializableStateInvariant <-- logs a warning if Redux is abused to store non-data
 * ] 
 */
const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => !isProduction
    ? getDefaultMiddleware({ serializableCheck: {
      ignoredActions: ['fetch/fulfilled'] }
    }).concat(createLogger({ logErrors: true }))
    : getDefaultMiddleware(),
  devTools: !isProduction
})

export default store