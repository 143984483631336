import  { useState, useEffect, memo } from 'react'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom'
import { Row } from './Grid'
import { makeStyles } from '@mui/styles'

const iframeURL = 'https://docs.google.com/forms/d/e/1FAIpQLSfm5EOxtftVfabB3mBln9pja61xyiHgfpskcjhs_hlejEnLuA/viewform?embedded=true'

export const useStyles = makeStyles(theme => ({
  linkStyle: {
    opacity: '0.7', 
    cursor: 'pointer', 
    paddingTop: '3em', 
    fontSize: '1em',
    textDecoration: 'underline',
    textDecorationColor: theme.palette.background.underlineOverBackground,
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.light
    }
  }
})
)

/** @param {{hideFeedbackFormInFooter?: boolean}} props */
const FeedbackIFrame = props => {

  let location = useLocation()
  const isFeedbackPage = location?.pathname === '/feedback'
  const isHideInFooter = props?.hideFeedbackFormInFooter ?? false

  /* TODO: refactor all of this to make it cleaner / more understandable */
  const isShown = isFeedbackPage
    ? isHideInFooter /* special prop to avoid showing two feedback forms */
      ? true : false
    : false

  const classes = useStyles()

  const [showForm, setShowForm] = useState(isShown)

  /* Close the feedback form on location change if not on /feedback page */
  useEffect(() => {
    if (!isFeedbackPage || (isFeedbackPage && !isHideInFooter)) setShowForm(false)
  }, [location, isFeedbackPage, isHideInFooter])

  //const isSmallDisplay = useMediaQuery( { query: '(max-width: 480px)' } )

  const toggleForm = () => {
    setShowForm(!showForm)
  }

  if (!showForm && !isFeedbackPage) { 
    return (
      <div className={classes.linkStyle} onClick={toggleForm}>
        See something wrong?
      </div>
    )
  }

  if (showForm) {
    return (
      <Grid
        style={{ height: '100vh', minHeight: 1000 }}
        container
        spacing={0}
        wrap="nowrap"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Row justifyContent="center">
          <div className={classes.linkStyle} onClick={toggleForm}>
            {showForm && !isFeedbackPage && 'Close feedback form'}
          </div>
        </Row>
        <Row justifyContent="center" style={{ height: '100vh', minHeight: 1000 }}>
          <iframe src={iframeURL}
            id="iframe"
            width='100%'
            height='fit-content' 
            frameBorder={0} 
            marginHeight={0}
            marginWidth={0}
            title="feedback form"
          >
          </iframe>
        </Row>
      </Grid>
    )
  }
}

export default memo(FeedbackIFrame)
