import makeStyles from '@mui/styles/makeStyles'

/**
 * Component Styles: provides non-global styles for a specific component If a style is global it
 * should go in Theme.js not here
 */
export default makeStyles(theme => {
  return ({
    table: {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
      tableLayout: 'fixed',
    },
    tableCell: {
      overflow: 'visible',
      overflowWrap: 'break-word',
      hyphens: 'auto',
      padding: 15,
      '&:last-child': {
        padding: 15,
      },
    },
    tableCellWithUnknownValue: {
      opacity: '0.6',
      overflow: 'visible',
      overflowWrap: 'break-word',
      hyphens: 'auto',
      padding: 15,
      '&:last-child': {
        padding: 15,
      },
    },
    headerCell: {
      overflow: 'visible',
      whiteSpace: 'normal',
      padding: '8px 15px 8px 15px',
      fontWeight: 'bold',
    },
    headerCellContent: {
      display: 'flex',
      placeItems: 'center',
    },
    tablePagination: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      '& svg': { color: theme.palette.primary.main },
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    paginationOption: {
      color: theme.palette.primary.main,
    },
    cardText: {
      color: theme.palette.primary.main,
      fontFamily: 'Source Sans Pro, sans-serif',
      fontSize: '0.9rem',
      fontWeight: 400,
      marginTop: 10,
    },
    aboutCard: {
      padding: 15,
      borderRadius: '3px',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
    },
    aboutButton: {
      boxShadow: 'none',
      border: 0,
      padding: '3px',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      '& svg': { color: theme.palette.primary.main },
    },
    showMoreButton: {
      boxShadow: 'none',
      border: 0,
      marginBottom: 15,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      '& svg': { color: theme.palette.primary.main },
    },
    paper: {
      boxShadow: 'none',
    },
    tableContainer: {
      border: '1px solid #D3D3D3',
      borderRadius: '3px',
    },
    limitedTextLength: {
      display: '-webkit-inline-box',
      overflow: 'hidden',
      lineClamp: 3,
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
      textOverflow: 'ellipsis',
      '&:hover, &:active': {
        lineClamp: 'unset',
        WebkitLineClamp: 'unset'
      }
    },
    nonOverflowingTableCell: {
      whiteSpace: 'normal',
      overflow: 'wrap'
    },
    alwaysUnderline: {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.background.underlineOverBackground,
      '&:hover': {
        textDecorationColor: theme.palette.primary.main
      }
    },
    accordionInTable: {
      whiteSpace: 'nowrap',
      backgroundColor: 'unset',
    },
    accordionSummary: {
      padding: 0,
    },
    accordionDetails: {
      padding: 0,
    },
    filter: {
      position: 'relative',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    filterPopup: {
      display: 'flex',
      flexDirection: 'column',
      placeItems: 'center',
      padding: '1.5rem',
      gap: '1rem'
    }, 
    filterOptionsContainer: {
      maxHeight: '400px',
      overflow: 'auto',
      margin: '1rem 0'
    },
    filterLabel: {
      width: '100%',
    },
    filterButton: {
      padding: '0.2rem 0.5rem',
      border: 'none',
      transition: 'background 200ms'
    },
    filterIcon: {
      height: '1.3rem',
    }
  })
})
