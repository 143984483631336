import  { useEffect, useCallback, useState, memo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useAppSelector } from 'src/state/hooks'
import { useAppDispatch } from 'src/state/hooks'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setSearchTerm, selectSearchOffset } from '../../state/slices/searchStateSlice'
import { useTheme } from '@mui/material/styles'
import { isEmpty } from '../Data/DataUtils'
import useStyles from '../../theme/SearchBarStyles'
import { CustomSearchInput } from './SearchBarInputUtils'
import { CustomDropdownList } from './SearchBarDropdownUtils'
import i18n from '../../i18n'
import Data from '../Data/Data'
import {
  selectSearchResults,
  selectSearchTerm,
  selectSearchReset,
  resetSearch
} from '../../state/slices/searchStateSlice'
import { debounce } from 'debounce'

/** SearchBar: a search input with a formatted drop-down list
 * Tracks state cross-sessions using the query parameter "searchParam"
*/
const SearchBar = () => {

  /* Set up Material-UI theme */
  const theme = useTheme()
  const classes = useStyles(theme)

  /* Set hooks to use Redux global state */
  const searchTerm = useAppSelector(selectSearchTerm)
  const isSearchReset = useAppSelector(selectSearchReset)
  const records = useAppSelector(selectSearchResults)
  const offset = useAppSelector(selectSearchOffset)
  const location = useLocation()

  /* Get the search path parameter from the URL bar */

  /* Set up hooks */
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [rawSearchTerm, setRawSearchTerm] = useState('')

  /* Set up the react-i18next translation engine */
  const { t, ready: translationReady } = useTranslation(
    'SearchBar' /* i18next-parser needs string here */,
    { useSuspense: false, i18n }
  )
  
  /* On initial load only, grab the URL search param and set it to current state */
  useEffect(() => {
    const params = new URL(document.location.href).searchParams
    const urlSearchTerm = params.get('searchTerm') || ''
    setRawSearchTerm(urlSearchTerm)
  }, [])

  /* determine appropriate URL depending on location */
  const limit = location.pathname === '/searchresults' ? 50 : 10
  const url = `/api/search?q=${encodeURIComponent(searchTerm)}&limit=${limit}&offset=${offset}`

  const justNavigated = useRef(location.state?.justNavigated)
  const fetchPromises = useRef([])
  const isLoading = useRef(false)
  isLoading.current = isEmpty(records) && !isInitialLoad

  /** get raw user input as state 
   * @type {ChangeEventHandler<HTMLTextAreaElement>}
  */
  const handleSearchTextChange = e => {
    if (isInitialLoad) setIsInitialLoad(false)
    isLoading.current = true
    const { value } = e.target
    setRawSearchTerm(value)
  }

  /** @type {React.MutableRefObject<ReturnType<debounce>>} */
  const debounceObj = useRef(debounce())
  // eslint-disable-next-line no-unused-vars
  const [_, setUrlSearchTerm] = useSearchParams()

  /* Manage debounced fetch queue which will call executeFetch() above */ 
  useEffect(() => {
    if (justNavigated.current) {
      /* don't try to abort requests when we navigate to search results page */
      justNavigated.current = false
      return
    }
    /** @param {string} term */
    const executeFetch = (term) => {
      dispatch(setSearchTerm(term))
      // changes ?searchTerm= in URL bar, as a measure of state
      term && setUrlSearchTerm({searchTerm: term})
    }

    debounceObj.current.clear && debounceObj.current.clear()
    debounceObj.current = debounce(() => executeFetch(rawSearchTerm), 400)
    debounceObj.current()
  }, [rawSearchTerm, debounceObj, justNavigated, dispatch, setUrlSearchTerm])

  /** Navigate to the Search Results page on Submit 
   * @type {EventHandler<HTMLDivElement>}
  */
  const handleSearchSubmit = useCallback(e => {
    if (rawSearchTerm.length === 0 || isSearchReset)
      return null /* if the user hasn't typed anything don't navigate to the search results page */
    debounceObj.current.flush()
    dispatch(
      setSearchTerm(rawSearchTerm)
    ) /* update what the searchterm should be, bypassing the debounce. Without this the results pages shows an outdated result */
    const searchParam = rawSearchTerm ? '?searchTerm=' + rawSearchTerm : ''
    navigate('/searchresults' + searchParam, {
      state: { justNavigated: true },
    }) /* Redirect to searchresults page */
  },
  [rawSearchTerm, isSearchReset, navigate, dispatch]
  )

  /* This is a hack to prevent the drop-down bar from staying open after an item is clicked */
  const handleResultClick = () => {
    dispatch(resetSearch())
    setRawSearchTerm('')
  }

  /* Define when dropdown list can render */
  const resultsReady =
    !isInitialLoad &&
    typeof searchTerm === 'string' &&
    rawSearchTerm.length > 0 &&
    !location.pathname.startsWith('/searchresults') &&
    !isSearchReset

  /* Translation calls with t() must occur after translationReady is true */
  /* Conditional returns must take place after all hooks have been called */
  if (!translationReady) { return null }

  /* Render the search bar */
  return (
    <div className={classes.root}>
      {typeof searchTerm === 'string' && searchTerm.length > 0 && (
        <Data
          fetchArray={[
            {
              slice: `searchterm-${searchTerm}`, url
            },
          ]}
          isDebugEnabled={false}
          onFetchPromise={p => fetchPromises.current.push(p)}
        ></Data>
      )}
      <div className='wrapper-div'>
        <div className='search-bar-header'>
          <form className='search-form'>
            <CustomSearchInput
              value={rawSearchTerm}
              placeholderText={t(
                'Search for an address, business, landlord, or neighborhood in Oakland or San Francisco'
              )}
              handleChange={handleSearchTextChange}
              handleSearchSubmit={handleSearchSubmit}
              handleResultClick={handleResultClick}
              isLoading={isLoading.current}
            />
          </form>
        </div>
        <div>
          {resultsReady && (
            <div className={classes.searchResults}>
              <CustomDropdownList data={records} handleResultClick={handleResultClick} isLoading={isLoading.current} />
            </div>
          )}
        </div>
      </div>
    </div>
  )

}

SearchBar.propTypes = {
  location: PropTypes.string.isRequired,
  helperText: PropTypes.string,
}

SearchBar.defaultProps = {
  location: '',
}

export default memo(SearchBar)
