import makeStyles from '@mui/styles/makeStyles'

/*
  Component Styles: provides non-global styles for a specific component
    If a style is global it should go in Theme.js not here
*/

export default makeStyles(
  theme => ({
    legend: {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontSize: '0.9rem',
      fontWeight: 400,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      opacity: 0.9,
      padding: 8
    },
    infoBox: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      zIndex: 400,
      overflow: 'hidden',
      opacity: 0.9,
      padding: 8
    },
    invertedColorScheme: {
      color: `${theme.palette.primary.contrastText} !important`
    },
  })
)
