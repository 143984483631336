import { createSlice } from '@reduxjs/toolkit'

/* A slice is a data object containing a portion of the application state */
/* Reducers determine how to modify state when something happens in the app */

/* State that should only be set initially */
export const initialSearchState = { searchTerm: '', isReset: false, offset: 0}

/* createSlice() automatically creates actions that incorporate the reducer logic below */
const searchSlice = createSlice({
  name: 'search',
  initialState: initialSearchState,
  reducers: {
    setSearchTerm(state, action) {
      const newTerm = action.payload
      if (newTerm !== state.searchTerm) {
        state.searchTerm = action.payload
      }
      state.isReset = false
    },
    resetSearch(state) {
      return { ...state, isReset: true, offset: 0 }
    },
    setOffset(state, action){
      state.offset = action.payload
    }
  }
})

/* Destructure and export synchronous action creators */
export const { resetSearch, setSearchTerm, setOffset } = searchSlice.actions

/**
 * For a smoother search, we don't want to return an empty object when the result is pending, as records does
 * the result should instead be the last fetched result 
 * @param {import('../hooks').RootState} state
 * */ 
export const selectSearchResults = state => {
  const term = state.search?.searchTerm ?? ''
  const slice = state._fetch[`searchterm-${term}`]
  return slice?.json?.records ?? {}
}

/** @param {import('../hooks').RootState} state */
export const selectSearchTerm = state => state.search?.searchTerm ?? ''
/** @param {import('../hooks').RootState} state */
export const selectSearchOffset = state => state.search?.offset ?? 0
/** @param {import('../hooks').RootState} state */
export const selectSearchReset = state => state.search?.isReset ?? false
/** @param {import('../hooks').RootState} state */
export const selectSearchPending = state => {
  const term = selectSearchTerm(state)
  const slice = `searchterm-${term}`
  return state._fetch[slice]?.pending ?? false
}

/* Export the above reducers in a way that can be passed directly to combineReducers() */
export default searchSlice.reducer
