import L from 'leaflet'
import multipleResultsSVG from './multiple-results-icon.svg'
import searchedForSVG from './searched-for-icon.svg'
import relatedParcelSVG from './related-parcel-icon.svg'
import directlyEvictedSVG from './directly-evicted-icon.svg'
import lightGreenMarker from './light-green-circle-marker-owner-22.png'
import darkGreenMarker from './dark-green-circle-marker-owner-22.png'
import lightOrangeMarker from './light-orange-circle-marker-owner-22.png'
import darkOrangeMarker from './dark-orange-circle-marker-owner-22.png'

/** @type {[number, number]} */
const iconAnchor = [ 11, 10 ]

//icons for parcel and neighborhood views
export const multipleResultsMarker = L.icon({
  iconUrl: multipleResultsSVG,
  iconAnchor
})

export const searchedForMarker = L.icon({
  iconUrl: searchedForSVG,
  iconAnchor
})

export const relatedParcelMarker = L.icon({
  iconUrl: relatedParcelSVG,
  iconAnchor
})

export const directlyEvictedMarker = L.icon({
  iconUrl: directlyEvictedSVG,
  iconAnchor
})

export const createClusterMarker = function (){
  return L.icon({
    iconUrl: multipleResultsSVG,
    iconAnchor
  })
}

//icons for owner view
export const lightGreenLMarker = L.icon({
  iconUrl: lightGreenMarker,
  iconAnchor
})

export const darkGreenLMarker = L.icon({
  iconUrl: darkGreenMarker,
  iconAnchor
})

export const lightOrangeLMarker = L.icon({
  iconUrl: lightOrangeMarker,
  iconAnchor
})

export const darkOrangeLMarker = L.icon({
  iconUrl: darkOrangeMarker,
  iconAnchor
})